import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import Swal from "sweetalert2";
import Dropdown from "react-bootstrap/Dropdown";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { renderLicenseStatus, translateLicenseStatusCode} from "../Helper/utils";

import {makeLicensePayment} from "../Helper/paymentHelper";

const baseUrl = process.env.REACT_APP_BASE_URL;

// Add styles to remove the dropdown arrow
const styles = `
  .dropdown-menu.show::before {
    display: none !important;
  }
  .dropdown-menu.show::after {
    display: none !important;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .sm-mobile-sidebar .dropdown-menu.show {
    margin-top: 8px !important;
    padding-top: 8px !important;
  }
  .sm-mobile-sidebar .dropdown-menu > * {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .sm-mobile-sidebar .dropdown-item {
    margin: 0 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .sm-mobile-sidebar .dropdown {
    padding: 0.5rem 1rem;
  }
`;
// Custom toggle component with down arrow
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="w-100 text-light d-flex align-items-center justify-content-between"
    style={{ cursor: 'pointer' }}
  >
    <div className="d-flex align-items-center">
      {children}
    </div>
    <i className="material-icons opacity-10 text-light">expand_more</i>
  </div>
));

// Custom mobile toggle without the square icon
const CustomMobileToggle = React.forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="nav-link d-flex align-items-center justify-content-between text-light"
    style={{ cursor: 'pointer' }}
  >
    <div className="d-flex align-items-center">
      <i className="material-icons opacity-10 me-2">how_to_reg</i>
      License
    </div>
    <i className="material-icons opacity-10">expand_more</i>
  </div>
));


class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFoodScientistRegistered: false,
      isLicenseLoading: false,
      isInspectionLoading: false,
      isInspectionRegistered: false,
      isUserInfoLoading: false,
      regNumber: "",
      showLicenseModal: false,
      isPayingForLicense: false,
      licenseId: null,
      showRenewalOption: false,
      licenseExpiryDate: null,
      isLoading: true,
      hasLicenseApplication: false,
      licenseStatus: "",
      paymentStatus: "",
      licenseExpired: false,
      licenseId: null,
      licenseExpDate: null,
      isOpen: props.isOpen || false,
      rrr: null
    };
  }

  componentDidMount() {
    this.initializeData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  initializeData = async () => {
    await Promise.all([
      this.getUserDetails(),
      this.checkLicenseRegistration() 
    ]);
  };


  checkLicenseRegistration = async () => {
    try {
      if (!localStorage.getItem("licenseId")) {
        return;
      }
      this.setState({ isLicenseLoading: true });
      
      const response = await fetch(
        `${baseUrl}/v2/license/${localStorage.getItem("licenseId")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const responseJson = await response.json();

      if (response.status === 401) {
        this.setState({ isLicenseLoading: false });
        Swal.fire({
          title: "Session Expired",
          text: "Session expired. Please login",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.props.history.push("/login");
        });
        this.setState({
          isLicenseLoading: false,
          license: responseJson.licenseNumber,
          licenseId: responseJson.licenseId,
          licenseExpiryDate: responseJson.licenseExpDate,
        });
      } else {
        this.setState({
          isLicenseLoading: false,
          license: responseJson.licenseNumber,
          licenseId: responseJson.licenseId
        });
      }
    } catch (error) {
      this.setState({ isLicenseLoading: false });
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  makePaymentReponse = () => {
    return (
      <button
        disabled={this.state.isPayingForLicense}
        onClick={async () => {
          this.setState({ isPayingForLicense: true });
  
          await makeLicensePayment(
            this.state.retrievedLicenseInfo.licenseId,
            this.state.retrievedLicenseInfo.rrr
          );
  
          this.setState({ isPayingForLicense: false });
        }}
        class="btn btn-danger m-0 p-1 px-3"
      >
        {this.state.isPayingForLicense && (
          <Spinner className="me-2" animation="grow" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        Pay for license
      </button>
    );
  };

  getUserDetails = async () => {
    try {
      this.setState({ isUserInfoLoading: true });
      const response = await fetch(
        `${baseUrl}/v2/dashboard/user/${localStorage.getItem("userid")}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const responseJson = await response.json();

      if (response.status === 401) {
        this.setState({ isUserInfoLoading: false });
        Swal.fire({
          title: "Session Expired",
          text: "Session expired. Please login",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.props.history.push("/login");
        });
      } else {
        
        const licenseInformation = responseJson.licenseInformation;
        const registrationInfo = responseJson.registrationInformation;

        this.setState({
          regNumber: registrationInfo.csfnNumber,
          registrationStatus: registrationInfo.applicationStatus,
          isUserInfoLoading: false,
          paymentStatus: licenseInformation.paymentStatus,
          licenseStatus: licenseInformation.applicationStatus,
          hasLicenseApplication: licenseInformation.hasLicenseApplication,
          licenseExpired: licenseInformation.licenseExpiry,
          licenseId: licenseInformation.licenseId,
          licenseExpDate: licenseInformation.expiryDate,
          rrr: licenseInformation.rrr,
          isLoading: false
        });
      }
    } catch (error) {
      this.setState({ isUserInfoLoading: false });
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  handleToggle = () => {
    const { ToggleCollapesSidebar } = this.props;
    if (ToggleCollapesSidebar) {
      ToggleCollapesSidebar(false);
    }
    this.setState({ isOpen: false });
  };

  
  render() {
    
    const {
      isLoading,
      hasLicenseApplication,
      licenseStatus,
      paymentStatus,
      licenseExpired,
      licenseId,
      licenseExpDate,
      isOpen
    } = this.state;



    if (isLoading) {
      return (
        <center>
          <Spinner animation="border" variant="primary" size="sm" />
        </center>
      );
    }


    const licenseStatusContent = renderLicenseStatus(
          hasLicenseApplication,
          licenseStatus,
          paymentStatus,
          licenseExpired,
          licenseId,
          this.makePaymentReponse,
          this.translateLicenseStatusCode,
          licenseExpDate,
          'sidebar'
        );
    

        const isEmptyDiv = (element) =>
          React.isValidElement(element) &&
          element.type === 'div' &&
          React.Children.count(element.props.children) === 0;

    return (
      <div className="g-sidenav-show" style={{ padding: 0, margin: 0 }}>
        <style>{styles}</style>
        {/* Desktop Sidebar */}
        <aside
          className="sidenav navbar navbar-vertical navbar-expand-xs fixed-start d-none d-md-block"
          id="sidenav-main"
          style={{
            margin: "0 !important",
            borderRadius: "0",
            overflow: "hidden",
            backgroundColor: "#00264C"
          }}
        >
          <div className="sidenav-header" style={{ backgroundColor: "#00264C" }}>
            <a className="navbar-brand d-flex justify-content-center text-center m-0" href="#" target="_blank">
              <img src={logo} className="navbar-brand-img h-100" alt="main_logo" />
            </a>
          </div>
          <hr className="horizontal light mt-0 mb-0" />

          <div className="collapse navbar-collapse w-auto" style={{ backgroundColor: "#00264C" }}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  className={window.location.href.includes("dashboard") ? "active nav-link" : "nav-link"}
                >
                  <div className="text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10 text-light">dashboard</i>
                  </div>
                  <span className="nav-link-text ms-1 text-light">Dashboard</span>
                </Link>
              </li>

              <li>
                <div className="nav-link pl-1">
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}>
                      <i className="material-icons opacity-10 text-light me-2">how_to_reg</i>
                      License
                    </Dropdown.Toggle>

                    <Dropdown.Menu style={{ backgroundColor: '#00264C', border: 'none', width: '100%' }}>
                       {(!isEmptyDiv(licenseStatusContent))  && (
                        <div>
                          {licenseStatusContent}
                        </div>
                      )}
                      <Dropdown.Item className="text-light" style={{ backgroundColor: 'transparent' }}>
                        <Link className="text-light text-decoration-none" to="/license-history">
                          License History
                        </Link>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </li>

              <li className="nav-item">
                <Link to="/payments" className="nav-link">
                  <div className="text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10 text-light">credit_card</i>
                  </div>
                  <span className="nav-link-text ms-1 text-light">Payments</span>
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/logout" className="nav-link">
                  <div className="text-center me-2 d-flex align-items-center justify-content-center">
                    <i className="material-icons opacity-10 text-light">logout</i>
                  </div>
                  <span className="nav-link-text ms-1 text-light">Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </aside>

      {/* Mobile Navigation */}
      <Navbar
          expand="lg"
          className={`d-md-none sm-mobile-sidebar ${isOpen ? "d-block" : "d-none"}`}
          variant="dark"
          style={{ backgroundColor: "#00264C" }}
        >
          <Container style={{ backgroundColor: "#00264C", padding: "18px 18px 0 18px" }}>
            <Navbar.Brand className="text-light font-weight-bold">
              NiCFoST
            </Navbar.Brand>
            <Navbar.Toggle onClick={this.handleToggle} className="bg-dark" >X</Navbar.Toggle>

            <Nav className="flex-column w-100">
              <Nav.Link className="text-light">
                <Link className="text-light text-decoration-none" to="/dashboard">
                  <div className="d-flex align-items-center">
                    <i className="material-icons opacity-10 me-2">dashboard</i>
                    Dashboard
                  </div>
                </Link>
              </Nav.Link>

              <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                  <i className="material-icons opacity-10 me-2">how_to_reg</i>
                  License
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ 
                  backgroundColor: '#00264C', 
                  border: 'none', 
                  width: '100%'
                }}>
                   {!isEmptyDiv(licenseStatusContent) && (
                    <div >
                      {licenseStatusContent}
                    </div>
                  )}
                  <Dropdown.Item 
                    className="text-light"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <Link className="text-light text-decoration-none" to="/license-history">
                      License History
                    </Link>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Nav.Link className="text-light">
                <Link className="text-light text-decoration-none" to="/payments">
                  <div className="d-flex align-items-center">
                    <i className="material-icons opacity-10 me-2">credit_card</i>
                    Payments
                  </div>
                </Link>
              </Nav.Link>

              <Nav.Link className="text-light">
                <Link className="text-light text-decoration-none" to="/logout">
                  <div className="d-flex align-items-center">
                    <i className="material-icons opacity-10 me-2">logout</i>
                    Logout
                  </div>
                </Link>
              </Nav.Link>
            </Nav>
          </Container>
        </Navbar>
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
          <nav className="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl" id="navbarBlur" navbar-scroll="true">
            <div className="container-fluid py-1 px-3">
              <nav aria-label="breadcrumb"></nav>
            </div>
          </nav>
        </main>
      </div>
    );
  }
}

export default Sidebar;