import { Component } from "react";
import NewDashboardLayout from "../components/NewDashboardlayout";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { makePayment } from "../Helper/paymentHelper";
import { Image } from "react-bootstrap";


const baseUrl = process.env.REACT_APP_BASE_URL

class NewDashboard extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      regNumber: "",
      regStatus: "",
      license: "",
      licenseStatus: "",
      hasLicenseApplication: "",
      licenseIsPaid: "",
      image: "",
      fullName: "",
      isUserInfoLoading: false,
    } 

    this.makePaymentReponse = this.makePaymentReponse.bind(this);
    this.translateLicenseStatusCode = this.translateLicenseStatusCode.bind(this);
  }

  componentDidMount() {
    // this.retrieveSuccessfulPayments();
    // this.getLicenseNumber();
    this.getUserDetails();
    // this.getPremisesDetails();
    // this.getLicenseRegistration();
  }


  getUserDetails = async () => {
    this.setState({ isUserInfoLoading: true });
    let obj = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    };
    await fetch(
      `${baseUrl}/v2/dashboard/user/${localStorage.getItem(
        "userid"
      )}`,
      obj
    )
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson, "from new dashboard")
      if (responseJson.status === 401) {
        Swal.fire({
          title: "Session Expired",
          text: "Session expired. Please login",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.props.history.push("/login");
        });
      } else {
        console.log(responseJson, baseUrl, " from Dashboard")
        const { licenseInformation, registrationInformation, userInformation } = responseJson;

        this.setState({
          regNumber: registrationInformation?.csfnNumber,
          regStatus: registrationInformation?.applicationStatus,
          license: licenseInformation?.licenseNumber,
          licenseStatus: licenseInformation?.applicationStatus,   
          hasLicenseApplication: licenseInformation?.hasLicenseApplication,
          licenseIsPaid: licenseInformation?.licenseIsPaid,      
          image: userInformation?.userImage,
          fullName: userInformation?.fullName,
          isUserInfoLoading: false,
        });

        localStorage.setItem('licenseId', licenseInformation?.licenseId);
        localStorage.setItem('rrr', licenseInformation?.rrr);
      }
    })
    .catch((error) => {
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    });
  };


  translateLicenseStatusCode = (status) => {
    localStorage.removeItem('paymentSuccessful')

    switch (status) {
      case "pending":
        return "Processing Application";
      case "approved":
        return "License Approved";
      case "rejected":
        return "Application Rejected";
      default:
        return "Unknown Status";
    }
  }

  makePaymentReponse = () => {
    if(localStorage.getItem('paymentSuccessful') === 'true') {
      return (
        <div>
          <div class="spinner-border" role="status">
           <span class="visually-hidden">Loading...</span> 
          </div>
          {` Awaiting response`} 
        </div>
      )
    }else {
      return <button onClick={() => makePayment()} class="btn btn-danger m-0 p-1 px-3">Pay for license</button>
    }
  }

  render() {
    const {
      regNumber,
      license,
      isUserInfoLoading,
      hasLicenseApplication,
      licenseIsPaid,
      licenseStatus,
      regStatus
    } = this.state;


    console.log("==============================================================================   rejected because", licenseStatus);

    return (
      <NewDashboardLayout PageName="Dashboard">
        <div className="row">
          <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-3">
                          <div className="card shining-card">
                            <div className="card-body">                    
                                <span className="fs-4 fw-semibold me-2 text-right">License Number</span>
                                <div className="d-flex  justify-content-between pt-4">
                                { isUserInfoLoading ? (   <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div> ) : (
                                  <h4 class="mb-0 text-light font-weight-normal text-end pt-2 counter text-dark">
                                    {!license?.length ? "Not issued" : license}
                                  </h4>
                                  )
                                }
                                </div>
                            </div>            
                          </div>
                      </div>
                      <div className="col-lg-3">
                          <div className="card shining-card">
                            <div className="card-body">                    
                                <span className="fs-4 fw-semibold me-2 text-right">CFSN Number</span>
                                <div className="d-flex  justify-content-between pt-4">
                                { isUserInfoLoading ? ( <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div> ) : (
                                  <h4 class="mb-0 text-light font-weight-normal text-end pt-2 counter text-dark">
                                    {regNumber ? regNumber : "Not issued"}
                                  </h4>
                                  )
                                }
                                </div>
                            </div>            
                          </div>
                      </div>
                      <div className="col-lg-3">
                          <div className="card shining-card">
                            <div className={`card-body ${regStatus === 'rejected' ? "bg-danger text-white" : (regStatus === 'approved' && "bg-success text-white") } `}>                    
                                <span className="fs-4 fw-semibold me-2 text-right">Registration Status</span>
                                <div className="d-flex  justify-content-between pt-4">
                                { isUserInfoLoading ? ( <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div> ) : (
                                  <h4 class="mb-0 text-light font-weight-normal text-end pt-2 counter">
                                    {regStatus === "rejected" ? "Contact us": regStatus}
                                  </h4>
                                  )
                                }
                                </div>
                            </div>            
                          </div>
                      </div>
                      <div className="col-lg-3">
                          <div className="card shining-card">
                            <div className={`card-body ${licenseStatus === 'rejected' ? "bg-danger text-white" : (licenseStatus === 'approved' && "bg-success text-white") } `}>                    
                                <span className="fs-4 fw-semibold me-2 text-right">License Status</span>
                                <div className="d-flex  justify-content-between pt-4">
                                { isUserInfoLoading ? (   <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                  </div> ) : (
                                  <h4 class="mb-0 text-light font-weight-normal text-end counter ">
                                    { !hasLicenseApplication ? <Link to="/new-license" className="btn btn-primary m-0 p-1 px-3">Apply Now</Link> : 
                                    ((hasLicenseApplication && licenseIsPaid === false && licenseStatus !== "rejected") ? this.makePaymentReponse() : 
                                    (this.translateLicenseStatusCode(licenseStatus)) ) }
                                  </h4>
                                  )
                                }
                                </div>
                            </div>            
                          </div>
                      </div>
                     {/* <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header d-flex justify-content-between flex-wrap">
                              <div className="width-100 h-100 text-center h-50 bg-opacity-25">
                                <Image className="w-100 h-100" style={{backgroundSize: "cover", backgroundPosition: "center"}} src="https://media.istockphoto.com/id/542099026/vector/certificate-template-certificate-of-appreciation-vector.jpg?s=1024x1024&w=is&k=20&c=vwQaF9wU_A0japiwJoCKjSHGjBscXspNN6xFW04x79U=" />
                              </div>
                            </div>
                            <div className="card-body">
                                <form className="col-lg-12">
                                  <div className="d-grid gap-card grid-cols-2">
                                    <button className="btn" type="button" style={{backgroundColor: "rgb(6 38 1)"}} disabled={true} >
                                      <span className="text-light">Download License Certificate</span>
                                      <svg className="rotate-45 text-light" width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.75 11.7256L4.75 11.7256" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13.7002 5.70124L19.7502 11.7252L13.7002 17.7502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    </button>
                                    
                                  </div>
                                </form>
                            </div>                                          
                          </div>
                      </div>*/}
                {/*      <div className="col-lg-6">
                          <div className="card">
                            <div className="card-header d-flex justify-content-between flex-wrap">
                              <div className="width-100 h-100 text-center h-50 bg-opacity-25">
                                <Image className="w-100 h-100" style={{backgroundSize: "cover", backgroundRepeat: "repeat", backgroundPosition: "center"}} src="https://media.istockphoto.com/id/542099026/vector/certificate-template-certificate-of-appreciation-vector.jpg?s=1024x1024&w=is&k=20&c=vwQaF9wU_A0japiwJoCKjSHGjBscXspNN6xFW04x79U=" />
                              </div>
                            </div>
                            <div className="card-body">
                                <form className="col-lg-12">
                                  <div className="d-grid gap-card grid-cols-2">
                                    <button className="btn" type="button" style={{backgroundColor: "rgb(6 38 1)"}}  disabled={true} >
                                      <span className="text-light">Download Premises Certificate</span>
                                      <svg className="rotate-45 text-light" width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.75 11.7256L4.75 11.7256" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path><path d="M13.7002 5.70124L19.7502 11.7252L13.7002 17.7502" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    </button>
                                    
                                  </div>
                                </form>
                            </div>                                          
                          </div>
                      </div>*/}
                    </div>
                </div>
              </div>
            </div>                         
         </div>     
      </NewDashboardLayout>
    )
  }
}

export default NewDashboard