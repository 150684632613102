import {Component}from 'react'
import Swal from 'sweetalert2';
import { Alert, Form, Spinner } from "react-bootstrap";
import NewDashboardLayout from '../components/NewDashboardlayout';
import moment from 'moment';

const baseUrl = process.env.REACT_APP_BASE_URL

class NewPremisesInspection extends Component  {
	constructor(props){
    super(props);
    this.state = {
      data: [],
      inspectionData: [],
      postsPerPage: 10,
      currentPage: 1,
      isLoading: false,
      isPremisesLoading: false,
      isInspectionLoading: false,
      isDisabled: false,
      

      //COLOR VARIABLES
      colorGender: 'black',
      colorLicesNum: 'black',
      colorNicFostNo: 'black',
      colorNIFST: 'black'
    }

    // this.handleGenderChange = this.handleGenderChange.bind(this);
    // this.handleCheckChange = this.handleCheckChange.bind(this);
  }
	
	
	registerPremises = async (value) => {
    let inputVal = value.split(",");
    this.setState({amount:inputVal[3], description:inputVal[9], remitaid: inputVal[10].trim(), name:inputVal[7], paymentCategory:inputVal[11], paymentSubcategory:inputVal[12]})
    // console.warn(inputVal[3]);
    // console.warn(inputVal[9]);

    let date = new Date();
    if(this.state.licenseNo === ""){
      Swal.fire({
        title: "Empty",
        text: "Please indicate your license number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ colorLicesNum: 'red' })
    }else if(this.state.nifst === ""){
      Swal.fire({
        title: "Empty",
        text: "Please specify your NiCFoST Reg Number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ colorNIFST: 'red' })
    }else if(this.state.gender === ""){
      Swal.fire({
        title: "Empty",
        text: "Please specify your gender",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ colorGender: 'red' })
    }else if (this.state.acceptConsent === false) {
      Swal.fire({
        title: "Verify Accuracy",
        text: "Verify accuracy of your information by ticking the bos",
        icon: "error",
        confirmButtonText: "OK",
      });
    }else{
    this.setState({isPremisesLoading: true, isDisabled: true})
    var obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        applicationdate: date.getTime(),
        businessdescription: inputVal[5],
        businesstype: inputVal[6],
        companysize: inputVal[4].trim(),
        formtype: "registration",
        gender: this.state.gender,
        inspectionfees: inputVal[3],
        leadfoodscientistlicensenumber: this.state.licenseNo,
        leadfoodscientistname: inputVal[7],
        lga: inputVal[0],
        locationaddress: inputVal[8],
        nationality: "Nigeria",
        organisationname: inputVal[2],
        productbrands: this.state.productbrands,
        registrationnumber: this.state.nifst,
        stateofororigin: inputVal[1],
        userid: localStorage.getItem('userid')
      }),
    };
    fetch(`${baseUrl}/Premises/addPremises`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (
          responseJson.message === "New Premises added Successfully"
        ) {
          // this.initializePayment()
          this.makeRemitaPayment()
        }else if(responseJson.status === 401){
          this.props.history.push("/login")
        }
      })
      .catch((error) => {
        this.setState({isPremisesLoading: false, isDisabled: false});
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    }
  };


	render() {
		const item = [];
		const val = '';


		return (
			<NewDashboardLayout PageName="Apply For Premises Inspection">
				<div className="container-fluid px-4" id="register-form-container">
            <div className="rown">
              <div className="col-12">
                <div className="card my-3">
                  <div className="card-header pb-0" style={{backgroundColor: "rgb(6 38 1)"}}>
                    <div className="d-flex flex-wrap justify-content-center mb-2">
                      <h5 className="text-light text-center font-weight-bold" >
											APPLICATION FORM FOR REGISTRATION OF PREMISES
                      </h5>
                    </div>
                  </div>
                  <div class="card-body reg-body">
									<form className="row">

              <label
                className="mb-3 h4"
                style={{ color: "green" }}
                htmlFor="floatingInputCustom"
              >
                Orgnainzation Information
              </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorSurname }}
                    className="form-label"
                  >
                    Organization Name <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control w-50 shadow-none"
                      type="text"
                      required="required"
                      value={item.organisationname}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Address
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <textarea
                      className="form-control w-50 shadow-none"
                      type="text"
                      value={item.addressstreet}
                    ></textarea>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Address LGA <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.addresslga}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label"
                  >
                    Address State <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.addressstate}
                    />
                  </div>
                </div>


                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Business Type
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.businesstype}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Business Description
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      value={item.businessdescription}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Product Brand
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      value={item.productbrands}
                    />
                  </div>
                </div>

                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Contact Information
                </label>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Contact Name
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      value={item.contactname}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Contact Email
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      value={item.contactemail}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label text-dark"
                  >
                    Contact Phone
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      value={item.contactphone}
                    />
                  </div>
                </div>

                <div
									className="text-center"
									style={{
										margin: "auto",
										width: "100%",
										marginTop: 45,
										marginBottom: 0,
									}}
								>
									<button
										type="button"
										disabled={this.state.isDisabled}
										style={{
											alignSelf: "center",
											width: "100%",
											backgroundColor: "#003314",
										}}
										id={`${item.addresslga},${item.addressstate},${item.organisationname},${val[1]},${val[4]},${item.businessdescription},${item.businesstype},${item.contactname},${item.addressstreet},${val[2]}}`}
										className="btn btn-success btn-lg"
										onClick={(e) => this.registerPremises(`${item.addresslga},${item.addressstate},${item.organisationname},${val[1]},${val[4]},${item.businessdescription},${item.businesstype},${item.contactname},${item.addressstreet},${val[2]},${val[5]}, ${val[0]}, ${val[3]}`)}
										>
										{this.state.isPremisesLoading ? (
											<Spinner animation="border" variant="light" size="sm" />
										) : (
											<span className="font-weight-bold">
												SUBMIT APPLICATION
											</span>
										)}
									</button>
								</div>
               
      				 </form>
									</div>
								</div>
							</div>
						</div>						
					</div>
			</NewDashboardLayout>
		)
	}
}


export default NewPremisesInspection