import React, { Component } from "react";
import { Spinner, Form } from "react-bootstrap";
 
import "bootstrap/dist/css/bootstrap.min.css";
import { states } from "../components/States";
import { countries } from "../components/Countries";
import Swal from "sweetalert2";
import logo from "../assets/images/logo.png"
import Footer from "../components/Footer";
import { makePayment } from '../Helper/paymentHelper';



const baseUrl = process.env.REACT_APP_BASE_URL


class License extends Component {

    constructor(props) {
        var today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
        super(props);
        this.state = {
          currentDate: date,
          title: "",
          gender: "",
          surname: "",
          prevSurname: "",
          firstname: "",
          othername: "",
          dob: "",
          position: "",
          mailingAddres: "",
          phone: "",
          disabled: false,
          nationality: "",
          state: "",
          lga: "",
          expDate: "31/12/2023",
          boxAll: false,
          address: "",
          password: "",
          confirmPassword: "",
          sponsorName: "",
          sponsorName2: "",
          sponsorNo2: "",
          curLicense: "",
          sponsorNo: "",
          orgName: "",
          orgEmail: "",
          orgPhone: "",
          orgAddress: "",
          qualification: "",
          qualification2: "",
          qualificationYear2: "",
          qualificationYear: "",
          prevLicense: "",
          prevLicenseDate: "",
          practiceCat: "",
          regType: "",
          city: "",
          email: "",
          nifst: "",
          countryCode: "",
          colorSurname: "black",
          colorFirstName: "black",
          colorTitle: "black",
          colorEmail: "black",
          colorOthername: "black",
          colorNationality: "black",
          colorLga: "black",
          colorPhone: "black",
          colorPassword: "black",
          colorPasswordConf: "black",
          colorSponsor: "black",
          colorSponsorNo: "black",
          colorOrgName: "black",
          colorOrgEmail: "black",
          colorOrgPhone: "black",
          colorAddress: "black",
          // colorCity: "black",
          colorPosition: "black",
          colorState: "black",
          colorGender: "black",
          colorDob: "black",
          colorNIFST: "black",
          colorQualYear: "black",
          colorRemita: "black",
          colorQual: "black",
          colorMailAddress: "black",
          colorCountryCode: "black",
          colorPraCat: 'black',
          colorPrevLic: 'black',
          colorCurLic: 'black',
          colorCurLicDate: 'black',
          colorPrevLicDate: 'black',
          colorRegType: "black",
          loading: false,
          isUserId: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleQualificationChange = this.handleQualificationChange.bind(this);
        this.handleQualificationChange2 = this.handleQualificationChange2.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handlePracticeChange = this.handlePracticeChange.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
      }

      handleChange(e) {
        this.setState({ title: e.target.value });
      }
      handlePracticeChange(e) {
        this.setState({ practiceCat: e.target.value });
      }
      handleStateChange(e) {
        this.setState({ state: e.target.value });
      }

      handleGenderChange(e) {
        this.setState({ gender: e.target.value });
      }

      handleCountryChange(e) {
        this.setState({ countryCode: e.target.value });
      }

      handleQualificationChange(e) {
        this.setState({ qualification: e.target.value });
      }

      handleQualificationChange2(e) {
        this.setState({ qualification2: e.target.value });
      }

      handleCheckChange(e) {
        this.setState({ boxAll: e.target.checked }, () => {
          console.log("This returned true or false", this.state.boxAll);
        });
      }

      onChangeValue(event) {
        this.setState({ regType: event.target.value})
           console.log(event.target.value);
          }

      getStates() {
        return states.map((state) => {
          return <option value={state}>{state}</option>;
        });
      }

      getCountry() {
        return countries.map((country) => {
          return <option value={country.dial_code}>{country.name}</option>;
        });
      }

      checkAuth(){
        if(localStorage.getItem('userid')){
          this.uploadImage();
        }else{
          this.register();
        }
      }

      checkValidation = async (e) => {
        const {
          title,
          gender,
          surname,
          firstname,
          othername,
          dob,
          qualification,
          nationality,
          state,
          nifst,
          phone,
          password,
          confirmPassword,
          mailingAddres,

          boxAll,
          lga,
          address,
          position,
          orgEmail,
          orgName,
          orgPhone,
          email,

        } = this.state;
        this.setState({ loading: true, disabled: true });
        e.preventDefault();

        if (surname === "" && !localStorage.getItem("surname")) {
          Swal.fire({
            title: "Empty",
            text: "Please enter your surname",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorSurname: "red" });
        } else if (firstname == "" && !localStorage.getItem("firstname")) {
          Swal.fire({
            title: "Empty",
            text: "Please veirfy your first name",
            icon: "error",
            confirmButtonText: "OK",
          });

          this.setState({ loading: false, disabled: false, colorFirstName: "red" });
        } else if (othername === "" && !localStorage.getItem("othername")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your other name",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOthername: "red" });
        } else if (title === "" && !localStorage.getItem("title")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your title",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorTitle: "red" });
        } else if (dob === "" && !localStorage.getItem("dob")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Date of birth",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorDob: "red" });
        } else if (nationality === "" && !localStorage.getItem("nationality")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your nationality",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorNationality: "red",
          });
        } else if (gender === "" && !localStorage.getItem("gender")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your gender",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorGender: "red" });
        } else if (state === "" && !localStorage.getItem("state")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your State of Origin",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorState: "red" });
        } else if (lga === "" && !localStorage.getItem("lga")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Local Government Area",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorLga: "red" });
        } else if (nifst === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your NIFST number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorNIFST: "red" });
        }  else if (
          qualification === ""
        ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Qualification",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorQual: "red" });
        } else if (
          this.state.qualificationYear === ""
        ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Year of Qualification",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorQualYear: "red" });
        }  else if (phone === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Phone Number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPhone: "red" });
        } else if (email === "") {
          Swal.fire({
            title: "Empty",
            text: "Please verify your email",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorEmail: "red" });
        }
        else if (mailingAddres === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Mailing Address",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorMailAddress: "red",
          });
        } else if (orgName === "" && !localStorage.getItem("orgName")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Organization Name",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgName: "red" });
        } else if (position === "" && !localStorage.getItem("position")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your position in your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPosition: "red" });
        } else if (address === "" && !localStorage.getItem("address")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify address of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorAddress: "red" });
        } else if (orgPhone === "") {
          Swal.fire({
            title: "Empty",
            text: "Please specify phone number of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgPhone: "red" });
        } else if (orgEmail === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify email of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgEmail: "red" });
        }else if(this.state.practiceCat === ""){
          Swal.fire({
            title: "Empty",
            text: "Please specify your practice category",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPraCat: "red" });
        }else if(this.state.prevLicense == ""){
          Swal.fire({
            title: "Empty",
            text: "Please enter your previous license number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPrevLic: "red" });
        }else if(this.state.curLicense == ""){
          Swal.fire({
            title: "Empty",
            text: "Please enter your curent license number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorCurLic: "red" });
        }else if(this.state.curLicenseDate == ""){
          Swal.fire({
            title: "Empty",
            text: "Please enter the date your current license was issued",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorCurLicDate: "red" });

        }else if(this.state.prevLicenseDate == ""){
          Swal.fire({
            title: "Empty",
            text: "Please enter the date you obtained your previous license",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorPrevLicDate: "red" });
        }else if(this.state.regType == ""){
          Swal.fire({
            title: "Empty",
            text: "Please indicate registration type",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorRegType: "red" });
        }


        else if (boxAll === false) {
          Swal.fire({
            title: "Accept The Terms",
            text: "Please accept the terms and conditions",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false });
        } else {
          this.registerLicense();
        }
      };

      registerLicense = async () => {
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            DOB: this.state.dob,
            applicationdaterecieved: "2022-03-20T10:18:41.458Z",
            applicationdateupdated: "2022-03-20T10:18:41.458Z",
            applicationstatus: "pending",
            contactaddress: this.state.contactaddress,
            contactemail: this.state.contactemail,
            contacttelephone: this.state.contacttelephone,
            educationperformance: this.state.educationperformance,
            formtype: this.state.regType,
            gender: this.state.gender,
            lga: this.state.lga,
            licensedate: this.state.curLicenseDate,
            licenseexpdate: this.state.expDate,
            licensenumber: this.state.curLicense,
            licenseremarks: "remarks",
            nationality: this.state.nationality,
            organization: this.state.orgName,
            organizationaddress: this.state.organizationaddress,
            organizationemail: this.state.orgEmail,
            organizationposition: this.state.position,
            organizationtelephone: this.state.orgPhone,
            othernames: this.state.othername,
            practicecategory: this.state.practiceCat,
            previouslicensedate: this.state.prevLicenseDate,
            previouslicensenumber: this.state.prevLicenseDate,
            previoussurname: this.state.prevSurname,
            qualification1: this.state.qualification,
            qualification2: this.state.qualification2,
            qualification3: this.state.qualification2,
            qualification4: this.state.qualification2,
            qualification5: this.state.qualification2,
            registrationnumber: this.state.nifst,
            state: this.state.state,
            surname: this.state.surname,
            title: this.state.title,
            userid: localStorage.getItem("userid"),
            yearofqualification: this.state.yearofqualification

          }),
        };
        fetch(`${baseUrl}/v2/license/create-license`, obj)
      .then(async (response) => {
        if (response.status === 200) {
          const responseJson = await response?.json();
          localStorage.setItem("rrr", responseJson.rrr);
          localStorage.setItem("licenseId", responseJson.licenseId )

          makePayment('license', '/v2/license/create-license')
          this.setState({ loading: false, disabled: false });
        }

        if (response.status === 400) {
          const responseJson = await response.json();
          Swal.fire({
            title: "Error",
            text: responseJson?.violations[0]?.message || responseJson?.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/new-dashboard")
          });
        }

        if (response.status === 500) {
          Swal.fire({
            title: "Error",
            text: "Unable to apply for a license at the moment. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }

        this.setState({ loading: false, disabled: false });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });

      };


    render(){
        const { loading } = this.state;
        return(
            <div>

                <div className="text-center">
                <img
              src={logo}
              style={{
                width: "10%",
                position: "relative",
                top: 35,
                alignSelf: "center",
                margin: "0 auto",
              }}
            />
            </div>
                  <h3
                      className="nicfostText"
                      style={{
                        position: "relative",
                        fontWeight: "bold",
                        top: 55,
                        textAlign: "center",
                      }}
                    >
                      NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)
                    </h3>
                    <h5
                      style={{
                        position: "relative",
                        fontWeight: "bold",
                        top: 65,
                        textAlign: "center",
                      }}
                    >
                      APPLICATION FORM FOR LICENCE OR RENEWAL TO PRACTICE AS A CERTIFIED FOOD SCIENTIST
                    </h5>
                    <div className="container" style={{ marginTop: 65, padding: 85 }}>
                      <div style={{ marginTop: 25 }}></div>
                      <form className="row">
                        <label
                          className="mb-3 h4"
                          style={{ color: "green" }}
                          htmlFor="floatingInputCustom"
                        >
                          Personal Information
                        </label>
                        <br />
                        <br />
                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorSurname }}
                            className="form-label"
                          >
                            Surname <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              defaultValue={localStorage.getItem("surname")}
                              onChange={(e) => this.setState({ surname: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            className="form-label text-dark"
                          >
                            Previous Surname (If applicable)
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              onChange={(e) => this.setState({ prevSurname: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorFirstName }}
                            className="form-label"
                          >
                            First name <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              defaultValue={localStorage.getItem("firstname")}
                              onChange={(e) => this.setState({ firstname: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorOthername }}
                            className="form-label"
                          >
                            Other name(s) <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ othername: e.target.value })}
                              defaultValue={localStorage.getItem("othername")}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorTitle }}
                            className="form-label"
                          >
                            Title <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <select
                              className="form-control"
                              aria-label="Select title"
                              onChange={this.handleChange}
                              style={{ color: this.state.colorTitle }}
                            >
                              <option
                                value={
                                  localStorage.getItem("title")
                                    ? this.state.title
                                    : "-- Select title --"
                                }
                                selected
                              >
                                {localStorage.getItem("title")
                                  ? localStorage.getItem("title")
                                  : "-- Select title --"}
                              </option>
                              <option value="mr">Mr.</option>
                              <option value="mrs">Mrs.</option>
                              <option value="ms">Ms.</option>
                              <option value="dr">Dr.</option>
                              <option value="prof">Prof.</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorDob }}
                            className="form-label"
                          >
                            DOB <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              id="datepicker"
                              type="date"
                              autocomplete="off"
                              className="form-control shadow-none"
                              onChange={(e) => this.setState({ dob: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            className="form-label"
                            style={{ color: this.state.colorNationality }}
                          >
                            Nationality <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              type="text"
                              className="form-control shadow-none"
                              onChange={(e) =>
                                this.setState({ nationality: e.target.value })
                              }
                              defaultValue={localStorage.getItem("nationality")}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            className="form-label"
                            style={{ color: this.state.colorGender }}
                          >
                            Select Gender <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <select
                              className="form-control shadow-none"
                              aria-label="Select"
                              onChange={this.handleGenderChange}
                              style={{ color: this.state.colorGender }}
                            >
                              <option
                                value={
                                  localStorage.getItem("gender")
                                    ? localStorage.getItem("gender")
                                    : "gender"
                                }
                                selected
                              >
                                {localStorage.getItem("gender")
                                  ? localStorage.getItem("gender")
                                  : "-- Select gender --"}
                              </option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorState }}
                            className="form-label"
                          >
                            Select State <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <select
                              className="form-control shadow-none"
                              aria-label="Select state"
                              onChange={this.handleStateChange}
                              style={{ color: this.state.colorState }}
                            >
                              <option
                                value={
                                  localStorage.getItem("state")
                                    ? localStorage.getItem("state")
                                    : "-- Select state --"
                                }
                                selected
                              >
                                {localStorage.getItem("state")
                                  ? localStorage.getItem("state")
                                  : "-- Select state --"}
                              </option>

                              {this.getStates()}
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorLga }}
                            className="form-label"
                          >
                            LGA <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ lga: e.target.value })}
                              defaultValue={localStorage.getItem("lga")}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorNIFST }}
                            className="form-label"
                          >
                            NIFST Registration Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              onChange={(e) => this.setState({ nifst: e.target.value })}
                            />
                          </div>
                        </div>

                        {/* <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorRemita }}
                            className="form-label"
                          >
                            Remita Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ remitaNo: e.target.value })}
                              defaultValue={localStorage.getItem("remita")}
                            />
                          </div>
                        </div> */}

                        <hr />
                        <div className="row" style={{padding: 20}}>
                        <label
                          className="mb-3 h4"
                          style={{ color: "green" }}
                          htmlFor="floatingInputCustom"
                        >
                          First Qualification
                        </label>
                        <br />
                        <br />

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorQual }}
                            className="form-label"
                          >
                            Qualification <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <select
                              className="form-control shadow-none"
                              aria-label="Select"
                              onChange={this.handleQualificationChange}
                            >
                              <option
                                 disabled
                                selected
                              >
                                -- Select qualification --
                              </option>
                              <option value="SSCE">SSCE</option>
                              <option value="OND">OND</option>
                              <option value="BSC">BSC</option>
                              <option value="MSC">MSC</option>
                              <option value="PHD">PHD</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorQualYear }}
                            className="form-label"
                          >
                            Year of Qualification <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) =>
                                this.setState({ qualificationYear: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        </div>

                        <hr />

                        <div className="row" style={{padding: 15}}>
                        <label
                          className="mb-3 h4"
                          style={{ color: "green" }}
                          htmlFor="floatingInputCustom"
                        >
                          Second Qualification
                        </label>
                        <br />
                        <br />


                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            className="form-label"
                          >
                          Second Qualification <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <select
                              className="form-control shadow-none"
                              aria-label="Select"
                              onChange={this.handleQualificationChange2}
                            >
                              <option

                                selected
                                disabled
                              >
                                 -- Select qualification --
                              </option>
                              <option value="SSCE">SSCE</option>
                              <option value="OND">OND</option>
                              <option value="BSC">BSC</option>
                              <option value="MSC">MSC</option>
                              <option value="PHD">PHD</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            className="form-label"
                          >
                            Year of Qualification <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) =>
                                this.setState({ qualificationYear2: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        </div>

                        <hr />

                        <div className="row" style={{padding: 15}}>

                        <label
                          className="mb-3 h4"
                          style={{ color: "green" }}
                          htmlFor="floatingInputCustom"
                        >
                          Contact Information
                        </label>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPhone }}
                            className="form-label"
                          >
                            Phone <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="phone"
                              required="required"
                              onChange={(e) => this.setState({ phone: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label style={{ color: this.state.colorEmail }} className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="email"
                              required="required"
                              onChange={(e) => this.setState({ email: e.target.value })}
                            />
                          </div>
                        </div>

                        {localStorage.getItem('userid') ? null :
                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPassword }}
                            className="form-label"
                          >
                            Pasword <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ password: e.target.value })}
                              defaultValue={localStorage.getItem("password")}
                            />
                          </div>
                        </div>
                         }

                        {localStorage.getItem('userid') ? null :
                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPasswordConf }}
                            className="form-label"
                          >
                            Confirm Pasword <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) =>
                                this.setState({ confirmPassword: e.target.value })
                              }
                              defaultValue={localStorage.getItem("confirmPassword")}
                            />
                          </div>
                        </div>
                         }

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorMailAddress }}
                            className="form-label"
                          >
                            Mailing Address <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>

                            <textarea
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) =>
                                this.setState({ mailingAddres: e.target.value })
                              }
                            ></textarea>
                          </div>
                        </div>
                        </div>

                        <hr />
                        <label
                          className="mb-3 h4"
                          style={{ color: "green" }}
                          htmlFor="floatingInputCustom"
                        >
                          Place of work
                        </label>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorOrgName }}
                            className="form-label"
                          >
                            Name of Organization/Institution{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ orgName: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPosition }}
                            className="form-label"
                          >
                            Position <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ position: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorAddress }}
                            className="form-label"
                          >
                            Address <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ address: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorOrgPhone }}
                            className="form-label"
                          >
                            Organization Telephone <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ orgPhone: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorOrgEmail }}
                            className="form-label"
                          >
                            Organization Email <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              required="required"
                              onChange={(e) => this.setState({ orgEmail: e.target.value })}
                            />
                          </div>
                        </div>

                        <hr />

                        <label
                          className="h4"
                          htmlFor="floatingInputCustom"
                          style={{ color: "green" }}
                        >
                         Practice Category
                        </label>
                        <br />
                        <br />

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPraCat }}
                            className="form-label"
                          >
                            Practice Category <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <select
                              className="form-control shadow-none"
                              aria-label="Select"
                              onChange={this.handlePracticeChange}
                              style={{ color: this.state.colorGender }}
                            >
                              <option selected disabled>
                                -- Select category --
                              </option>
                              <option value="ACADEMIC">ACADEMIC</option>
                              <option value="RESEARCH">RESEARCH</option>
                              <option value="GOVERNMENT">GOVERNMENT</option>
                              <option value="REGULATORY">REGULATORY</option>
                              <option value="INDUSTRY">INDUSTRY</option>
                              <option value="PRIVATE PRACTICE">PRIVATE PRACTICE</option>
                              <option value="NGO">NGO</option>
                              <option value="INTERNATIONAL DEVELOPMENT">INTERNATIONAL DEVELOPMENT</option>
                            </select>
                          </div>
                        </div>

                        <hr />

                        <label
                          className="h4"
                          htmlFor="floatingInputCustom"
                          style={{ color: "green" }}
                        >
                         Current License
                        </label>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorCurLic }}
                            className="form-label"
                          >
                            License Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              onChange={(e) => this.setState({ curLicense: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorCurLicDate }}
                            className="form-label"
                          >
                            Date <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="date"
                              required="required"
                              onChange={(e) => this.setState({ curLicenseDate: e.target.value })}

                            />
                          </div>
                        </div>


                        <hr />

                        <label
                          className="h4"
                          htmlFor="floatingInputCustom"
                          style={{ color: "green" }}
                        >
                         Previous License
                        </label>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPrevLic }}
                            className="form-label"
                          >
                            License Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="text"
                              onChange={(e) => this.setState({ prevLicense: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPrevLicDate }}
                            className="form-label"
                          >
                            Date <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="date"
                              required="required"
                              onChange={(e) => this.setState({ prevLicenseDate: e.target.value })}

                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label
                            style={{ color: this.state.colorPrevLicDate }}
                            className="form-label"
                          >
                            Expiry Date <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label"></label>
                            <input
                              className="form-control shadow-none"
                              type="date"
                              required="required"
                              onChange={(e) => this.setState({ expDate: e.target.value })}

                            />
                          </div>
                        </div>

                        <hr />

                        <div className="row" style={{marginTop: 15}}>
                          <label
                                style={{ color: this.state.colorRegType }}
                                htmlFor="floatingInputCustom"
                                className="mb-3"
                              >
                                Choose registration type <span className="text-danger">*</span>
                              </label>

                            <div onChange={this.onChangeValue}>
                          <Form.Floating className="mb-3">
                              <input
                               class="form-check-input shadow-none"
                                type="radio"
                                name="reg"
                                required="required"
                                value="new registration"
                                onChange={(e) => this.setState({ surname: e.target.value })}
                              />
                               {' '}New registration
                            </Form.Floating>
                          </div>

                          <div className="row">
                          <Form.Floating className="mb-3">
                              <input
                               class="form-check-input shadow-none"
                                type="radio"
                                name="reg"
                                required="required"
                                value="renewal"
                                onChange={(e) => this.setState({ surname: e.target.value })}
                              />
                               {' '}Renewal
                            </Form.Floating>
                          </div>

                          </div>


                        <div className="row" style={{ marginTop: 25 }}>
                          <Form.Floating className="mb-3">
                            <input
                              class="form-check-input shadow-none"
                              type="checkbox"
                              id="checkBoxUS"
                              name="US"
                              value="US"
                              onChange={this.handleCheckChange}
                            />{" "}
                            I hereby certify that I am not a registered and practicing
                            member of an allied profession and the particulars furnished
                            herein are true
                          </Form.Floating>
                        </div>

                        <div
                        className="text-center"
                          style={{
                            margin: "auto",
                            width: "100%",
                            marginTop: 45,
                            marginBottom: "15vh",
                          }}
                        >
                          <button
                            disabled={this.state.disabled}
                            style={{
                              alignSelf: "center",
                              width: "60%",
                              backgroundColor: "#003314",
                            }}
                            className="btn btn-success btn-lg"
                            onClick={(e) => this.checkValidation(e)}
                          >
                            {loading ? (
                              <Spinner animation="border" variant="light" size="sm" />
                            ) : (
                              <span className="font-weight-bold">
                                {/* APPLY <i class="fas fa-chevron-right"></i> */}
                                SUBMIT APPLICATION
                              </span>
                            )}
                          </button>

            </div>
            </form>
            </div>
            <Footer />
            </div>
        )
    }
}

export default License;
