import React from 'react';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
const baseUrl = process.env.REACT_APP_BASE_URL;

export const renderLicenseStatus = (
  hasLicenseApplication,
  licenseStatus,
  paymentStatus,
  licenseExpired,
  licenseId,
  makePaymentReponse,
  translateLicenseStatusCode,
  licenseExpDate,
  context = 'dashboard'
) => {
  if (!hasLicenseApplication) {
    return (
      <Link to="/register-license" className="btn btn-primary m-0 p-1 px-3">
        Apply Now
      </Link>
    );
  } 
  
    if (licenseStatus === "pending" && 
             (paymentStatus === "uninitialized" || 
              paymentStatus === "initialized" || 
              paymentStatus === "pending_payment")) {
    return makePaymentReponse();
  } 
  
  
  if (licenseStatus === "rejected") {
    if (context === 'sidebar') {
      return (
        <Link to={`/new-renew-license/${licenseId}`} className="btn btn-primary m-0 p-1 px-3">
          Apply Again
        </Link>
      );
    }
    return (
      <>
        <p>APPLICATION REJECTED</p>
        <Link to={`/new-renew-license/${licenseId}`} className="btn btn-primary m-0 p-1 px-3">
          Apply Again
        </Link>
      </>
    );
  }
  
  // Check for expired license
  if (licenseExpired) {
    return (
      <Link to={`/new-renew-license/${licenseId}`} className="btn btn-primary m-0 p-1 px-3">
        Renew License
      </Link>
    );
  }
  
  console.log('licenseStatus', licenseStatus);
  console.log('licenseExpired', licenseExpired);
  console.log('licenseExpDate', licenseExpDate);
  
    // If license is approved and within 60 days of expiry, show renewal button
  if (licenseStatus === "approved" && checkLicenseRenewalNeeded(licenseExpDate)) {
    if (context === 'sidebar') {
      return (
        <Link to={`/new-renew-license/${licenseId}`} className="btn btn-warning m-0 p-1 px-3">
          Renew Soon
        </Link>
      );
    }
    return (
      <>
        {typeof translateLicenseStatusCode === 'function' && translateLicenseStatusCode(licenseStatus, paymentStatus)}
        <div className="mt-2">
          <Link to={`/new-renew-license/${licenseId}`} className="btn btn-warning m-0 p-1 px-3">
            Renew Soon
          </Link>
        </div>
      </>
    );
  }

  // Default case - just show the status
  if (context === 'sidebar') {
    return <></>;
  }
  return typeof translateLicenseStatusCode === 'function' 
    ? translateLicenseStatusCode(licenseStatus, paymentStatus)
    : 'Status Unknown';
};

export const checkLicenseRenewalNeeded = (expiryDate, applicationStatus) => {
  if (!expiryDate) return false;
  if (!applicationStatus || applicationStatus === "pending") return false;
  
  const today = new Date();
  const expiry = new Date(expiryDate);
  const daysUntilExpiry = Math.ceil((expiry - today) / (1000 * 60 * 60 * 24));
  
  return daysUntilExpiry <= 60 && daysUntilExpiry > 0;
};

export  const  translateLicenseStatusCode = (applicationStatus, paymentStatus) => {
    let statusText = "Unknown";
    let bgColor = "gray";

    if (applicationStatus === "approved") {
        statusText = "Approved";
        bgColor = "green";
    } else if (applicationStatus === "rejected") {
        statusText = "Rejected";
        bgColor = "red";
    } else if (applicationStatus === "pending") {
      if (paymentStatus === "complete") {
        statusText = "Approval Pending";
        bgColor = "orange";
      } else if (paymentStatus === "pending_confirmation") {
        statusText = "Verifying Payment";
        bgColor = "blue";
      } else if (paymentStatus === "pending_payment" || paymentStatus === "initialized") {
        statusText = "Make Payment";
        bgColor = "purple";
      } else if (paymentStatus === "uninitialized") {
        statusText = "Make Payment";
        bgColor = "purple";
      } else if (paymentStatus === "failed" || paymentStatus === "hash_error") {
        statusText = "Payment Failed";
        bgColor = "red";
      }
    }
    return (
      <span
          style={{
              backgroundColor: bgColor,
              color: "white",
              padding: "5px 10px",
              borderRadius: "5px",
              fontSize: "14px",
              fontWeight: "bold",
              display: "inline-block",
              textAlign: "center"
          }}
      >
          {statusText}
      </span>
  );
  };
  

