import React, { PureComponent } from 'react';
import moment from 'moment';
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
 

const baseUrl = process.env.REACT_APP_BASE_URL


class VerifyMembership extends PureComponent {

  constructor(props){
    super(props);
    this.state = {
      surname: "",
      postsPerPage: 10,
      currentPage: 1,
      data: [],
      userData: [],
      isLoading: false,
      isPreviewLoading: false,
      disabled: false,
      showResults: false,
    }
  }

  showPagination = () => {
  const { postsPerPage, data } = this.state;
  const pageNumbers = [];
  const totalPosts = data.length;
  for(let i = 1; i<= Math.ceil(totalPosts/postsPerPage); i++){
    pageNumbers.push(i)
  }

   const paginate = (pageNumbers) => {
     this.setState({currentPage: pageNumbers})
   }

    return(
      <nav>
      <ul className="pagination">
      {pageNumbers.map(number => (
        <li key={number} className={this.state.currentPage === number ? 'page-item active' : 'page-item'}>
        <button onClick={()=> paginate(number)} className="page-link">
          { number }
        </button>
       </li>
     ))}
      </ul>
      </nav>
    )
  }

  showTable = () => {
    const { postsPerPage, currentPage, data } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    try {
      return currentPosts.map((item, index) => {
        return (
          <tbody>
           <tr>
           <td>
           <span className="text-xs font-weight-bold">{postsPerPage * (currentPage -1)+index+1 }</span>
           </td>
           <td className="text-xs text-secondary text-capitalize font-weight-bold">{`${item.surname}`}</td>
           <td className="text-xs text-secondary text-capitalize font-weight-bold">{item.othernames}</td>
           <td className="text-xs text-secondary text-capitalize font-weight-bold">{(item.nifstregistrationnumber)}</td>
           <td className="text-xs font-weight-bold">{moment(item.applicationdate).format('LL') === "Invalid date" ? null :moment(item.applicationdate).format('LL') }</td>
           {/*<td>
          <button className="btn btn-primary-2 mb-0" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false"><span class="iconify" data-icon="charm:menu-meatball" style={{fontSize: 'large'}} ></span></button>
          <ul className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4" aria-labelledby="#dropdownMenuButton2">

          <li className="mb-2" id = {item.formid} onClick={() => this.showUser(item.formid)} data-bs-toggle="modal" data-bs-target="#viewMember">
            <a className="dropdown-item border-radius-md" href="javascript:;">
              <div className="d-flex py-1">
                  <h6 className="text-sm font-weight-normal mb-1">
                    <span className="font-weight-bold">View</span>
                  </h6>
              </div>
            </a>
          </li>
        </ul>
        </td> */}
        <td></td>
     </tr>
     </tbody>
            );
      });
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.message,
        type: "error",
      })
    }
  };

  searchMember = async () => {
    this.setState({ isLoading: true, disabled: true });
    let obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify({
        query: this.state.surname.trim()
      }),
    };
    await fetch(`${baseUrl}/Registration/getRegistrationByQuery`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.status === 401) {
            this.setState({ isLoading: false, disabled: false, showResults: false });
            Swal.fire({
              title: "Session Expired",
              text: "Session expired. Please login",
              icon: "error",
              confirmButtonText: "OK",
            }).then(() => {
              this.props.history.push("/login");
            });
          }else{
            console.warn(responseJson);
          this.setState({data: responseJson, isLoading: false, disabled: false, showResults: true})
          }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  showUser = (formid) => {
    const url = `${baseUrl}/Registration/getRegistrationByFormID/${formid}`;
    this.setState({isPreviewLoading: true});
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(res => res.json())
      .then(res => {
        this.setState({
          isPreviewLoading: false,
          userData: res,
        });
      })
      .catch(error => {
        this.setState({error: true, isPreviewLoading: false});
        alert(error);
      });
  }

  render(){
    const { isLoading, disabled, data, isPreviewLoading, showResults } = this.state;
    return(
      <div className="container" style={{ display: 'flex', flexDirection: 'column'}}>
      <center className="mb-4">
        <img src="../assets/images/nicfost.png" className="navbar-brand-img" style={{ width: 90, height: 90, marginTop: 27, marginBottom: 18}} alt="main_logo" />
        <h2 className="text-uppercase">Verify CFSN Status </h2>
        <a href="https://nicfost.gov.ng">Go Back Home</a>
      </center>

      <p className="mt-4 font-weight-bold" style={{ textAlign: 'center', position: 'relative', right: 100}}>Enter surname / NiCFoST Registration Number</p>
      <div className="tab-content container-fluid" id="nav-tabContent">
      <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
      <center>
      <form  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center', width: '50%'}}>
      <div className="input-group input-group-outline mb-3">
      <input type="text" className="form-control shadow-none col-lg-8"
      onChange={(e) =>
        this.setState({ surname: e.target.value })
      } />
      </div>
      <button
        style={{backgroundColor: '#003314'}}
        className="btn btn-success col-lg-4 btn-lg"
        onClick={(e) => this.searchMember()}
        disabled={disabled}
      >
        {isLoading ? (
          <Spinner animation="border" variant="light" size="sm" />
        ) : (
          "Search"
        )}
      </button>
      </form>
      </center>
      </div>
      </div>

      {showResults &&
      <div className="container-fluid justify-content-center py-4" style={{ width: '100%', position: 'relative', left: 0 }}>
      <div class="container-fluid px-4">
        <div class="rown">
          <div class="col-12">
            <div class="card my-3">
              <div class="card-header pb-0 bg-success">
                <div class="d-flex flex-wrap align-items-center justify-content-center">
                  <h5 className="mb-4 text-light">{`Membership information for ${this.state.surname.toUpperCase()} `}</h5>
                </div>
              </div>
              <div class="card-body">

        {isLoading ?  <center><Spinner animation="border" variant="success" size="lg" style={{ alignSelf: 'center' }} className="text-center" /></center> :
      <div class="table-responsive p-0 pb-2">
      <table id="table" className="table align-items-center justify-content-center mb-0">
        <thead>
            <tr>
            <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">S/N</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Surname</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Othernames</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Reg No.</th>
            <th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Date Joined</th>
            {/*<th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">Action</th> */}
            </tr>
        </thead>
        {data.length > 0 ? this.showTable(): 'No records found'}
      </table>
      </div> }

      <div style={{float: 'right'}}>
      {this.showPagination()}
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
    }


           {/* View Modal */}
             <div className="modal fade" id="viewMember" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
               <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
                 <div className="modal-content">
                   <div className="modal-header d-flex align-items-center justify-content-between">
                     <h5 className="modal-title">Details</h5>
                     <button type="button" className="btn btn-link m-0 p-0 text-dark fs-4" data-bs-dismiss="modal" aria-label="Close"><span class="iconify" data-icon="carbon:close"></span></button>
                   </div>
                   <div className="modal-body">
                     <div className="row">
                       <div className="d-flex px-3">
                       {/*
                         <div className="my-auto text-center">
                           <img src="../assets/img/account.svg" className="avatar avatar-exbg  me-4 "/>
                         </div> */}
                         { isPreviewLoading ? <center><Spinner animation="border" className="text-center" variant="success" size="lg" /></center>  :
                         <div className="d-flex flex-column">
                           {this.state.userData.map((item) => {
                             return (
                               <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: !localStorage.getItem("token") ? '90%' : '100%', position: localStorage.getItem("token") ? 'relative' : '', right: 0, padding: 18, float: !localStorage.getItem("token") ? '' : 'right', marginBottom: 90 }}>
                                 <div className="container-fluid px-4">
                                   <div className="rown">
                                     <div className="col-12">
                                       <div className="card my-3 mb-4">
                                         <div className="card-header pb-0 bg-success">
                                           <div className="text-center">
                                             <h5 className="text-light text-center font-weight-bold mb-4">Registration For Food Scientist</h5>
                                           </div>
                                         </div>
                                        {/* <div class="card-body"> */}
                                        <div className="container" style={{ marginTop: 18, padding: 9 }}>
                                          <div style={{ marginTop: 0 }}></div>
                                          <form className="row">
                                            <label
                                              className="mb-3 h4"
                                              style={{ color: "#145973" }}
                                              htmlFor="floatingInputCustom"
                                            >
                                              Personal Information
                                            </label>
                                            <br />
                                            <br />
                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorSurname }}
                                                className="form-label"
                                              >
                                                Surname <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control w-50 shadow-none"
                                                  type="text"
                                                  required="required"
                                                  value={item.surname}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                className="form-label text-dark"
                                              >
                                                Previous Surname
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control w-50 shadow-none"
                                                  type="text"
                                                  value={item.previoussurname}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorOthername }}
                                                className="form-label"
                                              >
                                                Other name(s) <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.othernames}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorTitle }}
                                                className="form-label"
                                              >
                                                Title <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.title}
                                                />
                                              </div>
                                            </div>


                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorOthername }}
                                                className="form-label"
                                              >
                                                DOB <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.DOB}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                className="form-label"
                                                style={{ color: this.state.colorNationality }}
                                              >
                                                Nationality <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  type="text"
                                                  className="form-control shadow-none"
                                                  value={item.nationality}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                className="form-label"
                                                style={{ color: this.state.colorNationality }}
                                              >
                                                Gender <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  type="text"
                                                  className="form-control shadow-none"
                                                  value={item.gender}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                className="form-label"
                                                style={{ color: this.state.colorNationality }}
                                              >
                                                State <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  type="text"
                                                  className="form-control shadow-none"
                                                  value={item.state}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorLga }}
                                                className="form-label"
                                              >
                                                LGA <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  required="required"
                                                  value={item.lga}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorNIFST }}
                                                className="form-label"
                                              >
                                                NIFST Registration Number <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.nifstregistrationnumber}
                                                />
                                              </div>
                                            </div>

                                            <hr />
                                            <label
                                              className="mb-3 h4"
                                              style={{ color: "#145973" }}
                                              htmlFor="floatingInputCustom"
                                            >
                                              Qualification
                                            </label>
                                            <br />
                                            <br />

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorNIFST }}
                                                className="form-label"
                                              >
                                                Course of study
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.courseofstudy}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorNIFST }}
                                                className="form-label"
                                              >
                                                Tertiary Institution
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.tertiaryinstitution}
                                                />
                                              </div>
                                            </div>


                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                className="form-label"
                                                style={{ color: this.state.colorNationality }}
                                              >
                                                Qualification <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  type="text"
                                                  className="form-control shadow-none"
                                                  value={item.qualification}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorQualYear }}
                                                className="form-label"
                                              >
                                                Year of Qualification <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.yearofqualification}
                                                />
                                              </div>
                                            </div>

                                            <hr />

                                            <label
                                              className="mb-3 h4"
                                              style={{ color: "#145973" }}
                                              htmlFor="floatingInputCustom"
                                            >
                                              Contact Information
                                            </label>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorMailAddress }}
                                                className="form-label"
                                              >
                                                Mailing Address <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>

                                                <textarea
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  required="required"
                                                  rows="1"
                                                  value={item.contactaddress}
                                                ></textarea>
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label className="form-label" style={{color:this.state.colorEmail}} >
                                                Email <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="email"
                                                  value={item.contactemail}
                                                />
                                              </div>
                                            </div>


                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label className="form-label" style={{color:this.state.colorEmail}} >
                                                Practice Category <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="email"
                                                  value={item.practicecategory}
                                                />
                                              </div>
                                            </div>

                                            <hr />
                                            <label
                                              className="mb-3 h4"
                                              style={{ color: "#145973" }}
                                              htmlFor="floatingInputCustom"
                                            >
                                              Place of work
                                            </label>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorOrgName }}
                                                className="form-label"
                                              >
                                                Name of Organization/Institution{" "}
                                                <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  value={item.organization}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorPosition }}
                                                className="form-label"
                                              >
                                                Position <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.organizationposition}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorAddress }}
                                                className="form-label"
                                              >
                                                Address <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.organizationaddress}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorOrgPhone }}
                                                className="form-label"
                                              >
                                                Organization Telephone <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.organizationtelephone}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorOrgEmail }}
                                                className="form-label"
                                              >
                                                Organization Email <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.organizationemail}
                                                />
                                              </div>
                                            </div>

                                            <hr />

                                            <label
                                              className="h4"
                                              htmlFor="floatingInputCustom"
                                              style={{ color: "#145973" }}
                                            >
                                              Sponsor Information
                                            </label>
                                            <br />
                                            <br />

                                            <label className="h5" htmlFor="floatingInputCustom">
                                              Sponsor 1
                                            </label>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorSponsor }}
                                                className="form-label"
                                              >
                                                Sponsor Name <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.sponsorname1}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label
                                                style={{ color: this.state.colorSponsorNo }}
                                                className="form-label"
                                              >
                                                Current CFSN Number <span className="text-danger">*</span>
                                              </label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.sponsorcfsnno1}
                                                />
                                              </div>
                                            </div>

                                            <label className="h5" htmlFor="floatingInputCustom">
                                              Sponsor 2
                                            </label>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label className="form-label">Sponsor Name</label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.sponsorname2}
                                                />
                                              </div>
                                            </div>

                                            <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                              <label className="form-label">Current CFSN Number</label>
                                              <div className="input-group input-group-outline mb-3">
                                                <label className="form-label"></label>
                                                <input
                                                  className="form-control shadow-none"
                                                  type="text"
                                                  value={item.sponsorcfsnno2}
                                                />
                                              </div>
                                            </div>



                                            <div style={{ height: 45}} />



                                   </form>
                                 </div>

                                 </div>
                                 </div>
                                 </div>
                                 </div>
                              {/*   </div>*/}
                               </main>
                           )
                           })}
                         </div>
                       }
                       </div>
                       <span className="pt-3"><hr class="dark horizontal my-3" /></span>
                     </div>
                   </div>
                   <div class="modal-footer">
                     <button type="button" data-bs-dismiss="modal" class="btn btn-danger">Close</button>
                   </div>
                 </div>
               </div>
             </div>
        {/* View Member */}


      </div>


    )
  }
}

export default VerifyMembership
