import { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';

const NewDashboardLayout = ({ children, PageName }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [toggleDropdownOpen, setToggleDropdownOpen] = useState(false);
  const [image, setImage] = useState('');
  const [fullName, setFullName] = useState('');

  const ToggleCollapesSidebar = () => {
    setIsSidebarCollapsed(prevState => !prevState);
  };

  const handleToggleDropdown = () => {
    setToggleDropdownOpen(prevState => !prevState);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-2">
          <Sidebar
            isOpen={isSidebarCollapsed}
            ToggleCollapesSidebar={ToggleCollapesSidebar}
          />
        </div>
        <div className="col-md-10">
          <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
            <div className="container border-bottom">
              <div className="d-flex justify-content-between">
                <div
                  onClick={ToggleCollapesSidebar}
                  className="text-center me-2 d-flex align-items-center justify-content-center bg-dark w-15 rounded-circle d-md-none"
                  style={{ height: '50px' }}
                >
                  <i className="material-icons text-center opacity-10 text-light w-20">menu-up</i>
                </div>
                <div className="text-dark text-bold">
                  <h4 style={{ marginTop: 27 }}>{PageName}</h4>
                </div>
                <div onClick={handleToggleDropdown} className="nav-link dropdown-toggle d-none d-sm-inline-block cursor-pointer"  data-bs-toggle="dropdown">
                  <img src={image} className="avatar img-fluid rounded me-1 border rounded-circle mb-" alt="user" /> <span className="text-dark font-weight-bold">{fullName}</span>
                </div>
              </div>
            </div>
            <div className="container-fluid justify-content-center py-4">
              {children}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default NewDashboardLayout;
