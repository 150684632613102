import React, { PureComponent } from "react";
import { Spinner, Form, Alert } from "react-bootstrap";
 
import "bootstrap/dist/css/bootstrap.min.css";
import { states } from "../components/States";
import { countries } from "../components/Countries";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import Sidebar from '../components/Sidebar';
import logo from "../assets/images/logo.png";
import coat from "../assets/images/coat.png";
let TOKEN = "";
let FORM_ID = "";
let PASSPORT_FILEBASE64 = "";
let CryptoJS = require("crypto-js");

const baseUrl = process.env.REACT_APP_BASE_URL


class RegisterPremises extends PureComponent {
  constructor(props) {
    var today = new Date(),
      date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
    super(props);
    this.state = {
      currentDate: date,
      gender: "",
      feeType: [],
      phone: "",
      email: "",
      password: "",
      amount: "",
      payment_description: "",
      payment_id: "",
      payment_sub_category: "",
      payment_category: "",
      rrr: "",
      remitaid: "",
      confirmPassword: "",
      countryCode: "",
      productBrands: "",
      processEquipm: "",
      name: "",
      licenseNo: "",
      orgAddress: "",
      natureBus: "",
      disabled: false,
      nationality: "",
      state: "",
      lga: "",
      boxAll: false,
      orgName: "",
      regType: "",
      nifst: "",
      fileUpload: "",
      companySize: "",
      colorOrgAddress: "black",
      colorNationality: "black",
      colorLga: "black",
      colorImage: "black",
      colorLicesNum: "black",
      colorOrgName: "black",
      colorAddress: "black",
      colorProcessEquip: "black",
      colorState: "black",
      colorGender: "black",
      colorNIFST: "black",
      colorQualYear: "black",
      colorNatureBus: "black",
      colorSurname: "black",
      colorRegType: "black",
      colorPassport: "black",
      colorCompany: "black",
      colorEmail: "black",
      colorPassword: "black",
      colorPhone: "black",
      colorPasswordConf: "black",
      loading: false,
      isUserId: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleGenderChange = this.handleGenderChange.bind(this);
    this.handleCheckChange = this.handleCheckChange.bind(this);
    this.handleCompnaySizeChange = this.handleCompnaySizeChange.bind(this);
    this.handleBusNatureChange = this.handleBusNatureChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  handleChange(e) {
    this.setState({ title: e.target.value });
  }
  handleBusNatureChange(e) {
    this.setState({ natureBus: e.target.value });
  }
  handleStateChange(e) {
    this.setState({ state: e.target.value });
  }

  handleGenderChange(e) {
    this.setState({ gender: e.target.value });
  }
  handleCompnaySizeChange(e){
    this.setState({ companySize: e.target.value });
  }

  handleCheckChange(e) {
    this.setState({ boxAll: e.target.checked }, () => {
    });
  }

  handleCountryChange(e) {
    this.setState({ countryCode: e.target.value });
  }

  getCountry() {
    return countries.map((country) => {
      return <option value={country.dial_code}>{country.name}</option>;
    });
  }


  getStates() {
    return states.map((state) => {
      return <option value={state}>{state}</option>;
    });
  }

  checkAuth(){
    if(localStorage.getItem('userid')){
      this.uploadImage();
    }else{
      this.registerPremises();
    }
  }


  checkLogin = async (e) => {
    var obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        countryid: this.state.countryCode,
        password: this.state.password,
        telephone: this.state.phone,
      }),
    };
    fetch(`${baseUrl}/Users/login`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "Login Successfull") {
          this.setState({ loading: false });
          localStorage.setItem("userid", responseJson.userid);
          localStorage.setItem("email", responseJson.email);
          TOKEN = responseJson.token;
          localStorage.setItem("token", responseJson.token);
          // this.props.history.push("/dashboard");
          this.uploadImage();
        } else if (
          responseJson.message === "Index 0 out of bounds for length 0"
        ) {
          this.register();
        } else {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: responseJson.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  login = async (e) => {
    var obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        countryid: "+234",
        password: this.state.password,
        telephone: this.state.phone,
      }),
    };
    fetch(`${baseUrl}/Users/login`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (responseJson.message === "Login Successfull") {
          this.setState({ loading: false });
          localStorage.setItem("userid", responseJson.userid);
          localStorage.setItem("email", responseJson.email);
          TOKEN = responseJson.token;
          localStorage.setItem("token", responseJson.token);
          this.uploadImage();
        } else if (
          responseJson.message === "Index 0 out of bounds for length 0"
        ) {
          this.setState({ loading: false });
          Swal.fire({
            title: "Error!",
            text: "Invalid username or password",
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: responseJson.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  uploadImage = async () => {
    this.setState({ loading: true });
    var obj = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        imageToBase64String: PASSPORT_FILEBASE64,
        userid: localStorage.getItem("userid"),
      }),
    };
    await fetch(`${baseUrl}/Registration/uploadRegistrationImage`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        console.warn(responseJson);
        if (responseJson.message === "Image Updated Successfully") {
          FORM_ID = responseJson.formid;
          this.registerPremises();
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  AlertDismissibleExample = () => {
      return (
        <div style={{ position: 'relative', top: 0, width: '100%', marginLeft: 0 }}>
        <Alert className="text-light font-weight-bold" variant="warning" onClose={() => this.toggleInfo()} dismissible>
          <Alert.Heading className="text-light">Important Information on updating your Registration Information Earlier Supplied!</Alert.Heading>
          <p style={{fontWeight: 'bold'}}>
           EVERY REGISTERED FOOD SCIENTIST SHOULD SEND IMMEDIATE NOTIFICATION OF ANY CHANGE OF ADDRESS/DATA SUPPLIED TO THE REGISTRAR OF THE COUNCIL.
          </p>
        </Alert>
        </div>
      )
    }

    checkLogin = async () => {
      var obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          countryid: this.state.countryCode,
          password: this.state.password,
          telephone: this.state.phone,
        }),
      };
      fetch(`${baseUrl}/Users/login`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "Login Successfull") {
            this.setState({ loading: false });
            localStorage.setItem("userid", responseJson.userid);
            localStorage.setItem("email", responseJson.email);
            TOKEN = responseJson.token;
            localStorage.setItem("token", responseJson.token);
            this.props.history.push("/dashboard");
            // this.uploadImage();
          } else if (
            responseJson.message === "Index 0 out of bounds for length 0"
          ) {
            this.register();
          } else {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: responseJson.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    };

    login = async (e) => {
      var obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          countryid: "+234",
          password: this.state.password,
          telephone:this.state.phone,
        }),
      };
      fetch(`${baseUrl}/Users/login`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          // console.warn(responseJson);
          if (responseJson.message === "Login Successfull") {
            this.setState({ loading: false });
            localStorage.setItem("userid", responseJson.userid);
            localStorage.setItem("email", responseJson.email);
            TOKEN = responseJson.token;
            localStorage.setItem("token", responseJson.token);
            this.props.history.push('/dashboard');
          } else if (
            responseJson.message === "Index 0 out of bounds for length 0"
          ) {
            this.setState({ loading: false });
            Swal.fire({
              title: "Error!",
              text: "Invalid username or password",
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: responseJson.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    };

    register = async () => {
      const { password, countryCode, confirmPassword } = this.state;
      this.setState({ loading: true });

      var obj = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // Authorization: "Bearer " + TOKEN,
        },
        body: JSON.stringify({
          confirmpassword: confirmPassword,
          countryid: "+234",
          email: this.state.email,
          password: password,
          telephone: this.state.phone,
        }),
      };
      await fetch(`${baseUrl}/Users/newUser`, obj)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "Registration Successfull") {
            localStorage.setItem("userid", responseJson.userid);
            let req = {
              method: "POST",
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                userid: responseJson.userid,
              }),
            };
            fetch(`${baseUrl}/Users/activateUser`, req)
              .then((response) => response.json())
              .then((responseJson) => {
                // console.warn(responseJson);
                if (responseJson.message === "User Activated Successfully") {
                  this.login();
                } else {
                  this.setState({ loading: false });
                  Swal.fire({
                    title: "Error!",
                    text: responseJson.message,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
                }
              })
              .catch((error) => {
                this.setState({ loading: false, disabled: false });
                Swal.fire({
                  title: "Error!",
                  text: error.message,
                  icon: "error",
                  confirmButtonText: "OK",
                });
              });
          } else {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: responseJson.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    };

    async handlePassportImage(e){
      return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(e.target.files[0]);

          fileReader.onload = () => {
              resolve(fileReader.result);
              PASSPORT_FILEBASE64 = fileReader.result
          };

          fileReader.onerror = (error) => {
              reject(error);
          };
      });
    }

    makeRemitaPayment = () => {
      let merchantId = "9554487021";
      let apiKey = "520436"
      let serviceTypeId = parseFloat(this.state.remitaid);
      let d = new Date();
      let orderId = d.getTime();
      let totalAmount = `${this.state.amount}`;
      let apiHash = CryptoJS.SHA512(merchantId+serviceTypeId+orderId+totalAmount+apiKey);

      let req = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`,
        },
        body: JSON.stringify({
          "serviceTypeId": `${serviceTypeId}` ,
          "amount": `${this.state.amount}`,
          "orderId": orderId,
          "payerName": `${this.state.orgName}`,
          "payerEmail": this.state.email,
          "payerPhone": this.state.phone,
          "description": `${this.state.payment_description}`
        }),
      };

      fetch(`https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit`, req)
        .then((response) => response.json())
        .then((responseJson) => {
          console.warn(responseJson);
          if (responseJson.status === 401) {
              this.setState({ loading: false });
              Swal.fire({
                title: "Session Expired",
                text: "Session expired. Please login",
                icon: "error",
                confirmButtonText: "OK",
              }).then(() => {
                this.props.history.push("/login");
              });
            }
          else if(responseJson.status === "Payment Reference generated"){
            this.setState({rrr: responseJson.RRR})
            localStorage.setItem("rrr", responseJson.RRR);
            this.initializePayment()
          }
        })
        .catch((error) => {
          this.setState({ loading: false, disabled: false });
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }



      initializePayment = async () => {
        let d = new Date();
        let orderId = d.getTime();

         const obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token")
          },
          body: JSON.stringify({
            amount: `${this.state.amount}`,
            description: `${this.state.payment_description}`,
            formid: FORM_ID,
            formtype: localStorage.getItem("userid") ? "renewal" : "registration",
            message: "Payment Initialized",
            orderid: orderId,
            payer: `${this.state.surname} ${this.state.othername}`,
            paymentcategory: `${this.state.payment_category}`,
            paymentid: `${this.state.payment_id}`,
            paymentsubcategory: `${this.state.payment_sub_category}`,
            rrr: `${this.state.rrr}`,
            userid: localStorage.getItem("userid")
          }),
        };
        await fetch(`${baseUrl}/Payments/initPayment`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            // console.warn(responseJson);
            if (responseJson.status === 401) {
                this.setState({ loading: false });
                Swal.fire({
                  title: "Session Expired",
                  text: "Session expired. Please login",
                  icon: "error",
                  confirmButtonText: "OK",
                }).then(() => {
                  this.props.history.push("/login");
                });
              } else {
                Swal.fire({
                  title: "Success",
                  text: "Premises Registration successful",
                  icon: "success",
                  confirmButtonText: "OK",
                }).then(() => {
                  window.location.replace("remita.html")
                });
                // this.retrievePayments();
              }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };

  checkValidation = async (e) => {
    const {
      gender,
      name,
      licenseNo,
      processEquipm,
      nationality,
      natureBus,
      state,
      phone,
      password,
      confirmPassword,
      email,
      PASSPORT_FILEBASE64,
      orgAddress,
      nifst,
      fileUpload,
      boxAll,
      lga,
      orgName,
    } = this.state;
    this.setState({ loading: true, disabled: true });
    // e.preventDefault();

    if (name === "") {
      Swal.fire({
        title: "Empty",
        text: "Please enter name of lead technologist",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorSurname: "red" });
    } else if (licenseNo === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify technologist license number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorLicesNum: "red" });
    } else if (phone === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your phone number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPhone: "red" });
    } else if (password === "" && !localStorage.getItem('userid') ) {
      Swal.fire({
        title: "Empty",
        text: "Please enter your password",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPassword: "red" });
    } else if (confirmPassword === "" && !localStorage.getItem('userid') ) {
      Swal.fire({
        title: "Empty",
        text: "Please confirm your password",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorPasswordConf: "red" });
    }else if (email === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please your email address",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorEmail: "red" });
    } else if (nifst === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify technologist registration number",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorNIFST: "red" });
    } else if (nationality === "") {
      Swal.fire({
        title: "Empty",
        text: "Please specify your nationality",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorNationality: "red",
      });
    } else if (gender === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your gender",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorGender: "red" });
    } else if (state === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your State of Origin",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorState: "red" });
    } else if (lga === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Local Government Area",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorLga: "red" });
    } else if (orgName === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify your Organization Name",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorOrgName: "red" });
    } else if (orgAddress === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please specify address of your Organization",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorOrgAddress: "red",
      });
    } else if (natureBus === "") {
      Swal.fire({
        title: "Empty",
        text: "Please specify the nature of your business",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorNatureBus: "red" });
    } else if (processEquipm === "" ) {
      Swal.fire({
        title: "Empty",
        text: "Please give a description of your process",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({
        loading: false,
        disabled: false,
        colorProcessEquip: "red",
      });
    } else if (gender === "") {
      Swal.fire({
        title: "Empty",
        text: "Please specify your gender",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorCompany: "red" });
    }else if (PASSPORT_FILEBASE64 === "") {
      Swal.fire({
        title: "Empty",
        text: "Please upload your recent passport photograph",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false, colorImage: "red" });
    }else if (boxAll === false) {
      Swal.fire({
        title: "Accept The Terms",
        text: "Please accept the terms and conditions",
        icon: "error",
        confirmButtonText: "OK",
      });
      this.setState({ loading: false, disabled: false });
    } else {
      this.checkAuth();
    }
  };

  registerPremises = async () => {
    let fees = this.state.companySize.split(",");
    // console.warn(fees);
    this.setState({amount: fees[1].trim(), payment_id: fees[0].trim(), payment_description: fees[2].trim(), paymentcategory: fees[3].trim(), payment_sub_category: fees[4].trim(), remitaid: fees[5].trim()})
    // console.warn(fees);
    let date = new Date();
    let obj = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },

      body: JSON.stringify({
        applicationdate: `${date.getTime()}`,
        businessdescription: this.state.processEquipm,
        businesstype: this.state.natureBus,
        companysize: `${fees[4].trim()}`,
        formtype: localStorage.getItem('userid') ? 'renewal' : 'registration',
        gender: this.state.gender,
        inspectionfees: `${fees[1].trim()}`,
        leadfoodscientistlicensenumber: this.state.licenseNo,
        leadfoodscientistname: this.state.name,
        lga: this.state.lga,
        locationaddress: this.state.orgAddress,
        nationality: this.state.nationality,
        organisationname: this.state.orgName,
        productbrands: this.state.productBrands,
        registrationnumber: this.state.nifst,
        stateofororigin:this.state.state,
        userid: localStorage.getItem("userid"),
      }),
    };
    fetch(`${baseUrl}/Premises/addPremises`, obj)
      .then((response) => response.json())
      .then((responseJson) => {
        // console.warn(responseJson);
        if (responseJson.message == "New Premises added Successfully") {
            this.makeRemitaPayment();
        } else if (responseJson.status == 401) {
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        this.setState({ loading: false, disabled: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  getFeeType = async () => {
    const url = `${baseUrl}/Fees/getFeesByPaymentType/corporate`;
    this.setState({isLoading: true});
    await fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(res => res.json())
      .then(res => {
        if(res.status === 401){
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        }else{
        this.setState({ feeType: res })
        console.warn(res);
      }
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  getBusinessType = async () => {
    const url = `${baseUrl}/Lookups/GetBusinessType`;
    this.setState({isLoading: true});
    await fetch(url, {
      method: 'GET',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(res => res.json())
      .then(res => {
        this.setState({ businessType: res})
      })
      .catch(error => {
        this.setState({error: true, loading: false});
        alert(error);
      });
  }

  showFeeTypes(){
    return this.state.feeType.map((item) => {
      return (
      <option disabled={item.paymentcategory === "annual" ? `` : 'disabled'} value={`${item.paymenttype}, ${item.amount}, ${item.description}, ${item.paymentcategory}, ${item.paymentsubcategory}, ${item.remitaid}`}>{item.paymentcategory === "annual" ? `${item.description.toUpperCase()} (N${item.amount}) - ${item.paymentsubcategory.toUpperCase()}` : ''}</option>
    )
    });
  }

  componentDidMount(){
    this.getFeeType();
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="container">
      {localStorage.getItem("token") &&
      <Sidebar />
      }

        {!localStorage.getItem("token") &&
        <div className="text-center container-fluid px-4 d-flex justify-content-between" style={{width: '85%', justifyContent: 'space-evenly', alignItems: 'center', position: 'relative', top: 63 }}>
        <div>
        <img src={coat} className="navbar-brand-img" alt="main_logo" style={{ width: 126 }} />
        </div>
        <div>
        <h4 className="font-weight-bold text-center">NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)</h4>
        </div>
        <div>
        <img src={logo} className="navbar-brand-img h-100" style={{ width: 81 }} alt="main_logo" />
        </div>
        </div>
        }


        {!localStorage.getItem("token") &&
          <br />
        }
        {!localStorage.getItem("token") &&
          <br />
        }
        {!localStorage.getItem("token") &&
          <br />
        }

        <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg " style={{width: !localStorage.getItem("token") ? '90%' : '80%', position: localStorage.getItem("token") ? 'relative' : '', left: !localStorage.getItem("token") ? 81: '', padding: 18, float: !localStorage.getItem("token") ? '' : 'right' }}>
          <div className="container-fluid px-4">
            <div className="rown">
              <div className="col-12">
                <div className="card my-3">
                  <div className="card-header pb-0 bg-success">
                    <div className="text-center">
                      <h5 className="text-light text-center font-weight-bold mb-4">Application Form for the Registration of Food Business Premises</h5>
                    </div>
                  </div>
                  <div class="card-body">

                    {this.AlertDismissibleExample()}

          <div className="container" style={{ marginTop: 0, padding: 9 }}>
            <div style={{ marginTop: 25 }}></div>
            <form className="row">
              <label
                className="mb-3 h4"
                style={{ color: "green" }}
                htmlFor="floatingInputCustom"
              >
                Personal Information
              </label>
              <br />
              <br />
              <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorSurname }}
                  className="form-label"
                >
                  Name of Lead Food Scientist
                  <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    className="form-control shadow-none"
                    type="text"
                    // defaultValue={localStorage.getItem("lead_food_scientist")}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorLicesNum }}
                  className="form-label"
                >
                  License Number <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    className="form-control shadow-none"
                    type="text"
                    // defaultValue={localStorage.getItem("license_number")}
                    onChange={(e) =>
                      this.setState({ licenseNo: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorNIFST }}
                  className="form-label"
                >
                  NiCFoST Registration Number <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    className="form-control shadow-none"
                    type="text"
                    // defaultValue={localStorage.getItem("reg_number")}
                    onChange={(e) => this.setState({ nifst: e.target.value })}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                <label
                  className="form-label"
                  style={{ color: this.state.colorNationality }}
                >
                  Nationality <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    type="text"
                    className="form-control shadow-none"
                    onChange={(e) =>
                      this.setState({ nationality: e.target.value })
                    }
                    // defaultValue={localStorage.getItem("nationality")}
                  />
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                <label
                  className="form-label"
                  style={{ color: this.state.colorGender }}
                >
                  Select Gender <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <select
                    className="form-control shadow-none"
                    aria-label="Select"
                    onChange={this.handleGenderChange}
                    style={{ color: this.state.colorGender }}
                  >
                    <option selected disabled>
                       -- Select gender --
                    </option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorState }}
                  className="form-label"
                >
                  Select State <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <select
                    className="form-control shadow-none"
                    aria-label="Select state"
                    onChange={this.handleStateChange}
                    style={{ color: this.state.colorState }}
                  >
                    <option
                       value="select state"
                      selected disabled>
                       -- Select state --
                    </option>

                    {this.getStates()}
                  </select>
                </div>
              </div>

              <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorLga }}
                  className="form-label"
                >
                  LGA <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    className="form-control shadow-none"
                    type="text"
                    onChange={(e) => this.setState({ lga: e.target.value })}
                    // defaultValue={localStorage.getItem("lga")}
                  />
                </div>
              </div>

              <hr />

              <div className="row" style={{ padding: 15 }}>
                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Orgnainzation Information
                </label>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOrgName }}
                    className="form-label"
                  >
                    Name of Organization / Company{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="phone"
                      // defaultValue={localStorage.getItem("orgName")}
                      onChange={(e) =>
                        this.setState({ orgName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOrgAddress }}
                    className="form-label"
                  >
                    Address of Business Premises{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>

                    <textarea
                      className="form-control shadow-none"
                      type="text"
                      // defaultValue={localStorage.getItem("orgAddress")}
                      onChange={(e) =>
                        this.setState({ orgAddress: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorNatureBus }}
                    className="form-label"
                  >
                    Nature of Business <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select"
                      onChange={this.handleBusNatureChange}
                      style={{ color: this.state.colorGender }}
                    >
                      <option selected disabled>
                      -- Select category --
                      </option>
                      <option value="MANUFACTURING">MANUFACTURING</option>
                      <option value="STORAGE">STORAGE</option>
                      <option value="RESTAURANT">RESTAURANT</option>
                      <option value="RETAIL">RETAIL</option>
                      <option value="WHOLESALE">WHOLESALE</option>
                      <option value="FOOD SERVICES">FOOD SERVICES</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorProcessEquip }}
                    className="form-label"
                  >
                    Description of process indicating major equipment and
                    product <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>

                    <textarea
                      className="form-control shadow-none"
                      type="text"
                      rows={5}
                      required="required"
                      // defaultValue={localStorage.getItem("processEquipm")}
                      onChange={(e) =>
                        this.setState({ processEquipm: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label className="form-label text-dark">
                    List product brands with their unit packaging sizes if
                    applicable <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>

                    <textarea
                      className="form-control shadow-none"
                      type="text"
                      rows={5}
                      // defaultValue={localStorage.getItem("productBrands")}
                      onChange={(e) =>
                        this.setState({ productBrands: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorCompany }}
                    className="form-label"
                  >
                    Company size <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select"
                      onChange={this.handleCompnaySizeChange}
                      style={{ color: this.state.colorCompany }}
                    >
                      <option
                      selected disabled >
                      -- Select company size --
                      </option>
                      {this.showFeeTypes()}
                    </select>
                  </div>
                </div>
              </div>
              <hr />

                {!localStorage.getItem('userid') &&
              <label
                className="h4"
                htmlFor="floatingInputCustom"
                style={{ color: "#145973" }}
              >
                Create Login Details <br/>
                <span className="text-danger" style={{ fontSize: 18}}>Use this account to login to your dashboard for tracking, renewal and subsequent applications.</span><br/><br/>
              </label>
            }
              {!localStorage.getItem('userid') &&
              <br />
            }
              {!localStorage.getItem('userid') &&
              <br />
            }

                {!localStorage.getItem('userid') &&
              <div className="col-sm-6 col-lg-4 col-md-6 mb-3" style={{ display: 'none'}}>
                <label
                  style={{ color: this.state.colorCountryCode }}
                  className="form-label"
                >
                  Country <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <select
                    className="form-control shadow-none"
                    aria-label="Floating label select example"
                    onChange={this.handleCountryChange}
                  >
                    <option value="choose" selected="selected">
                      -- Select country code --
                    </option>
                    {this.getCountry()}
                  </select>
                </div>
              </div>
            }


              <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorPhone }}
                  className="form-label"
                >
                  Phone <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    className="form-control shadow-none"
                    type="phone"
                    required="required"
                    onChange={(e) => this.setState({ phone: e.target.value })}
                  />
                </div>
              </div>

          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
            <label
              style={{ color: this.state.colorEmail }}
              className="form-label"
            >
              Email <span className="text-danger">*</span>
            </label>
            <div className="input-group input-group-outline mb-3">
              <label className="form-label"></label>
              <input
                className="form-control shadow-none"
                type="email"
                required="required"
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </div>
          </div>


              {!localStorage.getItem('userid') &&
              <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorPassword }}
                  className="form-label"
                >
                  Password <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    className="form-control shadow-none"
                    type="password"
                    required="required"
                    onChange={(e) => this.setState({ password: e.target.value })}
                  />
                </div>
              </div>
               }

              {!localStorage.getItem('userid') &&
              <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                <label
                  style={{ color: this.state.colorPasswordConf }}
                  className="form-label"
                >
                  Confirm Password <span className="text-danger">*</span>
                </label>
                <div className="input-group input-group-outline mb-3">
                  <label className="form-label"></label>
                  <input
                    className="form-control shadow-none"
                    type="password"
                    required="required"
                    onChange={(e) =>
                      this.setState({ confirmPassword: e.target.value })
                    }
                  />
                </div>
              </div>
               }

               {!localStorage.getItem('userid') &&
               <div>
               <label
                 className="mb-2"
                 style={{ color: this.state.colorImage }}
                 htmlFor="floatingInputCustom"
               >
                 Upload Your Recent Passport Photograph
               </label>

               <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                 <input
                   className="form-control shadow-none"
                   type="file"
                   required="required"
                   onChange={this.handlePassportImage}
                 />
               </div>
               </div>
             }

              <div className="row" style={{ marginTop: 25 }}>
                <Form.Floating className="mb-3">
                  <input
                    class="form-check-input shadow-none"
                    type="checkbox"
                    id="checkBoxUS"
                    name="US"
                    value="US"
                    onChange={this.handleCheckChange}
                  />{" "}
                  I hereby certify that the information given above is true
                </Form.Floating>
              </div>

              <div
                className="text-center"
                style={{
                  margin: "auto",
                  width: "100%",
                  marginTop: 45,
                  marginBottom: 0,
                }}
              >
                <button
                  type="button"
                  disabled={this.state.disabled}
                  style={{
                    alignSelf: "center",
                    width: "100%",
                    backgroundColor: "#003314",
                  }}
                  className="btn btn-success btn-lg"
                  onClick={(e) => this.checkValidation()}
                >
                  {loading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : (
                    <span className="font-weight-bold">
                      SUBMIT APPLICATION
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>

          </div>
          </div>
          </div>
          </div>
          </div>
          <Footer />
        </main>
      </div>
    );
  }
}

export default RegisterPremises;
