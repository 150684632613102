import {Component} from 'react'
import NewDashboardLayout from '../components/NewDashboardlayout';
import moment from "moment";
import Swal from 'sweetalert2';


const baseUrl = process.env.REACT_APP_BASE_URL

class NewPayment extends Component { 
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      error: false,
      loading: true,
      currentPage: 1,
      postsPerPage: 15
    }
  }

  showTable = () => {
    const { postsPerPage, currentPage, data } = this.state;
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = data
      .reverse()
      .slice(indexOfFirstPost, indexOfLastPost);

    try {
      return currentPosts.map((item, index) => {
        let lastDay = new Date(new Date().getFullYear(), 11, 31);
        let licenseDate = new Date(item.licensedate);
        const diffDays = Math.round(
          (lastDay - licenseDate) / (1000 * 60 * 60 * 24)
        );

        return (
          <tr>
            <td>{`${item?.title} ${item?.surname} ${item?.othernames}`}</td>
            <td className="d-none d-xl-table-cell">{item?.contacttelephone}</td>
            <td className="d-none d-md-table-cell"> {item?.practicecategory}</td>
            <td className="d-none d-md-table-cell">{moment(item?.applicationdate).format("LL")}</td>
            <td className="d-none d-md-table-cell">{moment(item?.licensedate).format("LL")
              ? moment(item.licensedate).format("LL")
              : "Awaiting Approval"
            }
            </td>
            <td><span className="badge bg-warning p-2"> {diffDays === 0 ? "Expired" : diffDays + " days remaining"}</span></td>
            <td className="d-none d-md-table-cell font-weight-bolder">...</td>
          </tr>
        );
      });
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: e.message,
        type: "error",
      });
    }
  };

  getLicenseRegistration = async () => {
    const url = `${baseUrl}/License/getlicenseByUserID/${localStorage.getItem(
      "userid"
    )}`;
    this.setState({ isLoading: true });
    await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.warn(res);
        if (res.status === 401) {
          Swal.fire({
            title: "Session Expired",
            text: "Session expired. Please login",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/login");
          });
        } else {
          this.setState({ data: res, loading: false });
        }
      })
      .catch((error) => {
        this.setState({ error: true, loading: false });
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  componentDidMount() {
    this.getLicenseRegistration();
  }

  render() {
    return (
      <NewDashboardLayout PageName="Payment History">
        <div className="row">
          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
            <div className="card flex-fill" >
              <div className="d-flex justify-content-between" style={{backgroundColor: "rgb(6 38 1)"}}>
                <div className="">
                  <h5 className="card-title mb-0">{'  '}</h5>
                </div>
                <div className='form-label-group w-25 mt-4 mx-4 text-secondary'>
                  <input type="text"id="inputPassword" className="form-control shadow-none py-2 pl-2 pr-6 rounded-2" placeholder="Search" />
                  <label for="inputPassword" />
                </div>
              </div>
              <table className="table table-hover my-0">
                <thead>
                  <tr classNameName='px-2'>
                    <th>S/N</th>
                    <th>Name</th>
                    <th className="d-none d-xl-table-cell">RRR</th>
                    <th className="d-none d-xl-table-cell"> Payment Description</th>
                    <th>Amount</th>
                    <th className="d-none d-md-table-cell">Message</th>
                    <th className="d-none d-md-table-cell">Transaction Date</th>
                    <th className="d-none d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.showTable()}
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </NewDashboardLayout>
    )
  }

}

export default NewPayment