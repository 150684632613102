import React, { PureComponent } from "react";
import { Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Swal from "sweetalert2";
import { countries } from "../components/Countries";
import { states } from "../components/States";

import moment from "moment";
import Sidebar from "../components/Sidebar";

const baseUrl = process.env.REACT_APP_BASE_URL;

class RenewFoodScientist extends PureComponent {
	constructor(props) {
		const today = new Date(),
			date =
				today.getFullYear() +
				"-" +
				(today.getMonth() + 1) +
				"-" +
				today.getDate();
		super(props);
		this.state = {
			data: [],
			renewData: [],
			foodScientistData: [],
			practiceCategory: [],
			feeType: [],
			title: "",
			tertiaryinstitution: "",
			gender: "",
			surname: "",
			prevSurname: "",
			firstname: "",
			othername: "",
			courseStudy: "",
			dob: "",
			orgPosition: "",
			mailingAddres: "",
			phone: "",
			disabled: false,
			nationality: "",
			categoryPractice: "",
			state: "",
			lga: "",
			boxAll: false,
			address: "",
			password: "",
			confirmPassword: "",
			sponsorName: "",
			sponsorName2: "",
			sponsorNo2: "",
			sponsorNo: "",
			orgName: "",
			orgEmail: "",
			orgPhone: "",
			orgAddress: "",
			qualification: "",
			qualificationYear: "",
			// remitaNo: "",
			fileUpload: "",
			city: "",
			email: "",
			nifst: "",
			countryCode: "",
			colorSurname: "black",
			colorFirstName: "black",
			colorTitle: "black",
			colorEmail: "black",
			colorPractice: "black",
			colorOthername: "black",
			colorNationality: "black",
			colorTetiary: "black",
			colorLga: "black",
			colorPhone: "black",
			colorPassword: "black",
			colorPasswordConf: "black",
			colorSponsor: "black",
			colorSponsorNo: "black",
			colorOrgName: "black",
			colorOrgEmail: "black",
			colorOrgPhone: "black",
			colorAddress: "black",
			// colorCity: "black",
			colorPosition: "black",
			colorState: "black",
			colorGender: "black",
			colorDob: "black",
			colorNIFST: "black",
			colorQualYear: "black",
			// colorRemita: "black",
			colorQual: "black",
			colorMailAddress: "black",
			colorCountryCode: "black",
			colorStudy: "black",
			loading: true,
			isLoading: true,
			isRenewalLoading: false,
			isUserId: false,
			// hideForm: false,
			postsPerPage: 10,
			currentPage: 1,
			fees: "",
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleGenderChange = this.handleGenderChange.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);
		this.handleQualificationChange = this.handleQualificationChange.bind(this);
		this.handleCheckChange = this.handleCheckChange.bind(this);
		this.handleFeeChange = this.handleFeeChange.bind(this);
		this.handlePracticeChange = this.handlePracticeChange.bind(this);
	}

	showTable = () => {
		const { postsPerPage, currentPage, data } = this.state;
		const indexOfLastPost = currentPage * postsPerPage;
		const indexOfFirstPost = indexOfLastPost - postsPerPage;
		const currentPosts = data
			.reverse()
			.slice(indexOfFirstPost, indexOfLastPost);

		try {
			return currentPosts.map((item, index) => {
				return (
					<tbody>
						<tr>
							<td>
								<span className="text-xs font-weight-bold">
									{postsPerPage * (currentPage - 1) + index + 1}
								</span>
							</td>
							<td className="text-xs text-secondary text-capitalize font-weight-bold">{`${item.surname} ${item.othernames}`}</td>
							<td className="text-xs font-weight-bold">{item.contactemail}</td>
							<td className="text-xs font-weight-bold">
								{item.practicecategory}
							</td>
							<td className="text-xs font-weight-bold">
								{moment(item.applicationdate).format("LL")}
							</td>
							<td className="text-xs font-weight-bold">
								{moment(item.licensedate).format("LL") === "Invalid date"
									? null
									: moment(item.licensedate).format("LL")}
							</td>
							<td>
								<button
									className="btn btn-primary-2 mb-0"
									id="dropdownMenuButton2"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<span
										class="iconify"
										data-icon="charm:menu-meatball"
										style={{ fontSize: "large" }}
									></span>
								</button>
								<ul
									className="dropdown-menu  dropdown-menu-end  px-2 py-3 me-sm-n4"
									aria-labelledby="#dropdownMenuButton2"
								>
									<li
										className="mb-2"
										id={item.formid}
										onClick={() => this.getUserDetails(item.formid)}
										data-bs-toggle="modal"
										data-bs-target="#viewScientist"
									>
										<a
											className="dropdown-item border-radius-md"
											href="javascript:;"
										>
											<div className="d-flex py-1">
												<h6 className="text-sm font-weight-normal mb-1">
													<span className="font-weight-bold">View</span>
												</h6>
											</div>
										</a>
									</li>

									{postsPerPage * (currentPage - 1) + index + 1 === 1 && (
										<li
											className="mb-2"
											id={item.formid}
											onClick={() => this.getUserRenewDetails(item.formid)}
											data-bs-toggle="modal"
											data-bs-target="#verifyPayments"
										>
											<a
												className="dropdown-item border-radius-md"
												href="javascript:;"
											>
												<div className="d-flex py-1">
													<h6 className="text-sm font-weight-normal mb-1">
														<span className="font-weight-bold">Renew</span>
													</h6>
												</div>
											</a>
										</li>
									)}

									<li
										className="mb-2"
										id={item.recid}
										onClick={() => this.getUserPaymentDetails(item.recid)}
										data-bs-toggle="modal"
										data-bs-target="#verifyPayments"
									>
										<a
											className="dropdown-item border-radius-md"
											href="javascript:;"
										>
											<div className="d-flex py-1">
												<h6 className="text-sm font-weight-normal mb-1">
													<span className="font-weight-bold">
														Print License
													</span>
												</h6>
											</div>
										</a>
									</li>
								</ul>
							</td>
							<td></td>
						</tr>
					</tbody>
				);
			});
		} catch (e) {
			Swal.fire({
				title: "Error",
				text: e.message,
				type: "error",
			});
		}
	};

	showPagination = () => {
		const { postsPerPage, data } = this.state;
		const pageNumbers = [];
		const totalPosts = data.length;
		for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
			pageNumbers.push(i);
		}

		const paginate = (pageNumbers) => {
			this.setState({ currentPage: pageNumbers });
		};

		return (
			<nav>
				<ul className="pagination">
					{pageNumbers.map((number) => (
						<li
							key={number}
							className={
								this.state.currentPage === number
									? "page-item active"
									: "page-item"
							}
						>
							<button onClick={() => paginate(number)} className="page-link">
								{number}
							</button>
						</li>
					))}
				</ul>
			</nav>
		);
	};

	handleChange(e) {
		this.setState({ title: e.target.value });
	}
	handleStateChange(e) {
		this.setState({ state: e.target.value });
	}

	handleGenderChange(e) {
		this.setState({ gender: e.target.value });
	}

	handleCountryChange(e) {
		this.setState({ countryCode: e.target.value });
	}

	handleQualificationChange(e) {
		this.setState({ qualification: e.target.value });
	}

	handleCheckChange(e) {
		this.setState({ boxAll: e.target.checked }, () => {
			console.log("This returned true or false", this.state.boxAll);
		});
	}

	handleFeeChange(e) {
		this.setState({ fees: e.target.value });
	}

	handlePracticeChange(e) {
		this.setState({ categoryPractice: e.target.value });
	}

	getFeeType = async () => {
		const url = `${baseUrl}/Fees/getFeesByPaymentType/individual`;
		this.setState({ isLoading: true });
		await fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.status === 401) {
					Swal.fire({
						title: "Session Expired",
						text: "Session expired. Please login",
						icon: "error",
						confirmButtonText: "OK",
					}).then(() => {
						this.props.history.push("/login");
					});
				} else {
					this.setState({ feeType: res });
				}
			})
			.catch((error) => {
				this.setState({ error: true, loading: false });
				alert(error);
			});
	};

	getPracticeCategory = async () => {
		const url = `${baseUrl}/Lookups/GetPracticeCategory`;
		await fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res.status === 401) {
					Swal.fire({
						title: "Session Expired",
						text: "Session expired. Please login",
						icon: "error",
						confirmButtonText: "OK",
					}).then(() => {
						this.props.history.push("/login");
					});
				} else {
					this.setState({ practiceCategory: res });
				}
			})
			.catch((error) => {
				this.setState({ error: true, loading: false });
				alert(error);
			});
	};

	showFeeTypes() {
		return this.state.feeType.map((item) => {
			return (
				<option
					disabled={
						item.description === "Membership/Individual Renewal Fee"
							? ``
							: "disabled"
					}
					value={`${item.paymenttype}, ${item.amount}, ${item.description}, ${item.amount}`}
				>
					{item.description === "Membership/Individual Renewal Fee"
						? `${item.description.toUpperCase()} (N${item.amount})`
						: ""}
				</option>
			);
		});
	}

	showPracticeCategory() {
		return this.state.practiceCategory.map((item) => {
			return <option value={item.referencename}>{item.referencename}</option>;
		});
	}

	checkValidation = async (e) => {
		const {
			title,
			gender,
			surname,
			firstname,
			othername,
			prevSurname,
			categoryPractice,
			dob,
			address,
			fees,
			tertiaryinstitution,
			qualification,
			courseStudy,
			nationality,
			state,
			nifst,
			phone,
			password,
			confirmPassword,
			mailingAddres,
			sponsorName,
			boxAll,
			sponsorName2,
			sponsorNo,
			sponsorNo2,
			colorPractice,
			lga,
			orgAddress,
			orgPosition,
			orgEmail,
			orgName,
			orgPhone,
			city,
			email,
			countryCode,
		} = this.state;
		this.setState({ loading: true, disabled: true });
		e.preventDefault();

		if (surname === "" && !localStorage.getItem("surname")) {
			Swal.fire({
				title: "Empty",
				text: "Please enter your surname",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorFirstName: "red" });
		} else if (firstname == "" && !localStorage.getItem("firstName")) {
			Swal.fire({
				title: "Empty",
				text: "Please veirfy your first name",
				icon: "error",
				confirmButtonText: "OK",
			});

			this.setState({ loading: false, disabled: false, colorOtherName: "red" });
		} else if (othername === "" && !localStorage.getItem("othernames")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your other name",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorTitle: "red" });
		} else if (prevSurname === "" && !localStorage.getItem("previoussurname")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your other name",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorTitle: "red" });
		} else if (title === "" && !localStorage.getItem("title")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your title",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({
				loading: false,
				disabled: false,
				colorMailAddress: "red",
			});
		} else if (address === "" && !localStorage.getItem("contactaddress")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Mailing address",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorDob: "red" });
		} else if (dob === "" && !localStorage.getItem("DOB")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Date of birth",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorDob: "red" });
		} else if (nationality === "" && !localStorage.getItem("nationality")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your nationality",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({
				loading: false,
				disabled: false,
				colorNationality: "red",
			});
		} else if (gender === "" && !localStorage.getItem("gender")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your gender",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorGender: "red" });
		} else if (state === "" && !localStorage.getItem("state")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your State of Origin",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorState: "red" });
		} else if (lga === "" && !localStorage.getItem("lga")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Local Government Area",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorState: "red" });
		} else if (courseStudy === "" && !localStorage.getItem("courseofstudy")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your course of study",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorStudy: "red" });
		} else if (
			tertiaryinstitution === "" &&
			!localStorage.hasOwnProperty("tertiaryinstitution")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Tertiary Institution",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorTetiary: "red" });
		} else if (
			nifst === "" &&
			!localStorage.hasOwnProperty("nifstregistrationnumber")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your NIFST number",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorNIFST: "red" });
		} else if (
			qualification === "" &&
			!localStorage.hasOwnProperty("qualification")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Qualification",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorQual: "red" });
		} else if (
			this.state.qualificationYear === "" &&
			!localStorage.hasOwnProperty("yearofqualification")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Year of Qualification",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorQualYear: "red" });
		} else if (
			categoryPractice === "" &&
			!localStorage.hasOwnProperty("practiceCategory")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Practice Category",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorPractice: "red" });
		} else if (countryCode === "" && !localStorage.getItem("token")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Country Code",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({
				loading: false,
				disabled: false,
				colorCountryCode: "red",
			});
		} else if (phone === "" && !localStorage.getItem("token")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Phone Number",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorPhone: "red" });
		} else if (email === "" && !localStorage.getItem("contactemail")) {
			Swal.fire({
				title: "Empty",
				text: "Please verify your email",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorEmail: "red" });
		} else if (localStorage.getItem("userid") == "" && password === "") {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Password",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorPassword: "red" });
		} else if (localStorage.getItem("userid") == "" && confirmPassword === "") {
			Swal.fire({
				title: "Empty",
				text: "Please Confirm your Password",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({
				loading: false,
				disabled: false,
				colorPasswordConf: "red",
			});
		} else if (
			localStorage.getItem("userid") == "" &&
			confirmPassword !== password
		) {
			Swal.fire({
				title: "Error",
				text: "Passwords do not match",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({
				loading: false,
				disabled: false,
				colorPasswordConf: "red",
				colorPassword: "red",
			});
		} else if (
			mailingAddres === "" &&
			!localStorage.getItem("contactaddress")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Mailing Address",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({
				loading: false,
				disabled: false,
				colorMailAddress: "red",
			});
		} else if (orgName === "" && !localStorage.getItem("organization")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your Organization Name",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorOrgName: "red" });
		} else if (
			orgPosition === "" &&
			!localStorage.getItem("organizationposition")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify your position in your Organization",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorPosition: "red" });
		} else if (
			orgAddress === "" &&
			!localStorage.getItem("organizationaddress")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify address of your Organization",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({
				loading: false,
				disabled: false,
				colorOrgAddress: "red",
			});
		} else if (
			orgPhone === "" &&
			!localStorage.getItem("organizationtelephone")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify phone number of your Organization",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorOrgPhone: "red" });
		} else if (
			orgEmail === "" &&
			!localStorage.hasOwnProperty("organizationemail")
		) {
			Swal.fire({
				title: "Empty",
				text: "Please specify email of your Organization",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorOrgEmail: "red" });
		} else if (sponsorName === "" && !localStorage.getItem("sponsorname1")) {
			Swal.fire({
				title: "Empty",
				text: "Please supply at least one sponsor",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorSponsor: "red" });
		} else if (sponsorNo === "" && !localStorage.getItem("sponsorcfsnno1")) {
			Swal.fire({
				title: "Empty",
				text: "Please specify the CSFN of Your Sponsor",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorSponsorNo: "red" });
			// } else if (fileUpload === "") {
			//   Swal.fire({
			//     title: "Empty",
			//     text: "Please upload your recent photo",
			//     icon: "error",
			//     confirmButtonText: "OK",
			//   });
			//   this.setState({ loading: false, disabled: false, colorSponsorNo: "red" });
		} else if (fees === "" && !localStorage.getItem("inspectionfees")) {
			Swal.fire({
				title: "Empty",
				text: "Please select fee",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false, colorSponsorNo: "red" });
		} else if (boxAll === false) {
			Swal.fire({
				title: "Accept The Terms",
				text: "Please accept the terms and conditions",
				icon: "error",
				confirmButtonText: "OK",
			});
			this.setState({ loading: false, disabled: false });
		} else {
			this.renewRegistration();
		}
	};

	// RENEW PREMISES
	renewRegistration = async () => {
		const date = new Date();
		const fees = this.state.fees.split(",");
		// console.warn(fees);
		localStorage.setItem("amount", fees[1].trim());
		localStorage.setItem("description", fees[2].trim());
		localStorage.setItem("category", fees[0].trim());

		const obj = {
			method: "PUT",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},

			body: JSON.stringify({
				DOB:
					this.state.dob !== "" ? this.state.dob : localStorage.getItem("DOB"),
				RRR: "",
				amountpaid: `${fees[1].trim()}`,
				applicationdate: `${date.getTime()}`,
				contactaddress:
					this.state.address !== ""
						? this.state.address
						: localStorage.getItem("contactaddress"),
				contactemail:
					this.state.email !== ""
						? this.state.email
						: localStorage.getItem("contactemail"),
				contacttelephone: "",
				courseofstudy:
					this.state.courseStudy !== ""
						? this.state.courseStudy
						: localStorage.getItem("courseofstudy"),
				formid: localStorage.getItem("formid"),
				gender:
					this.state.gender !== ""
						? this.state.gender
						: localStorage.getItem("gender"),
				lga:
					this.state.lga !== "" ? this.state.lga : localStorage.getItem("lga"),
				nationality:
					this.state.nationality !== ""
						? this.state.nationality
						: localStorage.getItem("nationality"),
				nifstregistrationnumber:
					this.state.nifst !== ""
						? this.state.nifst
						: localStorage.getItem("nifstregistrationnumber"),
				organization:
					this.state.orgName !== ""
						? this.state.orgName
						: localStorage.getItem("organization"),
				organizationaddress:
					this.state.orgAddress !== ""
						? this.state.orgAddress
						: localStorage.getItem("orgAddress"),
				organizationemail:
					this.state.orgEmail !== ""
						? this.state.orgEmail
						: localStorage.getItem("organizationemail"),
				organizationposition:
					this.state.orgPosition !== ""
						? this.state.orgPosition
						: localStorage.getItem("organizationposition"),
				organizationtelephone:
					this.state.orgPhone !== ""
						? this.state.orgPhone
						: localStorage.getItem("organizationtelephone"),
				othernames:
					this.state.ortherName !== ""
						? this.state.ortherName
						: localStorage.getItem("othernames"),
				practicecategory:
					this.state.categoryPractice !== ""
						? this.state.categoryPractice
						: localStorage.getItem("practiceCategory"),
				previoussurname:
					this.state.prevSurname !== ""
						? this.state.prevSurname
						: localStorage.getItem("previoussurname"),
				qualification:
					this.state.qualification !== ""
						? this.state.qualification
						: localStorage.getItem("qualification"),
				sponsorcfsnno1:
					this.state.sponsorNo !== ""
						? this.state.sponsorNo
						: localStorage.getItem("sponsorcfsnno1"),
				sponsorcfsnno2:
					this.state.sponsorNo2 !== ""
						? this.state.sponsorNo2
						: localStorage.getItem("sponsorcfsnno2"),
				sponsorname1:
					this.state.sponsorName !== ""
						? this.state.sponsorName
						: localStorage.getItem("sponsorname1"),
				sponsorname2:
					this.state.sponsorName2 !== ""
						? this.state.sponsorName2
						: localStorage.getItem("sponsorname2"),
				state:
					this.state.state !== ""
						? this.state.state
						: localStorage.getItem("state"),
				surname:
					this.state.surname !== ""
						? this.state.surname
						: localStorage.getItem("surname"),
				tertiaryinstitution:
					this.state.tertiaryinstitution !== ""
						? this.state.tertiaryinstitution
						: localStorage.getItem("tertiaryinstitution"),
				title:
					this.state.title !== ""
						? this.state.title
						: localStorage.getItem("title"),
				userid: localStorage.getItem("userid"),
				yearofqualification:
					this.state.qualificationYear !== ""
						? this.state.qualificationYear
						: localStorage.getItem("yearofqualification"),
			}),
		};
		fetch(`${baseUrl}/Registration/updateRegistration`, obj)
			.then((response) => response.json())
			.then((responseJson) => {
				console.warn(responseJson);
				if (
					responseJson.message ===
					"Registration Information Updated Successfully"
				) {
					this.setState({ loading: false, disabled: false });
					Swal.fire({
						title: "Success",
						text: "Membership Renewal Submitted",
						icon: "success",
						confirmButtonText: "OK",
					}).then(() => {
						// window.location = "/register-premises-payment"
						// $("#verifyPayments").modal("hide");
						window.location.reload().then(() => {
							this.props.history.push("/renew-food-payment");
						});
					});
				} else if (responseJson.status == 401) {
					this.props.history.push("/login");
				}
			})
			.catch((error) => {
				this.setState({ loading: false, disabled: false });
				Swal.fire({
					title: "Error!",
					text: error.message,
					icon: "error",
					confirmButtonText: "OK",
				});
			});
	};

	getStates() {
		return states.map((state) => {
			return <option value={state}>{state}</option>;
		});
	}

	getCountry() {
		return countries.map((country) => {
			return <option value={country.dial_code}>{country.name}</option>;
		});
	}

	getFoodScientistRegistration = async () => {
		const url = `${baseUrl}/Registration/getRegistrationByUserID/${localStorage.getItem("userid")}`;
		this.setState({ isLoading: true });
		await fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.warn(res);
				if (res.status === 401) {
					Swal.fire({
						title: "Session Expired",
						text: "Session expired. Please login",
						icon: "error",
						confirmButtonText: "OK",
					}).then(() => {
						this.props.history.push("/login");
					});
				} else {
					this.setState({ data: res, loading: false });
				}
				// console.warn(res);
			})
			.catch((error) => {
				this.setState({ error: true, loading: false });
				alert(error);
			});
	};

	getUserDetails = (formid) => {
		const url = `${baseUrl}/Registration/getRegistrationByFormID/${formid}`;
		this.setState({ isLoading: true });
		fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		})
			.then((res) => res.json())
			.then((res) => {
				this.setState({
					isLoading: false,
					foodScientistData: res,
				});
			})
			.catch((error) => {
				this.setState({ error: true, loading: false });
				alert(error);
			});
	};

	getUserRenewDetails = (formid) => {
		const url = `${baseUrl}/Registration/getRegistrationByFormID/${formid}`;
		this.setState({ isRenewalLoading: true });
		fetch(url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: "Bearer " + localStorage.getItem("token"),
			},
		})
			.then((res) => res.json())
			.then((res) => {
				console.warn(res);
				this.setState({
					isRenewalLoading: false,
					renewData: res,
				});
			})
			.catch((error) => {
				this.setState({ error: true, loading: false });
				alert(error);
			});
	};

	componentDidMount() {
		this.getFoodScientistRegistration();
		this.getFeeType();
		this.getPracticeCategory();
	}

	render() {
		const {
			loading,
			isLoading,
			foodScientistData,
			isRenewalLoading,
			renewData,
		} = this.state;
		return (
			<div className="container" style={{ alignSelf: "center" }}>
				{localStorage.getItem("token") && <Sidebar />}
				<main
					className="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
					style={{ width: "80%", float: "right" }}
				>
					<div
						className="container-fluid justify-content-center py-4"
						style={{ width: "100%", position: "relative", left: 0 }}
					>
						<div class="container-fluid px-4">
							<div class="rown">
								<div class="col-12">
									<div class="card my-3">
										<div
											class="card-header pb-0"
											style={{ backgroundColor: "#00264C" }}
										>
											<div class="d-flex flex-wrap align-items-center">
												<h5 className="mb-4 text-light">
													My Food Scientist Registration History
												</h5>
											</div>
										</div>
										<div class="card-body">
											{loading ? (
												<center>
													<Spinner
														animation="border"
														variant="success"
														size="lg"
														style={{ alignSelf: "center" }}
														className="text-center"
													/>
												</center>
											) : (
												<div class="table-responsive p-0 pb-2">
													<table
														id="table"
														className="table align-items-center justify-content-center mb-0"
													>
														<thead>
															<tr>
																<th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
																	S/N
																</th>
																<th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
																	Name
																</th>
																<th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
																	Email
																</th>
																<th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
																	Practice Category
																</th>
																<th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
																	Application date
																</th>
																<th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
																	Approval date
																</th>
																<th className="text-capitalize text-secondary text-sm font-weight-bolder opacity-7 ps-2">
																	Action
																</th>
															</tr>
														</thead>

														{this.showTable()}
													</table>
												</div>
											)}

											<div style={{ float: "right" }}>
												{this.showPagination()}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>

				{/* Start of View Modal */}
				<div
					class="modal fade"
					id="viewScientist"
					tabIndex="-1"
					aria-labelledby="viewRunning"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
						<div class="modal-content">
							<div
								class="modal-header d-flex align-items-center justify-content-between"
								style={{ backgroundColor: "#00264C" }}
							>
								<h5 class="modal-title text-light">View Record</h5>
								<button
									type="button"
									class="btn btn-link m-0 p-0 text-light fs-4"
									data-bs-dismiss="modal"
									aria-label="Print"
								>
									<i
										style={{ fontSize: 27, color: "#fff" }}
										class="material-icons opacity-10 text-light"
									>
										print
									</i>
								</button>
								<button
									type="button"
									class="btn btn-link m-0 p-0 text-light fs-4"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<span class="iconify" data-icon="carbon:close"></span>
								</button>
							</div>
							{isLoading ? (
								<center>
									<Spinner
										animation="border"
										style={{ padding: 3 }}
										variant="success"
										size="lg"
									/>
								</center>
							) : (
								<div class="modal-body">
									{foodScientistData.length > 0 &&
										foodScientistData.map((item) => {
											return (
												<main
													class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
													style={{
														width: !localStorage.getItem("token")
															? "90%"
															: "100%",
														position: localStorage.getItem("token")
															? "relative"
															: "",
														right: 0,
														padding: 18,
														float: !localStorage.getItem("token")
															? ""
															: "right",
														marginBottom: 90,
													}}
												>
													<div className="container-fluid px-4">
														<div className="rown">
															<div className="col-12">
																<div className="card my-3 mb-4">
																	<div className="card-header pb-0 bg-success">
																		<div className="text-center">
																			<h5 className="text-light text-center font-weight-bold mb-4">
																				Application Form for the Registration of
																				Food Business Premises
																			</h5>
																		</div>
																	</div>
																	{/* <div class="card-body"> */}
																	<div
																		className="container"
																		style={{ marginTop: 18, padding: 9 }}
																	>
																		<div style={{ marginTop: 0 }}></div>
																		<form className="row">
																			<label
																				className="mb-3 h4"
																				style={{ color: "#145973" }}
																				htmlFor="floatingInputCustom"
																			>
																				Personal Information
																			</label>
																			<br />
																			<br />
																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorSurname,
																					}}
																					className="form-label"
																				>
																					Surname{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control w-50 shadow-none"
																						type="text"
																						required="required"
																						value={item.surname}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label className="form-label text-dark">
																					Previous Surname
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control w-50 shadow-none"
																						type="text"
																						value={item.previoussurname}
																					/>
																				</div>
																			</div>

																			{/*
                                                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                                                  <label
                                                    style={{ color: this.state.colorFirstName }}
                                                    className="form-label"
                                                  >
                                                    First name <span className="text-danger">*</span>
                                                  </label>
                                                  <div className="input-group input-group-outline mb-3">
                                                    <label className="form-label"></label>
                                                    <input
                                                      className="form-control w-50 shadow-none"
                                                      type="text"
                                                      required="required"
                                                      defaultValue={item.surname}
                                                      onChange={(e) => this.setState({ firstname: e.target.value })}
                                                    />
                                                  </div>
                                                </div> */}

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorOthername,
																					}}
																					className="form-label"
																				>
																					Other name(s){" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.othernames}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorTitle,
																					}}
																					className="form-label"
																				>
																					Title{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.title}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorOthername,
																					}}
																					className="form-label"
																				>
																					DOB{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.DOB}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					className="form-label"
																					style={{
																						color: this.state.colorNationality,
																					}}
																				>
																					Nationality{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						type="text"
																						className="form-control shadow-none"
																						value={item.nationality}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					className="form-label"
																					style={{
																						color: this.state.colorNationality,
																					}}
																				>
																					Gender{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						type="text"
																						className="form-control shadow-none"
																						value={item.gender}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					className="form-label"
																					style={{
																						color: this.state.colorNationality,
																					}}
																				>
																					State{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						type="text"
																						className="form-control shadow-none"
																						value={item.state}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{ color: this.state.colorLga }}
																					className="form-label"
																				>
																					LGA{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						required="required"
																						value={item.lga}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorNIFST,
																					}}
																					className="form-label"
																				>
																					NIFST Registration Number{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.registrationnumber}
																					/>
																				</div>
																			</div>

																			<hr />
																			<label
																				className="mb-3 h4"
																				style={{ color: "#145973" }}
																				htmlFor="floatingInputCustom"
																			>
																				Qualification
																			</label>
																			<br />
																			<br />

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorNIFST,
																					}}
																					className="form-label"
																				>
																					Course of study
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.courseofstudy}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorNIFST,
																					}}
																					className="form-label"
																				>
																					Tertiary Institution
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.tertiaryinstitution}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					className="form-label"
																					style={{
																						color: this.state.colorNationality,
																					}}
																				>
																					Qualification{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						type="text"
																						className="form-control shadow-none"
																						value={item.qualification}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorQualYear,
																					}}
																					className="form-label"
																				>
																					Year of Qualification{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.yearofqualification}
																					/>
																				</div>
																			</div>

																			<hr />

																			<label
																				className="mb-3 h4"
																				style={{ color: "#145973" }}
																				htmlFor="floatingInputCustom"
																			>
																				Contact Information
																			</label>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorMailAddress,
																					}}
																					className="form-label"
																				>
																					Mailing Address{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>

																					<textarea
																						className="form-control shadow-none"
																						type="text"
																						required="required"
																						rows="1"
																						value={item.contactaddress}
																					></textarea>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					className="form-label"
																					style={{
																						color: this.state.colorEmail,
																					}}
																				>
																					Email{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="email"
																						value={item.contactemail}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					className="form-label"
																					style={{ color: "black" }}
																				>
																					Practice Category{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="email"
																						value={item.practicecategory}
																					/>
																				</div>
																			</div>

																			<hr />
																			<label
																				className="mb-3 h4"
																				style={{ color: "#145973" }}
																				htmlFor="floatingInputCustom"
																			>
																				Place of work
																			</label>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorOrgName,
																					}}
																					className="form-label"
																				>
																					Name of Organization/Institution{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						value={item.organization}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorPosition,
																					}}
																					className="form-label"
																				>
																					Position{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.organizationposition}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorAddress,
																					}}
																					className="form-label"
																				>
																					Address{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.organizationaddress}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorOrgPhone,
																					}}
																					className="form-label"
																				>
																					Organization Telephone{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.organizationtelephone}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorOrgEmail,
																					}}
																					className="form-label"
																				>
																					Organization Email{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.organizationemail}
																					/>
																				</div>
																			</div>

																			<hr />

																			<label
																				className="h4"
																				htmlFor="floatingInputCustom"
																				style={{ color: "#145973" }}
																			>
																				Sponsor Information
																			</label>
																			<br />
																			<br />

																			<label
																				className="h5"
																				htmlFor="floatingInputCustom"
																			>
																				Sponsor 1
																			</label>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorSponsor,
																					}}
																					className="form-label"
																				>
																					Sponsor Name{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.sponsorname1}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label
																					style={{
																						color: this.state.colorSponsorNo,
																					}}
																					className="form-label"
																				>
																					Current CFSN Number{" "}
																					<span className="text-danger">*</span>
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.sponsorcfsnno1}
																					/>
																				</div>
																			</div>

																			<label
																				className="h5"
																				htmlFor="floatingInputCustom"
																			>
																				Sponsor 2
																			</label>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label className="form-label">
																					Sponsor Name
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.sponsorname2}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label className="form-label">
																					Current CFSN Number
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						value={item.sponsorcfsnno2}
																					/>
																				</div>
																			</div>
																			<hr />

																			<label
																				className="h4"
																				htmlFor="floatingInputCustom"
																				style={{ color: "#145973" }}
																			>
																				Official
																			</label>
																			<br />
																			<br />

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label className="form-label">
																					Application Status
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className={
																							item.applicationstatus ===
																							"approved"
																								? "form-control shadow-none bg-success text-center text-uppercase font-weight-bold text-light"
																								: item.applicationstatus ===
																										"pending"
																									? "form-control text-center text-uppercase font-weight-bold text-light shadow-none bg-danger"
																									: item.applicationstatus ===
																											"rejected"
																										? "form-control text-center text-uppercase font-weight-bold text-light shadow-none bg-danger"
																										: "form-control shadow-none"
																						}
																						type="text"
																						value={
																							item.applicationstatus ===
																								"pending" ||
																							item.applicationstatus ===
																								"rejected"
																								? "Contact us"
																								: item.applicationstatus
																						}
																					/>
																				</div>
																			</div>

																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label className="form-label">
																					Application Date
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						disabled
																						value={`${item.applicationdate}`}
																					/>
																				</div>
																			</div>
																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label className="form-label">
																					Registration Number
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						disabled
																						value={`${item.nifstregistrationnumber}`}
																					/>
																				</div>
																			</div>
																			<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																				<label className="form-label">
																					Remarks
																				</label>
																				<div className="input-group input-group-outline mb-3">
																					<label className="form-label"></label>
																					<input
																						className="form-control shadow-none"
																						type="text"
																						disabled
																						value={`${item.officialremarks}`}
																					/>
																				</div>
																			</div>

																			<div style={{ height: 45 }} />

																			{/*Create Login details */}

																			{!localStorage.getItem("userid") && (
																				<label
																					className="h4"
																					htmlFor="floatingInputCustom"
																					style={{ color: "#145973" }}
																				>
																					Create Login Details <br />
																					<span
																						className="text-danger"
																						style={{ fontSize: 18 }}
																					>
																						Use this account to login to your
																						dashboard for tracking, renewal and
																						subsequent applications.
																					</span>
																					<br />
																					<br />
																				</label>
																			)}
																			{!localStorage.getItem("userid") && (
																				<br />
																			)}
																			{!localStorage.getItem("userid") && (
																				<br />
																			)}

																			{!localStorage.getItem("userid") && (
																				<div
																					className="col-sm-6 col-lg-4 col-md-6 mb-3"
																					style={{ display: "none" }}
																				>
																					<label
																						style={{
																							color:
																								this.state.colorCountryCode,
																						}}
																						className="form-label"
																					>
																						Country{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<select
																							className="form-control shadow-none"
																							aria-label="Floating label select example"
																							onChange={
																								this.handleCountryChange
																							}
																						>
																							<option
																								value="choose"
																								selected="selected"
																							>
																								-- Select country code --
																							</option>
																							{this.getCountry()}
																						</select>
																					</div>
																				</div>
																			)}

																			{!localStorage.getItem("userid") && (
																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorPhone,
																						}}
																						className="form-label"
																					>
																						Phone{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="phone"
																							required="required"
																							onChange={(e) =>
																								this.setState({
																									phone: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>
																			)}

																			{!localStorage.getItem("userid") && (
																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorPassword,
																						}}
																						className="form-label"
																					>
																						Pasword{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							required="required"
																							onChange={(e) =>
																								this.setState({
																									password: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>
																			)}

																			{!localStorage.getItem("userid") && (
																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color:
																								this.state.colorPasswordConf,
																						}}
																						className="form-label"
																					>
																						Confirm Pasword{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							required="required"
																							onChange={(e) =>
																								this.setState({
																									confirmPassword:
																										e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>
																			)}
																		</form>
																	</div>
																</div>
															</div>
														</div>
													</div>
													{/*   </div>*/}
												</main>
											);
										})}
								</div>
							)}
							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-danger"
									data-bs-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* End of View Modal */}

				{/* Start of Renewal Modal */}
				<div
					class="modal fade"
					id="verifyPayments"
					tabIndex="-1"
					aria-labelledby="viewRunning"
					aria-hidden="true"
				>
					<div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
						<div class="modal-content">
							<div
								class="modal-header d-flex align-items-center justify-content-between"
								style={{ backgroundColor: "#00264C" }}
							>
								<h5 class="modal-title text-light font-weight-bold">
									Renew Registration
								</h5>
								<button
									type="button"
									class="btn btn-link m-0 p-0 text-light fs-4"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<span class="iconify" data-icon="carbon:close"></span>
								</button>
							</div>
							{isRenewalLoading ? (
								<center>
									<Spinner
										animation="border"
										style={{ padding: 9 }}
										variant="success"
										size="lg"
									/>
								</center>
							) : (
								<div class="modal-body">
									<div class="container-fluid px-4">
										{renewData.length > 0 &&
											renewData.map((item) => {
												localStorage.setItem("DOB", `${item.DOB}`);
												localStorage.setItem("formid", `${item.formid}`);
												localStorage.setItem("surname", `${item.surname}`);
												localStorage.setItem(
													"previoussurname",
													`${item.previoussurname}`,
												);
												localStorage.setItem("firstName", `${item.surname}`);
												localStorage.setItem(
													"othernames",
													`${item.othernames}`,
												);
												localStorage.setItem("title", `${item.title}`);
												localStorage.setItem(
													"nationality",
													`${item.nationality}`,
												);
												localStorage.setItem("gender", `${item.gender}`);
												localStorage.setItem("state", `${item.state}`);
												localStorage.setItem("lga", `${item.lga}`);
												localStorage.setItem(
													"nifstregistrationnumber",
													`${item.nifstregistrationnumber}`,
												);
												localStorage.setItem(
													"courseofstudy",
													`${item.courseofstudy}`,
												);
												localStorage.setItem(
													"tertiaryinstitution",
													`${item.tertiaryinstitution}`,
												);
												localStorage.setItem(
													"qualification",
													`${item.qualification}`,
												);
												localStorage.setItem(
													"yearofqualification",
													`${item.yearofqualification}`,
												);
												localStorage.setItem(
													"contactaddress",
													`${item.contactaddress}`,
												);
												localStorage.setItem(
													"contactemail",
													`${item.contactemail}`,
												);
												localStorage.setItem(
													"practicecategory",
													`${item.practicecategory}`,
												);
												localStorage.setItem(
													"organization",
													`${item.organization}`,
												);
												localStorage.setItem(
													"organizationposition",
													`${item.organizationposition}`,
												);
												localStorage.setItem(
													"organizationaddress",
													`${item.organizationaddress}`,
												);
												localStorage.setItem(
													"organizationtelephone",
													`${item.organizationtelephone}`,
												);
												localStorage.setItem(
													"organizationemail",
													`${item.organizationemail}`,
												);
												localStorage.setItem(
													"sponsorname1",
													`${item.sponsorname1}`,
												);
												localStorage.setItem(
													"sponsorcfsnno1",
													`${item.sponsorcfsnno1}`,
												);
												localStorage.setItem(
													"sponsorcfsnno2",
													`${item.sponsorcfsnno2}`,
												);
												localStorage.setItem(
													"sponsorname2",
													`${item.sponsorname2}`,
												);
												return (
													<main
														class="main-content position-relative max-height-vh-100 h-100 border-radius-lg "
														style={{
															width: !localStorage.getItem("token")
																? "90%"
																: "100%",
															position: localStorage.getItem("token")
																? "relative"
																: "",
															right: 0,
															padding: 18,
															float: !localStorage.getItem("token")
																? ""
																: "right",
															marginBottom: 90,
														}}
													>
														<div className="container-fluid px-4">
															<div className="rown">
																<div className="col-12">
																	<div className="card my-3 mb-4">
																		<div className="card-header pb-0 bg-success">
																			<div className="text-center">
																				<h5 className="text-light text-center font-weight-bold mb-4">
																					Renewal Form for the Registration of
																					Food Business Premises
																				</h5>
																			</div>
																		</div>
																		{/* <div class="card-body"> */}

																		<div
																			className="container"
																			style={{ marginTop: 18, padding: 9 }}
																		>
																			<div style={{ marginTop: 0 }}></div>
																			<form className="row">
																				<label
																					className="mb-3 h4"
																					style={{ color: "#145973" }}
																					htmlFor="floatingInputCustom"
																				>
																					Personal Information
																				</label>
																				<br />
																				<br />
																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorSurname,
																						}}
																						className="form-label"
																					>
																						Surname{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control w-50 shadow-none"
																							type="text"
																							required="required"
																							defaultValue={item.surname}
																							onChange={(e) =>
																								this.setState({
																									surname: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label className="form-label text-dark">
																						Previous Surname
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control w-50 shadow-none"
																							type="text"
																							defaultValue={
																								item.previoussurname
																							}
																							onChange={(e) =>
																								this.setState({
																									prevSurname: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorOthername,
																						}}
																						className="form-label"
																					>
																						Other name(s){" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={item.othernames}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorOthername,
																						}}
																						className="form-label"
																					>
																						Other name(s){" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<select
																							className="form-control"
																							aria-label="Select title"
																							onChange={this.handleChange}
																							style={{
																								color: this.state.colorTitle,
																							}}
																						>
																							<option
																								value={
																									item.title
																										? item.title
																										: "-- Select title --"
																								}
																								selected
																							>
																								{item.title
																									? item.title
																									: "-- Select title --"}
																							</option>
																							<option value="mr">Mr.</option>
																							<option value="mrs">Mrs.</option>
																							<option value="ms">Ms.</option>
																							<option value="dr">Dr.</option>
																							<option value="prof">
																								Prof.
																							</option>
																						</select>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorDob,
																						}}
																						className="form-label"
																					>
																						DOB{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="date"
																							defaultValue={item.DOB}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						className="form-label"
																						style={{
																							color:
																								this.state.colorNationality,
																						}}
																					>
																						Nationality{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							type="text"
																							className="form-control shadow-none"
																							defaultValue={item.nationality}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						className="form-label"
																						style={{
																							color: this.state.colorGender,
																						}}
																					>
																						Select Gender{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<select
																							className="form-control shadow-none"
																							aria-label="Select"
																							onChange={this.handleGenderChange}
																							style={{
																								color: this.state.colorGender,
																							}}
																						>
																							<option
																								value={
																									item.gender
																										? item.gender
																										: "-- Select gender--"
																								}
																								selected
																							>
																								{item.gender
																									? item.gender
																									: "-- Select gender --"}
																							</option>
																							<option value="male">Male</option>
																							<option value="female">
																								Female
																							</option>
																						</select>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorState,
																						}}
																						className="form-label"
																					>
																						Select State{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<select
																							className="form-control shadow-none"
																							aria-label="Select state"
																							onChange={this.handleStateChange}
																							style={{
																								color: this.state.colorState,
																							}}
																						>
																							<option
																								value={
																									item.state
																										? item.state
																										: "-- Select state --"
																								}
																								selected
																							>
																								{item.state
																									? item.state
																									: "-- Select state --"}
																							</option>
																							{this.getStates()}
																						</select>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorLga,
																						}}
																						className="form-label"
																					>
																						LGA{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							required="required"
																							defaultValue={item.lga}
																							onChange={(e) =>
																								this.setState({
																									lga: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorNIFST,
																						}}
																						className="form-label"
																					>
																						NIFST Registration Number{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={
																								item.nifstregistrationnumber
																							}
																							onChange={(e) =>
																								this.setState({
																									nifst: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<hr />
																				<label
																					className="mb-3 h4"
																					style={{ color: "#145973" }}
																					htmlFor="floatingInputCustom"
																				>
																					Qualification
																				</label>
																				<br />
																				<br />

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorStudy,
																						}}
																						className="form-label"
																					>
																						Course of study
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={item.courseofstudy}
																							onChange={(e) =>
																								this.setState({
																									courseStudy: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorTetiary,
																						}}
																						className="form-label"
																					>
																						Tertiary Institution
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={
																								item.tertiaryinstitution
																							}
																							onChange={(e) =>
																								this.setState({
																									tertiaryinstitution:
																										e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorQual,
																						}}
																						className="form-label"
																					>
																						Qualification{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<select
																							className="form-control shadow-none"
																							aria-label="Select"
																							onChange={
																								this.handleQualificationChange
																							}
																							style={{
																								color: this.state.colorQual,
																							}}
																						>
																							<option
																								value={
																									item.qualification
																										? item.qualification
																										: "-- Select qualification --"
																								}
																								selected
																							>
																								{item.qualification
																									? item.qualification
																									: "-- Select qualification --"}
																							</option>
																							<option value="SSCE">SSCE</option>
																							<option value="OND">OND</option>
																							<option value="HND">HND</option>
																							<option value="PgD">PgD</option>
																							<option value="BSC">BSC</option>
																							<option value="MSC">MSC</option>
																							<option value="M.Tech">
																								M.Tech
																							</option>
																							<option value="PHD">PHD</option>
																						</select>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorQualYear,
																						}}
																						className="form-label"
																					>
																						Year of Qualification{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={
																								item.yearofqualification
																							}
																							onChange={(e) =>
																								this.setState({
																									qualificationYear:
																										e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<hr />

																				<label
																					className="mb-3 h4"
																					style={{ color: "#145973" }}
																					htmlFor="floatingInputCustom"
																				>
																					Contact Information
																				</label>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color:
																								this.state.colorMailAddress,
																						}}
																						className="form-label"
																					>
																						Mailing Address{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>

																						<textarea
																							className="form-control shadow-none"
																							type="text"
																							required="required"
																							rows="1"
																							defaultValue={item.contactaddress}
																							onChange={(e) =>
																								this.setState({
																									mailingAddres: e.target.value,
																								})
																							}
																						></textarea>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						className="form-label"
																						style={{
																							color: this.state.colorEmail,
																						}}
																					>
																						Email{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="email"
																							defaultValue={item.contactemail}
																							onChange={(e) =>
																								this.setState({
																									email: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						className="form-label"
																						style={{
																							color:
																								this.state.practicecategory,
																						}}
																					>
																						Practice Category{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<select
																							className="form-control shadow-none"
																							aria-label="Select"
																							onChange={
																								this.handlePracticeChange
																							}
																							style={{
																								color: this.state.colorPractice,
																							}}
																						>
																							<option
																								value={
																									item.practiceCategory
																										? item.practiceCategory
																										: "-- Select practice category --"
																								}
																								selected
																							>
																								{item.practiceCategory
																									? item.practiceCategory
																									: "-- Select practice category --"}
																							</option>
																							{this.showPracticeCategory()}
																						</select>
																					</div>
																				</div>

																				<hr />
																				<label
																					className="mb-3 h4"
																					style={{ color: "#145973" }}
																					htmlFor="floatingInputCustom"
																				>
																					Place of work
																				</label>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorOrgName,
																						}}
																						className="form-label"
																					>
																						Name of Organization/Institution{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							defaultValue={item.organization}
																							onChange={(e) =>
																								this.setState({
																									orgName: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorPosition,
																						}}
																						className="form-label"
																					>
																						Position{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={
																								item.organizationposition
																							}
																							onChange={(e) =>
																								this.setState({
																									orgPosition: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorOrgAddress,
																						}}
																						className="form-label"
																					>
																						Address{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={
																								item.organizationaddress
																							}
																							onChange={(e) =>
																								this.setState({
																									orgAddress: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorOrgPhone,
																						}}
																						className="form-label"
																					>
																						Organization Telephone{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={
																								item.organizationtelephone
																							}
																							onChange={(e) =>
																								this.setState({
																									orgPhone: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorOrgEmail,
																						}}
																						className="form-label"
																					>
																						Organization Email{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={
																								item.organizationemail
																							}
																							onChange={(e) =>
																								this.setState({
																									orgEmail: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<hr />

																				<label
																					className="h4"
																					htmlFor="floatingInputCustom"
																					style={{ color: "#145973" }}
																				>
																					Sponsor Information
																				</label>
																				<br />
																				<br />

																				<label
																					className="h5"
																					htmlFor="floatingInputCustom"
																				>
																					Sponsor 1
																				</label>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorSponsor,
																						}}
																						className="form-label"
																					>
																						Sponsor Name{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={item.sponsorname1}
																							onChange={(e) =>
																								this.setState({
																									sponsorName: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label
																						style={{
																							color: this.state.colorSponsorNo,
																						}}
																						className="form-label"
																					>
																						Current CFSN Number{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={item.sponsorcfsnno1}
																							onChange={(e) =>
																								this.setState({
																									sponsorNo: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<label
																					className="h5"
																					htmlFor="floatingInputCustom"
																				>
																					Sponsor 2
																				</label>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label className="form-label">
																						Sponsor Name
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={item.sponsorname2}
																							onChange={(e) =>
																								this.setState({
																									sponsorName2: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>

																				<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																					<label className="form-label">
																						Current CFSN Number
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<input
																							className="form-control shadow-none"
																							type="text"
																							defaultValue={item.sponsorcfsnno2}
																							onChange={(e) =>
																								this.setState({
																									sponsorNo2: e.target.value,
																								})
																							}
																						/>
																					</div>
																				</div>
																				<hr />

																				<label
																					className="h5"
																					htmlFor="floatingInputCustom"
																				>
																					Payments
																				</label>

																				<div className="col-sm-6 col-lg-6 col-md-6 mb-3">
																					<label
																						style={{ color: "black" }}
																						className="form-label"
																					>
																						Select Payment{" "}
																						<span className="text-danger">
																							*
																						</span>
																					</label>
																					<div className="input-group input-group-outline mb-3">
																						<label className="form-label"></label>
																						<select
																							className="form-control shadow-none"
																							aria-label="Select"
																							onChange={this.handleFeeChange}
																							style={{
																								color: this.state.colorCompany,
																							}}
																						>
																							<option selected="selected">
																								-- Select payment type --
																							</option>
																							{this.showFeeTypes()}
																						</select>
																					</div>
																				</div>

																				<hr />

																				<div style={{ height: 45 }} />

																				{/*Create Login details */}

																				{!localStorage.getItem("userid") && (
																					<label
																						className="h4"
																						htmlFor="floatingInputCustom"
																						style={{ color: "#145973" }}
																					>
																						Create Login Details <br />
																						<span
																							className="text-danger"
																							style={{ fontSize: 18 }}
																						>
																							Use this account to login to your
																							dashboard for tracking, renewal
																							and subsequent applications.
																						</span>
																						<br />
																						<br />
																					</label>
																				)}
																				{!localStorage.getItem("userid") && (
																					<br />
																				)}
																				{!localStorage.getItem("userid") && (
																					<br />
																				)}

																				{!localStorage.getItem("userid") && (
																					<div
																						className="col-sm-6 col-lg-4 col-md-6 mb-3"
																						style={{ display: "none" }}
																					>
																						<label
																							style={{
																								color:
																									this.state.colorCountryCode,
																							}}
																							className="form-label"
																						>
																							Country{" "}
																							<span className="text-danger">
																								*
																							</span>
																						</label>
																						<div className="input-group input-group-outline mb-3">
																							<label className="form-label"></label>
																							<select
																								className="form-control shadow-none"
																								aria-label="Floating label select example"
																								onChange={
																									this.handleCountryChange
																								}
																							>
																								<option
																									value="choose"
																									selected="selected"
																								>
																									-- Select country code --
																								</option>
																								{this.getCountry()}
																							</select>
																						</div>
																					</div>
																				)}

																				{!localStorage.getItem("userid") && (
																					<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																						<label
																							style={{
																								color: this.state.colorPhone,
																							}}
																							className="form-label"
																						>
																							Phone{" "}
																							<span className="text-danger">
																								*
																							</span>
																						</label>
																						<div className="input-group input-group-outline mb-3">
																							<label className="form-label"></label>
																							<input
																								className="form-control shadow-none"
																								type="phone"
																								required="required"
																								onChange={(e) =>
																									this.setState({
																										phone: e.target.value,
																									})
																								}
																							/>
																						</div>
																					</div>
																				)}

																				{!localStorage.getItem("userid") && (
																					<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																						<label
																							style={{
																								color: this.state.colorPassword,
																							}}
																							className="form-label"
																						>
																							Pasword{" "}
																							<span className="text-danger">
																								*
																							</span>
																						</label>
																						<div className="input-group input-group-outline mb-3">
																							<label className="form-label"></label>
																							<input
																								className="form-control shadow-none"
																								type="text"
																								required="required"
																								onChange={(e) =>
																									this.setState({
																										password: e.target.value,
																									})
																								}
																							/>
																						</div>
																					</div>
																				)}

																				{!localStorage.getItem("userid") && (
																					<div className="col-sm-6 col-lg-4 col-md-6 mb-3">
																						<label
																							style={{
																								color:
																									this.state.colorPasswordConf,
																							}}
																							className="form-label"
																						>
																							Confirm Pasword{" "}
																							<span className="text-danger">
																								*
																							</span>
																						</label>
																						<div className="input-group input-group-outline mb-3">
																							<label className="form-label"></label>
																							<input
																								className="form-control shadow-none"
																								type="text"
																								required="required"
																								onChange={(e) =>
																									this.setState({
																										confirmPassword:
																											e.target.value,
																									})
																								}
																							/>
																						</div>
																					</div>
																				)}

																				<div
																					className="row"
																					style={{ marginTop: 25 }}
																				>
																					<Form.Floating className="mb-3">
																						<input
																							class="form-check-input shadow-none"
																							type="checkbox"
																							id="checkBoxUS"
																							name="US"
																							value="US"
																							onChange={this.handleCheckChange}
																						/>{" "}
																						I hereby certify that I am not a
																						registered and practicing member of
																						an allied profession and the
																						particulars furnished herein are
																						true
																					</Form.Floating>
																					<h6>
																						TAKE NOTICE: THE NICFOST SHALL MAKE
																						A CLAIM AND RECOVER ALL COSTS OF
																						LITIGATION INCURRED BY IN IN DEFENCE
																						OF ANY COURT ACTION INSTITUTED
																						AGAINST IT AT THE INSTANCE OF ANY
																						CERTIFIED FOOD SCIENTIST AND/OR
																						LICENSED FOOD SCIENTIST PREMISES AND
																						WHEREBY THE SUIT IS STRUCK OUT,
																						WITHDRAWN OR THE FOOD SCIENTIST OR
																						THE FOOD SCIENTIST PREMISES LOSES
																						THE CASE.
																					</h6>
																				</div>

																				<div
																					className="text-center"
																					style={{
																						margin: "auto",
																						width: "100%",
																						marginTop: 45,
																						marginBottom: 0,
																					}}
																				>
																					<button
																						disabled={this.state.disabled}
																						style={{
																							alignSelf: "center",
																							width: "60%",
																							backgroundColor: "#147332",
																						}}
																						className="btn btn-success btn-lg"
																						onClick={(e) =>
																							this.checkValidation(e)
																						}
																					>
																						{loading ? (
																							<Spinner
																								animation="border"
																								variant="light"
																								size="sm"
																							/>
																						) : (
																							<span className="font-weight-bold">
																								{/* APPLY <i class="fas fa-chevron-right"></i> */}
																								SUBMIT APPLICATION
																							</span>
																						)}
																					</button>
																				</div>
																			</form>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</main>
												);
											})}
									</div>
								</div>
							)}

							<div class="modal-footer">
								<button
									type="button"
									class="btn btn-danger"
									data-bs-dismiss="modal"
								>
									Close
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* End of Renewal Modal */}
			</div>
		);
	}
}

export default RenewFoodScientist;
