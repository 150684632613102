import React, { Component } from 'react';
import NewDashboardLayout from '../components/NewDashboardlayout';
import { Spinner, Form } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import { states } from "../assets/data/json-data";
import Swal from 'sweetalert2';
import { makePayment } from '../Helper/paymentHelper';

const baseUrl = process.env.REACT_APP_BASE_URL;

class NewRegisterLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surname: "",
      prevSurname: "",
      otherNames: "",
      title: "",
      dob: "",
      nationality: "",
      gender: "",
      selectedState: "",
      lga: "",
      selectedFile: null,
      qualifications: [],
      contactPhone: "",
      contactEmail: "",
      contactAddress: "",
      organizationName: "",
      organizationPosition: "",
      organizationEmail: "",
      organizationTelephone: "",
      organizationAddress: "",
      practiceCategory: "",
      employed: false,
      loading: false,
      disabled: false,
      // Using error colors for inputs – consider moving these to a separate error object or CSS classes
      colorSurname: "black",
      colorOthername: "black",
      colorTitle: "black",
      colorDob: "black",
      colorNationality: "black",
      colorGender: "black",
      colorState: "black",
      colorLga: "black",
      colorPhone: "black",
      colorEmail: "black",
      colorAddress: "black",
      colorPraCat: "black",
      colorOrgName: "black",
      colorOrgEmail: "black",
      colorOrgPhone: "black",
      colorOrgAddress: "black",
      colorOrgPosition: "black",
      // Additional qualification state (initialized)
      additionQualification: {
        qualification: "",
        year: "",
        certificateImage: null,
      },
      // For the certification checkbox at the end of the form
      certified: false,
    };
  }

  // Handler to update gender
  handleGenderChange = (e) => {
    this.setState({ gender: e.target.value, colorGender: "black" });
  };

  // Handler for the certification checkbox at the end of the form
  handleCheckChange = (e) => {
    this.setState({ certified: e.target.checked });
  };

  // Handler for employed checkbox
  handleEmployedCheck = (e) => {
    this.setState({ employed: e.target.checked });
  };

  // Handle state change (renamed from handleStateChange)
  handleStateChange = (e) => {
    this.setState({ selectedState: e.target.value, colorState: "black", lga: "" });
  };

  // Handle date change
  handleDateChange = (e) => {
    const dob = e.target.value;
    this.setState({ dob, colorDob: "black" });
  };


  // Update the file change handler with size check
  handleFileChange = (index, event) => {

    // File change for qualification uploads – works for qualification list items
    const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes
    if (!event || !event.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        Swal.fire({
          title: "File Too Large",
          text: "Please upload a file smaller than 1MB",
          icon: "error",
          confirmButtonText: "OK",
        });
        // Reset the file input
        event.target.value = '';
        return;
      }
      this.convertToBase64(file, index);
    } else {
      console.error("No file selected");
    }
  };

  // Separate handler for additional qualification file upload
  handleAdditionalQualificationFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.convertAdditionalQualificationToBase64(file);
    } else {
      console.error("No file selected for additional qualification");
    }
  };

  convertToBase64 = (file, index) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // Ensure we're storing the base64 string
      const base64String = reader.result.toString();
      this.updateQualification(index, 'certificateImage', base64String);
    };
    reader.onerror = (error) => {
      console.error("Error converting file to base64:", error);
    };
  };

  convertAdditionalQualificationToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState(prevState => ({
        additionQualification: {
          ...prevState.additionQualification,
          certificateImage: reader.result,
        }
      }));
    };
    reader.onerror = (error) => {
      console.error("Error converting file to base64:", error);
    };
  };
  
// Add this inside the class NewRegisterLicense
addQualification = () => {
  // Check if we've reached the maximum number of qualifications
  if (this.state.qualifications.length >= 10) {
    Swal.fire({
      title: "Maximum Limit Reached",
      text: "You can only add up to 10 qualifications",
      icon: "warning",
      confirmButtonText: "OK",
    });
    return;
  }

  const currentYear = new Date().getFullYear();
  this.setState(prevState => ({
    qualifications: [
      ...prevState.qualifications,
      { qualification: "", year: currentYear, certificateImage: null }
    ]
  }));
};

  updateQualification = (index, field, value) => {
    const newQualifications = [...this.state.qualifications];
    newQualifications[index] = {
      ...newQualifications[index],
      [field]: value
    };
    this.setState({ qualifications: newQualifications });
  };

  getStates = () => {
    return states.map((state, i) => (
      <option key={i} value={state.name}>
        {state.name}
      </option>
    ));
  };

  getLgas = () => {
    const { selectedState } = this.state;
    if (selectedState) {
      const chosenState = states.find((state) => state.name === selectedState);
      return chosenState?.locals?.map((local, i) => (
        <option key={i} value={local.name}>
          {local.name}
        </option>
      ));
    }
    return null;
  };

  componentDidMount() {
    // You can remove the console log if not needed
    console.log("Params:", this.props.match?.params);
    this.getlicenseInfo();
  }

  getlicenseInfo = () => {
    const userid = localStorage.getItem("userid");
    axios
      .get(`${baseUrl}/v2/license/license-information/${userid}`)
      .then((response) => {
        const { personalInformation, contactInformation, qualifications, workInformation, practiceCategory } = response.data;
        this.setState({
          ...personalInformation,
          ...contactInformation,
          qualifications: [...qualifications],
          ...workInformation,
          ...practiceCategory,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, disabled: false });
        const response = err.response;
        if (response?.status === 400) {
          Swal.fire({
            title: "Error!",
            text: response.data.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/dashboard");
          });
        } else {
          Swal.fire({
            title: "Error!",
            text: err.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      });
  };

  // Add these validation helper functions to the class
validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

validatePhone = (phone) => {
  // Validates that phone is 11 digits
  const phoneRegex = /^\d{10,11}$/;
  return phoneRegex.test(phone);
};

validateQualifications = (qualifications) => {
  if (qualifications.length === 0) {
    return { isValid: true };
  }

  for (let i = 0; i < qualifications.length; i++) {
    const qual = qualifications[i];
    if (!qual.qualification) {
      return { 
        isValid: false, 
        message: `Please select a qualification type for qualification #${i + 1}` 
      };
    }
    if (!qual.certificateImage) {
      return { 
        isValid: false, 
        message: `Please upload a certificate for qualification #${i + 1}` 
      };
    }
  }

  return { isValid: true };
};

// checkValidation method with this enhanced version
checkValidation = async (e) => {
  e.preventDefault();
  const {
    surname,
    otherNames,
    title,
    dob,
    nationality,
    gender,
    selectedState,
    lga,
    contactPhone,
    contactEmail,
    contactAddress,
    qualifications,
    organizationName,
    organizationPosition,
    organizationEmail,
    organizationTelephone,
    organizationAddress,
    practiceCategory,
    employed,
    certified,
  } = this.state;

  // Helper to show error message and mark field in red
  const showError = (message, fieldColorKey) => {
    Swal.fire({
      title: "Validation Error",
      text: message,
      icon: "error",
      confirmButtonText: "OK",
    });
    this.setState({ loading: false, disabled: false, [fieldColorKey]: "red" });
    return false;
  };

  // Basic required field validations
  if (!surname || surname.trim().length < 2) {
    return showError("Please enter a valid surname (minimum 2 characters)", "colorSurname");
  }
  if (!otherNames || otherNames.trim().length < 2) {
    return showError("Please enter valid other names (minimum 2 characters)", "colorOthername");
  }
  if (!title) {
    return showError("Please specify your title", "colorTitle");
  }
  
  // Date of birth validation
  if (!dob) {
    return showError("Please specify your Date of birth", "colorDob");
  }
  const dobDate = new Date(dob);
  const today = new Date();
  const age = today.getFullYear() - dobDate.getFullYear();
  if (age < 18 || age > 100) {
    return showError("Please enter a valid date of birth (age must be between 18 and 100)", "colorDob");
  }

  if (!nationality || nationality.trim().length < 2) {
    return showError("Please specify a valid nationality", "colorNationality");
  }
  if (!gender) {
    return showError("Please specify your gender", "colorGender");
  }
  if (!selectedState) {
    return showError("Please select your state", "colorState");
  }
  if (!lga) {
    return showError("Please select your LGA", "colorLga");
  }

  // Contact information validations
  if (!contactPhone) {
    return showError("Please specify your Phone Number", "colorPhone");
  }
  if (!this.validatePhone(contactPhone)) {
    return showError("Please enter a valid 11-digit phone number", "colorPhone");
  }
  if (!contactEmail) {
    return showError("Please specify your email", "colorEmail");
  }
  if (!this.validateEmail(contactEmail)) {
    return showError("Please enter a valid email address", "colorEmail");
  }
  if (!contactAddress || contactAddress.trim().length < 10) {
    return showError("Please enter a complete contact address (minimum 10 characters)", "colorAddress");
  }

  // Qualifications validation
  const qualValidation = this.validateQualifications(qualifications);
  if (!qualValidation.isValid) {
    return showError(qualValidation.message, "colorQual");
  }

  // Employment information validation (if employed)
  if (employed) {
    if (!organizationName || organizationName.trim().length < 2) {
      return showError("Please specify your Organization Name", "colorOrgName");
    }
    if (!organizationPosition || organizationPosition.trim().length < 2) {
      return showError("Please specify your position in your Organization", "colorOrgPosition");
    }
    if (!organizationAddress || organizationAddress.trim().length < 10) {
      return showError("Please specify a complete Organization address", "colorOrgAddress");
    }
    if (!organizationTelephone) {
      return showError("Please specify Organization phone number", "colorOrgPhone");
    }
    if (!this.validatePhone(organizationTelephone)) {
      return showError("Please enter a valid 11-digit Organization phone number", "colorOrgPhone");
    }
    if (!organizationEmail) {
      return showError("Please specify Organization email", "colorOrgEmail");
    }
    if (!this.validateEmail(organizationEmail)) {
      return showError("Please enter a valid Organization email address", "colorOrgEmail");
    }
  }

  if (!practiceCategory) {
    return showError("Please specify your practice category", "colorPraCat");
  }

  // Certification check
  if (!certified) {
    return showError("Please certify that the information provided is true", "colorCertification");
  }

  // If all validations pass
  this.setState({ loading: true, disabled: true });
  this.renewLicense();
};

  registerLicense = (employed) => {
    this.setState({ loading: true, disabled: true });

    if (!employed) {
      this.setState({
        organizationName: "",
        organizationPosition: "",
        organizationEmail: "",
        organizationTelephone: "",
        organizationAddress: "",
      });
    }

    const date = new Date();
    const payload = {
      dob: this.state.dob,
      applicationdate: date.getTime(),
      contactAddress: this.state.contactAddress,
      contactEmail: this.state.contactEmail,
      contactTelephone: this.state.contactPhone,
      formType: "registration",
      gender: this.state.gender,
      lga: this.state.lga,
      nationality: this.state.nationality,
      organization: this.state.organizationName,
      organizationAddress: this.state.organizationAddress,
      organizationEmail: this.state.organizationEmail,
      organizationPosition: this.state.organizationPosition,
      organizationTelephone: this.state.organizationTelephone,
      otherNames: this.state.otherNames,
      practiceCategory: this.state.practiceCategory,
      previoussurname: this.state.prevSurname,
      registrationnumber: this.state.nifst,
      state: this.state.selectedState,
      surname: this.state.surname,
      title: this.state.title,
      userId: localStorage.getItem("userid"),
    };

    fetch(`${baseUrl}/v2/license/create-license`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response?.status === 200) {
          const responseJson = await response.json();
          localStorage.setItem("rrr", responseJson.rrr);
          localStorage.setItem("licenseId", responseJson.licenseId);
          makePayment();
          this.setState({ loading: false, disabled: false });
        } else if (response?.status === 400) {
          const responseJson = await response.json();
          Swal.fire({
            title: "Error",
            text: responseJson?.violations?.[0]?.message || responseJson?.message,
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            this.props.history.push("/dashboard");
          });
        } else if (response?.status === 500) {
          Swal.fire({
            title: "Error",
            text: "Unable to apply for a license at the moment. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
        this.setState({ loading: false, disabled: false });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  renewLicense = () => {
    const { licenseId } = this.props.match.params;
    if (!licenseId) {
      Swal.fire({
        title: "Error",
        text: "License ID is missing. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
  
    this.setState({ loading: true, disabled: true });
    const date = new Date();
    
    // Format qualifications to ensure proper data types
    const formattedQualifications = this.state.qualifications.map(qual => ({
      qualification: qual.qualification,
      year: qual.year.toString(), // Ensure year is a string
      certificateImage: qual.certificateImage || "" // Ensure certificateImage is a string
    }));

    const payload = {
      dob: this.state.dob,
      applicationdate: date.getTime(),
      contactAddress: this.state.contactAddress,
      contactEmail: this.state.contactEmail,
      contactTelephone: this.state.contactPhone,
      formType: "renewal",
      gender: this.state.gender,
      lga: this.state.lga,
      nationality: this.state.nationality,
      organization: this.state.organizationName,
      organizationAddress: this.state.organizationAddress,
      organizationEmail: this.state.organizationEmail,
      organizationPosition: this.state.organizationPosition,
      organizationTelephone: this.state.organizationTelephone,
      otherNames: this.state.otherNames,
      practiceCategory: this.state.practiceCategory,
      previoussurname: this.state.prevSurname,
      registrationnumber: this.state.nifst,
      qualifications: formattedQualifications, 
      state: this.state.selectedState,
      surname: this.state.surname,
      title: this.state.title,
      userId: localStorage.getItem("userid"),
    };

    // Log the payload for debugging
    console.log('Renewal payload:', payload);

    fetch(`${baseUrl}/v2/license/renew-license/${licenseId}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: JSON.stringify(payload),
    })
      .then(async (response) => {
        if (response?.status === 200) {
          try {
            const responseJson = await response.json();
            localStorage.setItem("rrr", responseJson.rrr);
            localStorage.setItem("licenseId", responseJson.licenseId);
            makePayment();
            this.setState({ loading: false, disabled: false });
            this.props.history.push("/dashboard");
          } catch (error) {
            console.error("Error parsing JSON response:", error);
            Swal.fire({
              title: "Error",
              text: "Unable to parse server response. Please try again later.",
              icon: "error",
              confirmButtonText: "OK",
            });
            this.setState({ loading: false, disabled: false });
          }
        } else if (response?.status === 400) {
          const responseJson = await response.json();
          Swal.fire({
            title: "Error",
            text: responseJson?.violations?.[0]?.message || responseJson?.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else if (response?.status === 500) {
          Swal.fire({
            title: "Error",
            text: "Unable to apply for a license at the moment. Please try again later.",
            icon: "error",
            confirmButtonText: "OK",
          });
        }
        this.setState({ loading: false, disabled: false });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
          confirmButtonText: "OK",
        });
        this.setState({ loading: false, disabled: false });
      });
  };

  removeQualification = (indexToRemove) => {
    this.setState(prevState => ({
      qualifications: prevState.qualifications.filter((_, index) => index !== indexToRemove)
    }));
  };

  render() {
    const { loading, disabled, employed, qualifications } = this.state;
    return (
      <NewDashboardLayout PageName="License Renewal">
        <div className="container-fluid px-4" id="register-form-container">
          <div className="row">
            <div className="col-12">
              <div className="card my-3">
                <div className="card-header pb-0" style={{ backgroundColor: "rgb(6, 38, 1)" }}>
                  <div className="d-flex flex-wrap justify-content-center mb-2">
                    <h5 className="text-light text-center font-weight-bold">
                      APPLICATION FORM FOR LICENCE OR RENEWAL TO PRACTICE AS A CERTIFIED FOOD SCIENTIST
                    </h5>
                  </div>
                </div>
                <div className="card-body reg-body">
                  <h3
                    className="nicfostText"
                    style={{
                      position: "relative",
                      fontWeight: "bold",
                      top: 0,
                      fontSize: 25,
                      marginLeft: 85,
                      textAlign: "center",
                    }}
                  >
                    NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NicFosT)
                  </h3>
                  <div className="container" style={{ marginTop: 0, padding: 18 }}>
                    <form className="row">
                      {/* Personal Information */}
                      <label className="mb-3 h4" style={{ color: "green" }} htmlFor="personal-info">
                        Personal Information
                      </label>
                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorSurname }} className="form-label">
                          Surname <span className="text-danger">*</span>
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            value={this.state.surname}
                            onChange={(e) =>
                              this.setState({ surname: e.target.value, colorSurname: "black" })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label className="form-label text-dark">
                          Previous Surname (If applicable)
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            value={this.state.prevSurname}
                            onChange={(e) =>
                              this.setState({ prevSurname: e.target.value })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorOthername }} className="form-label">
                          Other name(s) <span className="text-danger">*</span>
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <input
                            className="form-control shadow-none"
                            type="text"
                            value={this.state.otherNames}
                            onChange={(e) =>
                              this.setState({ otherNames: e.target.value, colorOthername: "black" })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorTitle }} className="form-label">
                          Title <span className="text-danger">*</span>
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <select
                            className="form-control"
                            value={this.state.title}
                            onChange={(e) =>
                              this.setState({ title: e.target.value, colorTitle: "black" })
                            }
                          >
                            <option value="">-- Select title --</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Prof.">Prof.</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorDob }} className="form-label">
                          DOB <span className="text-danger">*</span>
                        </label>
                        <Form.Control
                          name="dob"
                          value={moment(this.state.dob).format("YYYY-MM-DD")}
                          placeholder="Enter date of birth"
                          onChange={this.handleDateChange}
                          type="date"
                        />
                      </div>

                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorNationality }} className="form-label">
                          Nationality <span className="text-danger">*</span>
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none"
                            value={this.state.nationality}
                            onChange={(e) =>
                              this.setState({ nationality: e.target.value, colorNationality: "black" })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorGender }} className="form-label">
                          Select Gender <span className="text-danger">*</span>
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <select
                            className="form-control shadow-none"
                            value={this.state.gender}
                            onChange={this.handleGenderChange}
                          >
                            <option value="">-- Select gender --</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorState }} className="form-label">
                          Select State <span className="text-danger">*</span>
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <select
                            className="form-control shadow-none"
                            value={this.state.selectedState}
                            onChange={this.handleStateChange}
                          >
                            <option value="">-- Select state --</option>
                            {this.getStates()}
                          </select>
                        </div>
                      </div>

                      {this.state.selectedState && (
                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label style={{ color: this.state.colorLga }} className="form-label">
                            LGA <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <select
                              className="form-control shadow-none"
                              value={this.state.lga}
                              onChange={(e) =>
                                this.setState({ lga: e.target.value, colorLga: "black" })
                              }
                            >
                              <option value="">-- Select LGA --</option>
                              {this.getLgas()}
                            </select>
                          </div>
                        </div>
                      )}

                      <hr />

                      {/* Contact Information */}
                      <div className="row" style={{ padding: 15 }}>
                        <label className="mb-3 h4" style={{ color: "green" }} htmlFor="contact-info">
                          Contact Information
                        </label>
                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label style={{ color: this.state.colorPhone }} className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <input
                              className="form-control shadow-none"
                              type="number"
                              value={this.state.contactPhone}
                              onChange={(e) =>
                                this.setState({ contactPhone: e.target.value, colorPhone: "black" })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label style={{ color: this.state.colorEmail }} className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <input
                              className="form-control shadow-none"
                              type="email"
                              value={this.state.contactEmail}
                              onChange={(e) =>
                                this.setState({ contactEmail: e.target.value, colorEmail: "black" })
                              }
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                          <label style={{ color: this.state.colorAddress }} className="form-label">
                            Contact Address <span className="text-danger">*</span>
                          </label>
                          <div className="input-group input-group-outline mb-3">
                            <textarea
                              className="form-control shadow-none"
                              value={this.state.contactAddress}
                              onChange={(e) =>
                                this.setState({ contactAddress: e.target.value, colorAddress: "black" })
                              }
                            ></textarea>
                          </div>
                        </div>
                      </div>

                      <hr />

                      {/* Professional Development */}
                      <div className="row" style={{ padding: 10 }}>
                        <label className="mb-3 h4" style={{ color: "green" }} htmlFor="professional-dev">
                          Professional Development ({qualifications.length}/10 qualifications added)
                        </label>

                        <div className="alert alert-warning mb-3">
                          <small>
                            <strong>File Upload Requirements:</strong>
                            <ul className="mb-0">
                              <li>Maximum file size: 1MB</li>
                              <li>Accepted formats: Images (JPG, PNG) and PDF</li>
                            </ul>
                          </small>
                        </div>

                        {qualifications.map((qual, index) => (
                          <div key={index} className="row align-items-center mb-3">
                          <div className="col-sm-5 col-lg-5 col-md-5">
                            <label className="form-label" style={{ color: this.state.colorQual }}>
                              Qualification
                            </label>
                            <div className="input-group input-group-outline">
                              <select
                                className="form-control shadow-none"
                                value={qual.qualification}
                                onChange={e =>
                                  this.updateQualification(index, 'qualification', e.target.value)
                                }
                              >
                                <option value="">-- Select qualification --</option>
                                <option value="PHD_DEGREE">PH.D DEGREE</option>
                                <option value="PHD_STUDENT">PH.D STUDENT</option>
                                <option value="MSC_DEGREE">M.SC DEGREE</option>
                                <option value="MSC_STUDENT">M.SC STUDENT</option>
                                <option value="NIFST_REFOST_PRESENTATION">NIFST REFOST / PRESENTATION</option>
                                <option value="INTERNATIONAL_FST_CONFERENCES_PRESENTATION">
                                  INTERNATIONAL FST CONFERENCES / PRESENTATION
                                </option>
                                <option value="NICO_FST_TRAINING_WORKSHOP">
                                  NICO FST TRAINING WORKSHOP
                                </option>
                                <option value="INTL_FST_CERTIFICATION">INT'L FST CERTIFICATION</option>
                                <option value="WORK_EXPERIENCE">WORK EXPERIENCE</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-sm-5 col-lg-5 col-md-5">
                            <label className="form-label">
                              Upload Certificate <small className="text-muted">(Max: 1MB)</small>
                            </label>
                            <div className="input-group input-group-outline">
                              <input
                                className="form-control shadow-none"
                                type="file"
                                accept="image/*,application/pdf"
                                onChange={(e) => this.handleFileChange(index, e)}
                              />
                            </div>
                          </div>

                          <div className="col-sm-2 col-lg-2 col-md-2" style={{ marginTop: '20px' }}>
                            <button 
                              className="btn btn-danger"
                              onClick={() => this.removeQualification(index)}
                            >
                              Remove
                            </button>
                          </div>
                          </div>
                        ))}
                        <span className="text-danger form-label font-weight-bold">
                          Include any additional qualifications you have obtained this year. This qualification should not be a duplicate of any already submitted.
                        </span>
                        
                        {qualifications.length < 10 && (
                          <button 
                            className="btn btn-primary mt-3" 
                            onClick={(e) => { 
                              e.preventDefault(); 
                              this.addQualification(); 
                            }}
                          >
                            Add Qualification
                          </button>
                        )}
                        
                      </div>

                      <hr />

                      {/* Place of Work */}
                      <label className="mb-3 h4" style={{ color: "green" }} htmlFor="work-info">
                        Place of Work <small className="text-danger">(Fill if currently employed)</small>
                      </label>
                      {employed && (
                        <>
                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label style={{ color: this.state.colorOrgName }} className="form-label">
                              Name of Organization/Institution <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                value={this.state.organizationName}
                                onChange={(e) =>
                                  this.setState({ organizationName: e.target.value, colorOrgName: "black" })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label style={{ color: this.state.colorOrgPosition }} className="form-label">
                              Position <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                value={this.state.organizationPosition}
                                onChange={(e) =>
                                  this.setState({ organizationPosition: e.target.value, colorOrgPosition: "black" })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label style={{ color: this.state.colorOrgAddress }} className="form-label">
                              Address <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                value={this.state.organizationAddress}
                                onChange={(e) =>
                                  this.setState({ organizationAddress: e.target.value, colorOrgAddress: "black" })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label style={{ color: this.state.colorOrgPhone }} className="form-label">
                              Organization Telephone <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <input
                                className="form-control shadow-none"
                                type="number"
                                value={this.state.organizationTelephone}
                                onChange={(e) =>
                                  this.setState({ organizationTelephone: e.target.value, colorOrgPhone: "black" })
                                }
                              />
                            </div>
                          </div>

                          <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                            <label style={{ color: this.state.colorOrgEmail }} className="form-label">
                              Organization Email <span className="text-danger">*</span>
                            </label>
                            <div className="input-group input-group-outline mb-3">
                              <input
                                className="form-control shadow-none"
                                type="text"
                                value={this.state.organizationEmail}
                                onChange={(e) =>
                                  this.setState({ organizationEmail: e.target.value, colorOrgEmail: "black" })
                                }
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="row" style={{ marginTop: 25 }}>
                        <Form.Floating className="mb-3">
                          <Form.Check
                            type="checkbox"
                            id="employedCheckbox"
                            label="Are you currently employed?"
                            onChange={this.handleEmployedCheck}
                          />
                        </Form.Floating>
                      </div>

                      <hr />

                      {/* Practice Category */}
                      <label className="h4" style={{ color: "green" }} htmlFor="practice-category">
                        Practice Category
                      </label>
                      <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                        <label style={{ color: this.state.colorPraCat }} className="form-label">
                          Practice Category <span className="text-danger">*</span>
                        </label>
                        <div className="input-group input-group-outline mb-3">
                          <select
                            className="form-control shadow-none"
                            value={this.state.practiceCategory}
                            onChange={(e) =>
                              this.setState({ practiceCategory: e.target.value, colorPraCat: "black" })
                            }
                          >
                            <option value="">-- Select category --</option>
                            <option value="ACADEMIC/RESEARCH">ACADEMIC/RESEARCH</option>
                            <option value="GOVERNMENT/REGULATORY">GOVERNMENT/REGULATORY</option>
                            <option value="INDUSTRY">INDUSTRY</option>
                            <option value="PRIVATE PRACTICE">PRIVATE PRACTICE</option>
                            <option value="NGO">NGO</option>
                            <option value="INTERNATIONAL DEVELOPMENT">INTERNATIONAL DEVELOPMENT</option>
                          </select>
                        </div>
                      </div>

                      <hr />

                      <div className="row" style={{ marginTop: 25 }}>
                        <Form.Floating className="mb-3">
                          <Form.Check
                            type="checkbox"
                            id="certificationCheckbox"
                            label="I hereby certify that I am not a registered and practicing member of an allied profession and the particulars furnished herein are true"
                            onChange={this.handleCheckChange}
                          />
                        </Form.Floating>
                      </div>

                      <div
                        className="text-center"
                        style={{ margin: "45px auto 0", width: "100%" }}
                      >
                        <button
                          type="button"
                          disabled={disabled}
                          style={{
                            width: "100%",
                            backgroundColor: "#003314",
                          }}
                          className="btn btn-success btn-lg"
                          onClick={this.checkValidation}
                        >
                          {loading && (
                            <Spinner animation="border" variant="light" size="sm" />
                          )}
                          <span className="font-weight-bold">
                            {" SUBMIT APPLICATION AND PROCEED TO PAYMENT"}
                          </span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NewDashboardLayout>
    );
  }
}

export default NewRegisterLicense;