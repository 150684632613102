import { Component } from 'react';
import NewDashboardLayout from '../components/NewDashboardlayout';
import moment from "moment";
import Swal from 'sweetalert2';
import NewPagination from '../components/NewPagination';
import NewLicenseModal from '../components/NewLicenseModal';
import noData from '../assets/images/data.png';
import dataLoading from '../assets/images/loading.gif';
import {Link} from "react-router-dom";

const baseUrl = process.env.REACT_APP_BASE_URL;

class NewLicenseHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activePage: 1,
      error: false,
      loading: true,
      currentPage: 1,
      postsPerPage: 15,
      modalShow: false,
      recentModalViewID: 0,
    };

    this.getLicenseRegistration = this.getLicenseRegistration.bind(this);
  }

  handleViewLicenseDetails = (id) => {
    this.setState({ modalShow: true, recentModalViewID: id });
  };

  getPageChanged = (pageClick) => {
    this.setState({ activePage: pageClick });
  };

  showTable = () => {
    const { data: { content } } = this.state;

    try {
      return content?.map((item, index) => (
        <tr key={`00${index}`}>
          <td>{`${item?.title} ${item?.otherNames}`}</td>
          <td className="d-none d-xl-table-cell">{item?.contactTelephone}</td>
          <td className="d-none d-md-table-cell">{item?.practiceCategory}</td>
          <td className="d-none d-md-table-cell">{moment(item?.applicationDate).format("LL")}</td>
          <td className="d-none d-md-table-cell">{moment(item?.approvalDate).format("LL")}</td>
          <td>
            <span className={`badge bg-warning p-2 ${item?.applicationStatus === "rejected" ? "bg-danger" : (item?.applicationStatus === "approved") && "bg-success"}`}>
              {item?.applicationStatus}
            </span>
          </td>
          <td className="d-none d-md-table-cell font-weight-bolder cursor-pointer">
            <span className="badge bg-dark text-dark px-3 py-2 bg-white font-weight-bold cursor-pointer shadow-lg border-4 "
                  onClick={() => this.handleViewLicenseDetails(item?.licenseId)}
            >view</span>
            <span className="badge bg-dark text-dark px-3 py-2 bg-white font-weight-bold cursor-pointer shadow-lg border-4 ">

              <Link to={`/new-renew-license/${item?.licenseId}`} className={`text-black`} style={{ textDecoration: "none" }}>

            Renew

                </Link>
            </span>
          </td>
        </tr>
      ));
    } catch (e) {
      Swal.fire({
        title: "Error",
        text: "Unable to load data",
        type: "error",
      });
    }
  };

  getLicenseRegistration = async () => {
    const url = `${baseUrl}/v2/license/history/${localStorage.getItem("userid")}`;
    this.setState({ isLoading: true });
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.status === 401) {
        Swal.fire({
          title: "Session Expired",
          text: "Session expired. Please login",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.props.history.push("/login");
        });
      } else {
        const data = await response.json();
        this.setState({ data, loading: false });
      }
    } catch (error) {
      this.setState({ error: true, loading: false });
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  componentDidMount() {
    this.getLicenseRegistration();
  }

  render() {
    const { data, loading, activePage, modalShow, recentModalViewID } = this.state;
    return (
      <NewDashboardLayout PageName="License Registration History">
        <div className="row">
          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
            <div className="card flex-fill" >
              <div className="d-flex justify-content-between" style={{ backgroundColor: "rgb(6 38 1)" }}>
                <div className="">
                  <h5 className="card-title mb-0">{'  '}</h5>
                </div>
                <div className='form-label-group w-25 mt-4 mx-4 text-secondary'>
                  <input type="text" id="inputPassword" className="form-control shadow-none py-2 pl-2 pr-6 rounded-2" placeholder="Search" />
                  <label htmlFor="inputPassword" />
                </div>
              </div>
              <table className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="d-none d-xl-table-cell">Phone</th>
                    <th className="d-none d-xl-table-cell">Practice Category</th>
                    <th>Application Date</th>
                    <th className="d-none d-md-table-cell">Approval Date</th>
                    <th className="d-none d-md-table-cell">Application Status</th>
                    <th className="d-none d-md-table-cell">Action</th>
                  </tr>
                </thead>
                <tbody className='m-auto'>
                  { data?.content?.length && this.showTable() }
                </tbody>
              </table>
              { !data?.content?.length && loading ? (<div className='d-flex justify-content-center mx-auto mt-4'><img width={100} height={100} alt='loading' src={dataLoading}/></div>) : ( !data?.content?.length &&  <div className='d-flex justify-content-center mx-auto  mt-4'><img width={100} height={100} alt='no-data' src={noData}/></div>)} 
            </div>
          </div>
        </div>
        <NewPagination activePage={activePage} totalItemsCount={data?.totalElements} getPageChanged={this.getPageChanged} />
        <NewLicenseModal show={modalShow} liicenceId={recentModalViewID} onHide={() => this.setState({ modalShow: false })} />
      </NewDashboardLayout>
    );
  }
}

export default NewLicenseHistory;
