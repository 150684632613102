import { useState, useCallback, useMemo, useEffect } from "react";
import { useFormikContext } from "formik";
import { useWizard } from "react-formik-step-wizard";
import { Container, Row, Col, Spinner } from "reactstrap";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { makePayment } from "../../Helper/paymentHelper";

export default function RegistrationFooter(props) {
  const { textNext, textPrevious } = props;
  const [isLoad, setIsLoad] = useState(false);
  const [tabIndex, settabIndex] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();

  const {
    isLoading,
    goToPreviousStep,
    goToNextStep,
    goToStep,
    stepNumber,
    activeStep,
    isFirstStep,
    isLastStep,
  } = useWizard();

  const {
    disablePrevious,
    hideNext,
    disableNext,
    disableNextOnErrors,
    endpoint,
  } = activeStep;

  const { isValid, values, submitForm, errors } = useFormikContext();
  const isPreviousDisabled = disablePrevious || isLoading;
  const isNextDisabled =
    isLoading ||
    disableNext ||
    (disableNextOnErrors && !isValid) ||
    (isLastStep && !isChecked);

  useMemo(() => {
    settabIndex(tabIndex);
    goToStep(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    if (sessionStorage.getItem("ongoing:tabIndex")) {
      settabIndex(sessionStorage.getItem("ongoing:tabIndex"));
    }
  }, []);

  const showtToast = async () => {
    setIsLoad(true);

    try {
      const response =
        endpoint === "create-account-information"
          ? await axios.post(
              `${process.env.REACT_APP_BASE_URL}/v2/registration/register/${endpoint}`,
              { ...values }
            )
          : await axios.post(
              `${process.env.REACT_APP_BASE_URL}/v2/registration/register/${endpoint}`,
              {
                userId:
                  JSON.parse(sessionStorage.getItem("registration:userId")) ||
                  "",
                ...values,
              }
            );

      if (endpoint === "complete-registration") {
        Swal.fire({
          html: response.data.message || "User succesfully registered",
          icon: "info",
          timerProgressBar: false,
          showConfirmButton: true,
          showCancelButton: false,
          showCloseButton: false,
          cancelButtonColor: "#ff7b7b",
          confirmButtonText: "Registration Completed",
        }).then(() => {
          const { status, data } = response;

          if (status === 200) {
            localStorage.setItem("userid", data.userid);
            localStorage.setItem("email", data.email);
            localStorage.setItem("token", data.token);

            sessionStorage.clear();
            history.push("/dashboard");
          } else {
            history.push("/login");
          }
        });
      } else if (Object.keys(errors).length > 0) {
        const key = Object.keys(errors);
        const val = Object.values(errors[key][0])[0];
        Swal.fire({
          html: errors[errors[key]] || val,
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonColor: "#ff7b7b",
          confirmButtonText: "Registration Completed",
        });

        setIsLoad(false);
      } else {
        sessionStorage.setItem(
          "registration:userId",
          JSON.stringify(response?.data?.userId)
        );
        sessionStorage.setItem(
          "registration:ongoing",
          JSON.stringify(response?.data)
        );

        const rrr = response.data?.paymentInformation?.rrr;
        localStorage.setItem("rrr", rrr);

        setIsLoad(false);
        submitForm();
        sessionStorage.setItem("ongoing:tabIndex", stepNumber);
      }
    } catch (error) {
      setIsLoad(false);
      if (error?.response?.status === 402) {
        if (endpoint === "complete-registration") {
          error?.response?.data?.paymentStatus !== "complete" &&
            makePayment("registration", endpoint);
        } else {
          Swal.fire({
            html:
              error?.response?.data?.violations?.message ||
              error?.response?.data?.message,
            icon: "error",
            showConfirmButton: false,
            showCancelButton: true,
            showCloseButton: true,
            cancelButtonColor: "#ff7b7b",
          });
        }
      } else if (
        error?.response?.status === 400 &&
        error?.response?.data?.violations?.length > 0
      ) {
        Swal.fire({
          html: error?.response?.data?.violations[0]?.message,
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonColor: "#ff7b7b",
        });
      } else if (error?.response?.status === 400) {
        Swal.fire({
          html: error?.response?.data?.message,
          icon: "error",
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonColor: "#ff7b7b",
        });
      } else if (error.message === "Network Error") {
        Swal.fire({
          html: "Check your Internet connection",
          icon: "error",
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonColor: "#ff7b7b",
        });
      } else if (Object.keys(errors).length) {
        Swal.fire({
          html: "Field not correctly filled",
          icon: "error",
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonColor: "#ff7b7b",
        });
      } else {
        Swal.fire({
          html:
            error?.response?.data?.violations?.message ||
            error?.response?.data?.message ||
            "Unable process your registration at the moment. Please try again later.",
          icon: "error",
          timer: 4000,
          timerProgressBar: true,
          showConfirmButton: false,
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonColor: "#ff7b7b",
        });
      }
    } finally {
      setIsLoad(false);
    }
  };

  return (
    <div className="wizard-card-footer clearfix bg-success py-4">
      <Container>
        <Row>
          <Col md={12}>
            {/* 'Previous' button */}
            {!isFirstStep && (
              <Button
                size="lg"
                variant="light"
                className="float-start m-0"
                type="button"
                disabled={isPreviousDisabled}
                onClick={() => {
                  settabIndex((tabIndex) => (tabIndex > 0 ? tabIndex - 1 : 0));
                  goToPreviousStep();
                }}
              >
                {textPrevious || "Previous"}
              </Button>
            )}

            {/* 'Next' button */}
            {!hideNext && (
              <div onClick={showtToast}>
                <Button
                  size="lg"
                  variant="light"
                  className="float-end m-0"
                  disabled={isNextDisabled}
                >
                  {isLoad ? (
                    <Spinner
                      className="me-2"
                      size="sm"
                      animation="border"
                      role="status"
                    >
                      <span className="">Loading...</span>
                    </Spinner>
                  ) : textNext || isLastStep ? (
                    "Submit and Proceed to Payment"
                  ) : (
                    "Next"
                  )}
                </Button>
              </div>
            )}
          </Col>
        </Row>
        {isLastStep && (
          <Row
            style={{
              marginTop: "-100px",
              position: "absolute",
            }}
          >
            <Col md={12}>
              <input
                type="checkbox"
                id="agreement"
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <label htmlFor="agreement">
                {" "}
                I hereby certify that all particulars furnished herein are true
                and correct.
              </label>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
