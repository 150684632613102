import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import noData from '../assets/images/data.png';
import dataLoading from '../assets/images/loading.gif';

const baseUrl = process.env.REACT_APP_BASE_URL;

function NewLicenseModal(props) {

  const { liicenceId } = props;
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    getEachLicenseRegistration()
  },[liicenceId]);

  const getEachLicenseRegistration = async () => {
    const url = `${baseUrl}/v2/license/${liicenceId}`;
    setLoading(true);
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (response.status === 401) {
        Swal.fire({
          title: "Session Expired",
          text: "Session expired. Please login",
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          history.push('/login')
        });
      } else {
        const data = await response.json();
        setData(data);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        title: "Error!",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      { data &&  <>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <span className={`badge bg-warning p-2 ${data?.applicationStatus === "rejected" ? "bg-danger" : (data?.applicationStatus === "approved") && "bg-success"}`}>
              {data?.applicationStatus}
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-hover my-0">
            <thead>
              <tr className='bg-secondary '>
                <th className='text-black'>Name</th>
                <th className="d-none d-xl-table-cell text-black">Phone</th>
                <th className="d-none d-xl-table-cell text-black">Practice Category</th>
                <th className='text-black'>Application Date</th>
                <th className="d-none d-md-table-cell text-black">Approval Date</th>
                <th className="d-none d-md-table-cell text-black">Application Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{`${data?.title} ${data?.otherNames}`}</td>
                <td className="d-none d-xl-table-cell">{data?.contactTelephone}</td>
                <td className="d-none d-md-table-cell">{data?.practiceCategory}</td>
                <td className="d-none d-md-table-cell">{moment(data?.applicationDate).format("LL")}</td>
                <td className="d-none d-md-table-cell">{moment(data?.approvalDate).format("LL")}</td>
                <td className="d-none d-md-table-cell">{data?.applicationStatus}</td>
              </tr>
            </tbody> 

            <thead className='text-black'>
              <tr className='bg-secondary'>
                <th className='text-black'>Email</th>
                <th className="d-none d-xl-table-cell text-black">Address</th>
                <th className="d-none d-xl-table-cell text-black">Expiration Date</th>
                <th className='text-black'>state</th>
                <th className="d-none d-md-table-cell text-black">Local Govt Area</th>
                <th className="d-none d-md-table-cell text-black">Nationality</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.contactEmail}</td>
                  <td className="d-none d-md-table-cell">{data?.contactAddress}</td>
                  <td className="d-none d-xl-table-cell">{moment(data?.licenseExpDate).format("LL")}</td>   
                  <td className="d-none d-md-table-cell">{data?.state}</td>
                  <td className="d-none d-md-table-cell">{data?.lga}</td>
                  <td className="d-none d-md-table-cell">{data?.nationality}</td>
              </tr>
            </tbody> 
          </table>     
        </Modal.Body>
      </> } 
      { !data && loading ? (<div className='d-flex justify-content-center mx-auto mt-4'><img width={100} height={100} alt='loading' src={noData}/></div>) :  
        (!data && <div className='d-flex justify-content-center mx-auto mt-4'><img width={100} height={100} alt='no-data' src={dataLoading}/></div> )
      }
      <Modal.Footer>
        <Button style={{ backgroundColor: "rgb(6, 38, 1)" }} onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewLicenseModal

