import React, { Component } from "react";
import { Spinner, Form } from "react-bootstrap";
 
import "bootstrap/dist/css/bootstrap.min.css";
import { states } from "../components/States";
import Swal from "sweetalert2";
import Footer from "../components/Footer";
import logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";

let FORM_ID = "";
let TOKEN = "";



const baseUrl = process.env.REACT_APP_BASE_URL

class Premises extends Component {
    constructor(props) {
        var today = new Date(),
          date =
            today.getFullYear() +
            "-" +
            (today.getMonth() + 1) +
            "-" +
            today.getDate();
        super(props);
        this.state = {
          currentDate: date,
          gender: "",
          productBrands: "",
          processEquipm: "",
          name: "",
          licenseNo: "",
          orgAddress: "",
          natureBus: "",
          fbpn: "",
          password: "",
          confirmPassword: "",
          disabled: false,
          nationality: "",
          state: "",
          lga: "",
          boxAll: false,
          orgName: "",
          regType: "",
          nifst: "",
          colorFbpn: "black",
          colorOrgAddress: "black",
          colorNationality: "black",
          colorLga: "black",
          colorLicesNum: "black",
          colorPassword: "black",
          colorPasswordConf: "black",
          colorOrgName: "black",
          colorAddress: "black",
          colorProcessEquip: "black",
          colorSurname: "black",
          colorState: "black",
          colorGender: "black",
          colorNIFST: "black",
          colorQualYear: "black",
          colorNatureBus: "green",
          colorRegType: "black",
          loading: false,
          isUserId: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleGenderChange = this.handleGenderChange.bind(this);
        this.handleCheckChange = this.handleCheckChange.bind(this);
        this.handleBusNatureChange = this.handleBusNatureChange.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
      }

      handleChange(e) {
        this.setState({ title: e.target.value });
      }
      handleBusNatureChange(e) {
        this.setState({ natureBus: e.target.value });
      }
      handleStateChange(e) {
        this.setState({ state: e.target.value });
      }

      handleGenderChange(e) {
        this.setState({ gender: e.target.value });
      }

      handleCheckChange(e) {
        this.setState({ boxAll: e.target.checked }, () => {
          console.log("This returned true or false", this.state.boxAll);
        });
      }

      onChangeValue(event) {
        this.setState({ regType: event.target.value})
           console.log(event.target.value);
          }

      getStates() {
        return states.map((state) => {
          return <option value={state}>{state}</option>;
        });
      }

      checkAuth(){
        if(localStorage.getItem('userid')){
          this.registerPremises();
        }else{
          this.register();
        }
      }

      checkLogin = async (e) => {
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            countryid: this.state.countryCode,
            password: this.state.password,
            telephone: localStorage.getItem("phone")
              ? localStorage.getItem("phone")
              : this.state.phone,
          }),
        };
        fetch(`${baseUrl}/Users/login`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.message === "Login Successfull") {
              this.setState({ loading: false });
              localStorage.setItem("userid", responseJson.userid);
              localStorage.setItem("email", responseJson.email);
              TOKEN = responseJson.token;
              localStorage.setItem("token", responseJson.token);
              // this.props.history.push("/dashboard");
              this.registerPremises()
            } else if (
              responseJson.message === "Index 0 out of bounds for length 0"
            ) {
              this.register();
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };

      login = async (e) => {
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            countryid: this.state.countryCode,
            password: this.state.password,
            telephone: localStorage.getItem("phone")
              ? localStorage.getItem("phone")
              : this.state.phone,
          }),
        };
        fetch(`${baseUrl}/Users/login`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            console.warn(responseJson);
            if (responseJson.message === "Login Successfull") {
              this.setState({ loading: false });
              localStorage.setItem("userid", responseJson.userid);
              localStorage.setItem("email", responseJson.email);
              TOKEN = responseJson.token;
              localStorage.setItem("token", responseJson.token);
              this.registerPremises();
            } else if (
              responseJson.message === "Index 0 out of bounds for length 0"
            ) {
              this.setState({ loading: false });
              Swal.fire({
                title: "Error!",
                text: "Invalid username or password",
                icon: "error",
                confirmButtonText: "OK",
              });
            } else {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Error!",
                text: responseJson.message,
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };


      checkValidation = async (e) => {
        const {
          gender,
          name,
          licenseNo,
          processEquipm,
          nationality,
          natureBus,
          state,
          orgAddress,
          password,
          confirmPassword,
          nifst,
          boxAll,
          lga,
          orgName,
        } = this.state;
        this.setState({ loading: true, disabled: true });
        e.preventDefault();

         if(this.state.regType == ""){
            Swal.fire({
              title: "Empty",
              text: "Please indicate application type",
              icon: "error",
              confirmButtonText: "OK",
            });
            this.setState({ loading: false, disabled: false, colorRegType: "red" });
          }else if(this.state.regType === "renewal"){
            this.props.history.push('/login');
        }else if (name === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please enter name of lead technologist",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorSurname: "red" });
        } else if (licenseNo === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify technologist license number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorLicesNum: "red" });
        } else if (nifst === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please specify technologist registration number",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorNIFST: "red" });
        } else if (nationality === "" && !localStorage.getItem("nationality")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your nationality",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({
            loading: false,
            disabled: false,
            colorNationality: "red",
          });
        } else if (gender === "" && !localStorage.getItem("gender")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your gender",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorGender: "red" });
        } else if (state === "" && !localStorage.getItem("state")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your State of Origin",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorState: "red" });
        } else if (lga === "" && !localStorage.getItem("lga")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Local Government Area",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorLga: "red" });
        }  else if (password === "") {
            Swal.fire({
              title: "Empty",
              text: "Please enter your password",
              icon: "error",
              confirmButtonText: "OK",
            });
            this.setState({ loading: false, disabled: false, colorPassword: "red" });
          }else if (confirmPassword === "") {
            Swal.fire({
              title: "Empty",
              text: "Please confirm your password",
              icon: "error",
              confirmButtonText: "OK",
            });
            this.setState({ loading: false, disabled: false, colorPasswordConf: "red" });
          }
          else if (confirmPassword !== password) {
            Swal.fire({
              title: "Error",
              text: "Passwords do not match",
              icon: "error",
              confirmButtonText: "OK",
            });
            this.setState({
              loading: false,
              disabled: false,
              colorPasswordConf: "red",
              colorPassword: "red",
            });
          }


        else if (orgName === "" && !localStorage.getItem("orgName")) {
          Swal.fire({
            title: "Empty",
            text: "Please specify your Organization Name",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgName: "red" });
        } else if (orgAddress === "") {
          Swal.fire({
            title: "Empty",
            text: "Please specify address of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorOrgAddress: "red" });
        } else if (natureBus === "") {
          Swal.fire({
            title: "Empty",
            text: "Please specify phone number of your Organization",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorNatureBus: "red" });
        } else if (processEquipm === "" ) {
          Swal.fire({
            title: "Empty",
            text: "Please give a description of your process",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false, colorProcessEquip: "red" });
        } else if (boxAll === false) {
          Swal.fire({
            title: "Accept The Terms",
            text: "Please accept the terms and conditions",
            icon: "error",
            confirmButtonText: "OK",
          });
          this.setState({ loading: false, disabled: false });
        } else {
          this.checkAuth();
        }
      };

      registerPremises = async () => {
        var obj = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify({
            applicationdate: "2022-03-20T17:25:55.033Z",
            applicationstatus: "pending",
            businessdescription: this.state.processEquipm,
            businesstype: this.state.natureBus,
            fbpnumber: this.state.fbpn,
            formtype: this.state.regType,
            gender: this.state.gender,
            inspectiondate: "",
            inspectionfees: "",
            inspectionfollowup: "",
            inspectionrejectedcomment: "",
            inspectionstatus: "",
            inspectionteamleadercomment: "",
            inspectionteamleadername: "",
            leadfoodscientistlicensenumber: this.state.licenseNo,
            leadfoodscientistname: this.state.name,
            lga: this.state.lga,
            locationaddress: this.state.orgAddress,
            nationality: this.state.nationality,
            organisationname: this.state.orgName,
            productbrands: this.state.productBrands,
            registrationnumber: this.state.nifst,
            stateofororigin: this.state.state,
            userid: localStorage.getItem("userid")

          }),
        };
        fetch(`${baseUrl}/Premises/addPremises`, obj)
          .then((response) => response.json())
          .then((responseJson) => {
            console.warn(responseJson);
            if (
              responseJson.message == "New Premises added Successfully"
            ) {
              this.setState({ loading: false, disabled: false });
              Swal.fire({
                title: "Success",
                text: "Premises Registration Successful",
                icon: "success",
                confirmButtonText: "OK",
              }).then(()=> {
                // this.props.history.push("/dashboard");
              })
            }else if(responseJson.status == 401){
              this.props.history.push("/login")
            }
          })
          .catch((error) => {
            this.setState({ loading: false, disabled: false });
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      };


    render(){
        const { loading } = this.state;
        return(
            <div>

            <div className="text-center">
                <img
              src={logo}
              style={{
                width: "10%",
                position: "relative",
                top: 35,
                alignSelf: "center",
                margin: "0 auto",
              }}
            />
            </div>
                 <h3
              className="nicfostText"
              style={{
                position: "relative",
                fontWeight: "bold",
                top: 55,
                textAlign: "center",
              }}
            >
              NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY (NiCFoST)
            </h3>
            <h5
              style={{
                position: "relative",
                fontWeight: "bold",
                top: 65,
                textAlign: "center",
              }}
            >
              APPLICATION FORM FOR THE REGISTRATION OF FOOD BUSINESS PREMISES
            </h5>
            <div className="container" style={{ marginTop: 65, padding: 85 }}>
              <div style={{ marginTop: 25 }}></div>
              <form className="row">

    <div className="row" style={{marginTop: 15}}>
      <label
            style={{ color: this.state.colorRegType }}
            htmlFor="floatingInputCustom"
            className="mb-3 h4"
          >
            Choose application type <span className="text-danger">*</span>
          </label>

        <div onChange={this.onChangeValue}>
      <Form.Floating className="mb-3">
          <input
           class="form-check-input shadow-none"
            type="radio"
            name="reg"
            required="required"
            value="new registration"
            onChange={(e) => this.setState({ regType: e.target.value })}
          />
           {' '}New registration
        </Form.Floating>
      </div>

      <div className="row">
      <Form.Floating className="mb-3">
          <input
           class="form-check-input shadow-none"
            type="radio"
            name="reg"
            required="required"
            value="renewal"
            onChange={(e) => this.setState({ regType: e.target.value })}
          />
           {' '}Renewal
        </Form.Floating>
      </div>

      </div>

      <hr />

      <label
        className="mb-3 h4"
        style={{ color: "green" }}
        htmlFor="floatingInputCustom"
      >
        Personal Information
      </label>
                <br />
                <br />
                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorSurname }}
                    className="form-label"
                  >
                    Name of Lead Food Scientist <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ name: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorLicesNum }}
                    className="form-label"
                  >
                    License Number <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ licenseNo: e.target.value })}
                    />
                  </div>
                </div>


                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorNIFST }}
                    className="form-label"
                  >
                    Registration Number <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ nifst: e.target.value })}
                    />
                  </div>
                </div>



                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    className="form-label"
                    style={{ color: this.state.colorNationality }}
                  >
                    Nationality <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      type="text"
                      className="form-control shadow-none"
                      onChange={(e) =>
                        this.setState({ nationality: e.target.value })
                      }
                      defaultValue={localStorage.getItem("nationality")}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    className="form-label"
                    style={{ color: this.state.colorGender }}
                  >
                    Select Gender <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select"
                      onChange={this.handleGenderChange}
                      style={{ color: this.state.colorGender }}
                    >
                      <option
                        value={
                          localStorage.getItem("gender")
                            ? localStorage.getItem("gender")
                            : "gender"
                        }
                        selected
                      >
                        {localStorage.getItem("gender")
                          ? localStorage.getItem("gender")
                          : "-- Select gender --"}
                      </option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorState }}
                    className="form-label"
                  >
                    Select State <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select state"
                      onChange={this.handleStateChange}
                      style={{ color: this.state.colorState }}
                    >
                      <option
                        value={
                          localStorage.getItem("state")
                            ? localStorage.getItem("state")
                            : "-- Select state --"
                        }
                        selected
                      >
                        {localStorage.getItem("state")
                          ? localStorage.getItem("state")
                          : "-- Select state --"}
                      </option>

                      {this.getStates()}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorLga }}
                    className="form-label"
                  >
                    LGA <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ lga: e.target.value })}
                      defaultValue={localStorage.getItem("lga")}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorPassword }}
                    className="form-label"
                  >
                    Pasword <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) => this.setState({ password: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-4 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorPasswordConf }}
                    className="form-label"
                  >
                    Confirm Pasword <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) =>
                        this.setState({ confirmPassword: e.target.value })
                      }
                    />
                  </div>
                </div>


                <hr />

                <div className="row" style={{padding: 15}}>

                <label
                  className="mb-3 h4"
                  style={{ color: "green" }}
                  htmlFor="floatingInputCustom"
                >
                  Orgnainzation Information
                </label>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOrgName }}
                    className="form-label"
                  >
                    Name of Organization / Company <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="phone"
                      required="required"
                      onChange={(e) => this.setState({ orgName: e.target.value })}
                    />
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorFbpn }}
                    className="form-label"
                  >
                    Food Business Premises Number (For Renewals Only)
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <input
                      className="form-control shadow-none"
                      type="phone"
                      required="required"
                      onChange={(e) => this.setState({ fbpn: e.target.value })}
                    />
                  </div>
                </div>


                 <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorOrgAddress }}
                    className="form-label"
                  >
                    Address of Business Premises <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>

                    <textarea
                      className="form-control shadow-none"
                      type="text"
                      required="required"
                      onChange={(e) =>
                        this.setState({ orgAddress: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>


                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorNatureBus }}
                    className="form-label"
                  >
                    Nature of Business <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>
                    <select
                      className="form-control shadow-none"
                      aria-label="Select"
                      onChange={this.handleBusNatureChange}
                      style={{ color: this.state.colorGender }}
                    >
                      <option selected disabled>
                        -- Select category --
                      </option>
                      <option value="MANUFACTURING">MANUFACTURING</option>
                      <option value="STORAGE">STORAGE</option>
                      <option value="RESTAURANT">RESTAURANT</option>
                      <option value="RETAIL">RETAIL</option>
                      <option value="WHOLESALE">WHOLESALE</option>
                      <option value="FOOD SERVICES">FOOD SERVICES</option>
                    </select>
                  </div>
                </div>


                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    style={{ color: this.state.colorProcessEquip }}
                    className="form-label"
                  >
                    Description of process indicating major equipment and product <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>

                    <textarea
                      className="form-control shadow-none"
                      type="text"
                      rows={15}
                      required="required"
                      onChange={(e) =>
                        this.setState({ processEquipm: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="col-sm-6 col-lg-6 col-md-6 mb-3">
                  <label
                    className="form-label"
                  >
                    List of product brands with their unit packaging sizes if applicable <span className="text-danger">*</span>
                  </label>
                  <div className="input-group input-group-outline mb-3">
                    <label className="form-label"></label>

                    <textarea
                      className="form-control shadow-none"
                      type="text"
                      rows={15}
                      required="required"
                      onChange={(e) =>
                        this.setState({ productBrands: e.target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                </div>


                <div className="row" style={{ marginTop: 25 }}>
                  <Form.Floating className="mb-3">
                    <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="checkBoxUS"
                      name="US"
                      value="US"
                      onChange={this.handleCheckChange}
                    />{" "}
                    I hereby certify that the information given above is true
                  </Form.Floating>
                </div>

                <div
                className="text-center"
                  style={{
                    margin: "auto",
                    width: "100%",
                    marginTop: 45,
                    marginBottom: "15vh",
                  }}
                >
                  <button
                    disabled={this.state.disabled}
                    style={{
                      alignSelf: "center",
                      width: "60%",
                      backgroundColor: "#003314",
                    }}
                    className="btn btn-success btn-lg"
                    onClick={(e) => this.checkValidation(e)}
                  >
                    {loading ? (
                      <Spinner animation="border" variant="light" size="sm" />
                    ) : (
                      <span className="font-weight-bold">
                        {/* APPLY <i class="fas fa-chevron-right"></i> */}
                        SUBMIT APPLICATION
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
            <Footer />
            </div>
        )
    }
}

export default Premises;
