import React, { useState } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Card, Form } from "react-bootstrap";
import logo from "../assets/images/logo.png";
import Swal from "sweetalert2";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";

const AccountVerification = () => {
  const [message, setMessage] = useState(
    "Please, wait while we verify your account..."
  );

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { search } = useLocation();
  const history = useHistory();

  function verifyAccount() {
    const self = this;

    const searchParams = new URLSearchParams(search);

    let token = searchParams.has("token") ? searchParams.get("token") : "";

    const body = { token };
    const url = `${baseUrl}/v2/users/verify-account`;

    axios
      .put(url, body)
      .then((response) => {
        const data = response.data;
        const msg =
          data?.message ?? "Your account has successfully been verified.";

        setMessage(msg);

        Swal.fire({
          title: "Account Verified",
          text: msg,
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          history.push("login");
        });
      })
      .catch((error) => {
        const data = error.response?.data;
        const msg = data?.message ?? "Unable to verify your account.";

        setMessage(msg);

        Swal.fire({
          title: "Error",
          text: msg,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }

  useEffect(() => {
    setTimeout(verifyAccount, 1000);
  }, []);

  return (
    <div className="loginContainer">
      <div
        className="d-flex justify-content-center"
        style={{
          backgroundColor: "rgba(0,0,0,0.7)",
          width: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
        }}
      >
        <Card
          style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
          className="d-flex justify-content-center cardContainer loginContainer-content"
        >
          <img
            src={logo}
            style={{
              width: "15%",
              position: "absolute",
              top: 25,
              alignSelf: "center",
            }}
          />

          <h5
            className="nicfostText"
            style={{
              position: "relative",
              fontWeight: "bold",
              top: 5,
              color: "#333",
              textAlign: "center",
            }}
          >
            NIGERIAN COUNCIL OF FOOD SCIENCE AND TECHNOLOGY
            <br />
            (NiCFoST)
          </h5>
          <div className="mb-3"></div>

          <div className="container" style={{ marginTop: 50 }}>
            <div className="alert alert-success" role="alert">
              {message}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default AccountVerification;
